import { Route, Routes } from "react-router-dom"

import ServiceLocationDetail from "./service-locations/detail/ServiceLocationDetail"

function ServiceLocationsRoutes() {
    return (
        <>
            <Routes>
                <Route path=':service_location_unique_identifier/*' element={<ServiceLocationDetail />} />
            </Routes>
        </>
    )
}

export default ServiceLocationsRoutes