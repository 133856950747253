import { useIntl } from 'react-intl'
import { Navigate, Route, Routes } from 'react-router-dom'

import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { DebugHighlevelRequests } from '../../../_metronic/partials/widgets/debugging/DebugHighlevelRequests'
import { DebugPassareRequests } from '../../../_metronic/partials/widgets/debugging/DebugPassareRequests'
import { DebugAllLeads } from '../../../_metronic/partials/widgets/debugging/DebugAllLeads'
import { DebugPipedrive } from '../../../_metronic/partials/widgets/debugging/DebugPipedrive'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

export const DebuggingWrapper = () => {
  const intl = useIntl()
  return <>
    <Routes>
      <Route path='ghl-requests' element={<>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>
          Debug 360 Requests
        </PageTitle>
        <DebugHighlevelRequests />
      </>} />
      <Route path='passare-requests' element={<>
        <PageTitle breadcrumbs={dashboardBreadCrumbs}>
          Debug Passare Requests
        </PageTitle>
        <DebugPassareRequests />
      </>} />
      <Route path='all-leads/*' element={<>
        <DebugAllLeads />
      </>} />
      <Route path='all-pipedrive/*' element={<>
        <DebugPipedrive />
      </>} />
      <Route path='*' element={<Navigate to='/debugging/ghl-requests' />} />
    </Routes>
  </>
}