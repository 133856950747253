import { useState, useCallback, useEffect } from "react"
import { useAuth, APIError } from "../../../../../app/modules/auth"
import { getAllPassareAcquaintances, PassareAcquaintance } from "../../../../../mogul/core/_requests"
import { LeadHeader, useLeadDetail } from "../DebugAllLeads"
import { formatLeadItemDate } from "../DebugPipedrive"

const saveFilterToLocalStorageKey = 'passare-acquaintances-filter'

interface FilterObject {
    filteringByLocationUniqueIdentifier: string | null;
    showLeadsRegardlessofGHLStatus: boolean;
}

export function PassareAcquaintancesTable() {
    const { currentUser } = useAuth()
    const { accounts } = useLeadDetail()

    const isAdmin = !!currentUser?.is_admin

    const [currentFilterObject, setCurrentFilterObject] = useState<FilterObject | null>(null)

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)

    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [errorLoading, setErrorLoading] = useState<string | null>(null);

    const [results, setResults] = useState<PassareAcquaintance[]>([])
    const [totalResults, setTotalResults] = useState(0)

    const loadList = useCallback(async (forceFilter?: FilterObject, page: number = 1) => {
        setErrorLoading(null)
        if (page === 1) {
            setLoading(true)
        } else {
            setLoadingMore(true)
        }

        try {
            const activeFilter = forceFilter || currentFilterObject || {
                filteringByLocationUniqueIdentifier: null,
                showLeadsRegardlessofGHLStatus: isAdmin ? false : true
            }

            const limit = 100
            const {
                passare_acquaintances: result,
                total_passare_acquaintances: totalResult
            } = await getAllPassareAcquaintances({
                limit,
                page,
                filteringByLocationUniqueIdentifier: activeFilter.filteringByLocationUniqueIdentifier
            })

            if (page === 1) {
                setResults(result)
            } else {
                setResults(prev => [...prev, ...result])
            }

            setTotalResults(totalResult)
            // Assume if we get a full page of results, there might be more
            setHasMore(result.length === limit)
        } catch (exception) {
            console.error(exception)
            const errorMessage =
                exception instanceof APIError ? exception.message : 'Failed to load leads'
            setErrorLoading(errorMessage)
        } finally {
            setLoading(false)
            setLoadingMore(false)
        }
    }, [currentFilterObject, isAdmin])

    useEffect(() => {
        // Load saved filters from local storage
        try {
            const savedFilter = localStorage.getItem(saveFilterToLocalStorageKey)
            if (savedFilter) {
                const parsedFilter = JSON.parse(savedFilter)
                setCurrentFilterObject(parsedFilter)
                loadList(parsedFilter)
            } else {
                // Default filter
                const defaultFilter: FilterObject = {
                    filteringByLocationUniqueIdentifier: null,
                    showLeadsRegardlessofGHLStatus: isAdmin ? false : true
                }
                setCurrentFilterObject(defaultFilter)
                loadList(defaultFilter)
            }
        } catch (error) {
            console.error('Failed to load saved filter', error)
            // Fall back to defaults
            const defaultFilter: FilterObject = {
                filteringByLocationUniqueIdentifier: null,
                showLeadsRegardlessofGHLStatus: isAdmin ? false : true
            }
            setCurrentFilterObject(defaultFilter)
            loadList(defaultFilter)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadMore = () => {
        if (hasMore && !loading && !loadingMore) {
            setCurrentPage(prevPage => prevPage + 1)
            loadList(currentFilterObject || undefined, currentPage + 1)
        }
    }

    if (loading && currentPage === 1) {
        return <div className='p-10'>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    }

    if (errorLoading) {
        return <div className='p-10'>
            <div className="alert alert-danger" role="alert">
                {errorLoading}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={() => loadList()}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <div>
        <LeadHeader
            title={totalResults === 1 ? '1 Passare Lead' : `${totalResults} Passare Leads`}
            subtitle={!isAdmin ? '' : ''}
            showLeadsRegardlessofGHLStatus={currentFilterObject?.showLeadsRegardlessofGHLStatus || false}
            setShowLeadsRegardlessofGHLStatus={(val) => {
                const newFilter = {
                    ...(currentFilterObject || { filteringByLocationUniqueIdentifier: null, showLeadsRegardlessofGHLStatus: isAdmin ? false : true }),
                    showLeadsRegardlessofGHLStatus: val
                }
                setCurrentFilterObject(newFilter)
                localStorage.setItem(saveFilterToLocalStorageKey, JSON.stringify(newFilter))
                setCurrentPage(1)
                loadList(newFilter, 1)
            }}
            setFilteringByLocation={(val) => {
                const newFilter = {
                    ...(currentFilterObject || { filteringByLocationUniqueIdentifier: null, showLeadsRegardlessofGHLStatus: isAdmin ? false : true }),
                    filteringByLocationUniqueIdentifier: val
                }
                setCurrentFilterObject(newFilter)
                localStorage.setItem(saveFilterToLocalStorageKey, JSON.stringify(newFilter))
                setCurrentPage(1)
                loadList(newFilter, 1)
            }}
        />
        <div className='px-10'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                    <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>When Created?</th>
                        <th className='min-w-150px'>Last Updated</th>
                        <th className='min-w-150px'>Organization</th>
                        <th>Status</th>
                        <th>Deceased</th>
                        <th>Person</th>
                        <th>Relationship</th>
                        <th className='min-w-150px'>Phone Numbers</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((acquaintance: PassareAcquaintance) => {
                        const decedentFullName = acquaintance.getDecedentFullName();
                        const caseUrl = acquaintance.case_item.public_data.case_result?.case_url;

                        return (
                            <tr
                                key={`event-administrator-${acquaintance.unique_identifier}`}
                                data-unique-identifier={acquaintance.unique_identifier}
                            >
                                <td>{formatLeadItemDate(acquaintance.inserted_at)}</td>
                                <td>{formatLeadItemDate(acquaintance.updated_at, true)}</td>
                                <td>{acquaintance.passare_organization.organization_name || 'Unknown Organization'}</td>
                                {acquaintance.case_item.public_data.case_result ? (
                                    <>
                                        <td>
                                            {acquaintance.case_item.public_data.case_result.case_status || '?'}
                                        </td>
                                        <td>
                                            {decedentFullName ? (
                                                caseUrl ? (
                                                    <a href={caseUrl} target='_blank' rel='noreferrer'>
                                                        {decedentFullName}
                                                    </a>
                                                ) : (
                                                    decedentFullName
                                                )
                                            ) : (
                                                `Error with unique_identifier: ${acquaintance.unique_identifier} - No decedent found`
                                            )}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>-</td>
                                        <td>-</td>
                                    </>
                                )}
                                <td>
                                    {acquaintance.public_data.acquaintance_result
                                        ? `${acquaintance.public_data.acquaintance_result.first_name} ${acquaintance.public_data.acquaintance_result.last_name}`
                                        : '-'}
                                </td>
                                <td>
                                    {acquaintance.public_data.acquaintance_result
                                        ? acquaintance.public_data.acquaintance_result.relationship || '-'
                                        : '-'}
                                </td>
                                <td>
                                    {acquaintance.public_data.phone_numbers &&
                                        (acquaintance.public_data.phone_numbers.home_phone ||
                                            acquaintance.public_data.phone_numbers.mobile_phone ||
                                            acquaintance.public_data.phone_numbers.work_phone) ? (
                                        <>
                                            {acquaintance.public_data.phone_numbers.home_phone && (
                                                <div>Home: {acquaintance.public_data.phone_numbers.home_phone}</div>
                                            )}
                                            {acquaintance.public_data.phone_numbers.mobile_phone && (
                                                <div>Mobile: {acquaintance.public_data.phone_numbers.mobile_phone}</div>
                                            )}
                                            {acquaintance.public_data.phone_numbers.work_phone && (
                                                <div>Work: {acquaintance.public_data.phone_numbers.work_phone}</div>
                                            )}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </td>
                                <td>
                                    {acquaintance.public_data.email_address || '-'}
                                </td>
                            </tr>
                        )
                    })}
                    {!loading && hasMore && (
                        <tr>
                            <td colSpan={9}>
                                <button
                                    disabled={loadingMore}
                                    onClick={loadMore}
                                    className='btn btn-primary'
                                >
                                    {loadingMore ? 'Loading...' : 'Load More'}
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
}
