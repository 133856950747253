import { useState, useCallback, useEffect } from "react"
import { useAuth, APIError } from "../../../../../app/modules/auth"
import { FullCircleLead, getAllFullCircleLeads } from "../../../../../mogul/core/_requests"
import { SyncLeadButton } from "../DebugAllLeads"
import { formatLeadItemDateBetter } from "../DebugPipedrive"
import Select from 'react-select'
import useAvailableFullCircleAfterCareOptions from "../../../../../mogul/hooks/useAvailableFullCircleAfterCareOptions"

interface FilterObject {
    selectedOrgIds: string[],
    selectedPreneedOptions: string[],
    onlyUnSyncedWith360: boolean,
}

const saveFilterToLocalStorageKey = 'full_circle_lead_filter_v1'

function FilterFullCircleLeads({
    onCancel,
    onReset,
    currentFilterObject,
    onApplyFilter,
}: {
    onCancel: () => void,
    onReset: () => void,
    currentFilterObject: FilterObject | null,
    onApplyFilter: (filterObject: FilterObject) => void,
}) {

    const {
        availableOrgSelections,
        availablePreneedLeadInterestOptions
    } = useAvailableFullCircleAfterCareOptions()

    const [selectedOrgIds, setSelectedOrgIds] = useState<string[]>(currentFilterObject ? currentFilterObject.selectedOrgIds : [])
    const [selectedPreneedOptions, setSelectedPreneedOptions] = useState<string[]>(currentFilterObject ? currentFilterObject.selectedPreneedOptions : [])
    const [onlyUnSyncedWith360, setOnlyUnSyncedWith360] = useState(currentFilterObject ? currentFilterObject.onlyUnSyncedWith360 : false)

    return (
        <div>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter List</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>

                <div className='mb-5'>
                    <label className='form-label fw-bold'>
                        Funeral Home
                    </label>
                    <div>
                        <Select
                            options={[
                                { value: '', label: '-All Options-' },
                                ...availableOrgSelections.map((option) => {
                                    return {
                                        value: `${option.org_id}`,
                                        label: option.org_name
                                    }
                                })]}
                            onChange={(selectedOption) => {
                                setSelectedOrgIds(selectedOption ? selectedOption.map((x) => x.value) : [])
                            }}
                            isMulti
                            filterOption={(option, searchValue) => {
                                const words = searchValue.toLowerCase().split(' ');
                                return words.every(word => option.label.toLowerCase().includes(word));
                            }}
                            value={availableOrgSelections.filter(x => (selectedOrgIds || []).includes(`${x.org_id}`)).map((option) => {
                                return {
                                    value: `${option.org_id}`,
                                    label: option.org_name
                                }
                            })}
                        />
                    </div>
                </div>

                <div className='mb-5'>
                    <label className='form-label fw-bold'>
                        Interested in Pre-Need?:
                    </label>
                    <div>
                        <Select
                            options={[
                                { value: '', label: '-All Options-' },
                                ...availablePreneedLeadInterestOptions.map((option) => {
                                    return {
                                        value: `${option.id}`,
                                        label: option.label
                                    }
                                })]}
                            onChange={(selectedOption) => {
                                setSelectedPreneedOptions(selectedOption ? selectedOption.map((x) => x.value) : [])
                            }}
                            value={availablePreneedLeadInterestOptions.filter(x => selectedPreneedOptions.includes(`${x.id}`)).map((option) => {
                                return {
                                    value: `${option.id}`,
                                    label: option.label
                                }
                            })}
                            isMulti
                            filterOption={(option, searchValue) => {
                                const words = searchValue.toLowerCase().split(' ');
                                return words.every(word => option.label.toLowerCase().includes(word));
                            }}
                        />
                    </div>
                </div>

                <div className='mb-5'>
                    <label className='form-label fw-bold'>Only those un-synced with 360:</label>
                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            name='notifications'
                            onChange={(e) => setOnlyUnSyncedWith360(e.target.checked)}
                            checked={onlyUnSyncedWith360}
                        />
                        <label className='form-check-label'>Enabled</label>
                    </div>
                </div>

                <div className='d-flex justify-content-end'>
                    <button
                        type='button'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        onClick={() => onReset()}
                    >
                        Reset
                    </button>
                    <button
                        type='button'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        onClick={() => onCancel()}
                    >
                        Cancel
                    </button>
                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        onClick={() => onApplyFilter({
                            selectedOrgIds,
                            selectedPreneedOptions,
                            onlyUnSyncedWith360: onlyUnSyncedWith360
                        })}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    )
}

export function FullCircleLeadsTableHeader({
    title,
    subtitle,
    currentFilterObject,
    onApplyFilter
}: {
    title: string,
    subtitle?: string,
    currentFilterObject: FilterObject | null,
    onApplyFilter: (filterObject: FilterObject) => void,
}) {

    const [viewingFilter, setViewingFilter] = useState(false)

    return <>
        {/* begin::Header */}
        <div className='card-header align-items-center border-0 mt-3'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bolder text-dark fs-3'>
                    {title}
                </span>
                {subtitle && <span className='text-gray-400 mt-2 fw-bold fs-6'>{subtitle}</span>}
            </h3>
            <div className='card-toolbar'>
                {/* begin::Menu */}
                <button
                    type='button'
                    className='btn btn-sm btn-light me-2'
                    onClick={() => setViewingFilter(!viewingFilter)}
                >
                    <span className='indicator-label'>
                        Filter List
                    </span>
                </button>
                {/* end::Menu */}
            </div>
        </div>
        {/* end::Header */}
        {viewingFilter && <>
            <FilterFullCircleLeads
                onReset={() => {
                    onApplyFilter({
                        selectedOrgIds: [],
                        selectedPreneedOptions: [],
                        onlyUnSyncedWith360: false
                    })
                    localStorage.removeItem(saveFilterToLocalStorageKey)
                }}
                onCancel={() => setViewingFilter(false)}
                currentFilterObject={currentFilterObject}
                onApplyFilter={onApplyFilter}
            />
        </>}
    </>

}

export function FullCircleLeadsTable() {
    const { currentUser } = useAuth();

    const isAdmin = !!currentUser?.is_admin;

    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [errorLoading, setErrorLoading] = useState<string | null>(null);

    const [results, setResults] = useState<FullCircleLead[]>([]);
    const [totalResults, setTotalResults] = useState(0);

    const [currentFilterObject, setCurrentFilterObject] = useState<FilterObject | null>(null);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const loadList = useCallback(
        async (forceFilter?: FilterObject, page: number = 1) => {
            setErrorLoading(null);
            if (page === 1) {
                setLoading(true);
            } else {
                setLoadingMore(true);
            }
            try {
                const {
                    full_circle_leads: result,
                    total_full_circle_leads: totalResult,
                } = await getAllFullCircleLeads({
                    limit: 25, // the backend does quite a bit of computation so we limit to 25
                    page,
                    filter: forceFilter
                        ? {
                            org_ids: forceFilter?.selectedOrgIds || [],
                            preneed_options: forceFilter?.selectedPreneedOptions || [],
                            only_unsynced_with_360: forceFilter?.onlyUnSyncedWith360 || false,
                        }
                        : {
                            org_ids: currentFilterObject?.selectedOrgIds || [],
                            preneed_options: currentFilterObject?.selectedPreneedOptions || [],
                            only_unsynced_with_360: currentFilterObject?.onlyUnSyncedWith360 || false,
                        },
                });
                if (page === 1) {
                    setResults(result);
                } else {
                    setResults((prevResults) => [...prevResults, ...result]);
                }
                setTotalResults(totalResult);
                setHasMore(result.length === 25); // Assume there's more if we received a full page of results
            } catch (exception) {
                console.error(exception);
                const errorMessage =
                    exception instanceof APIError ? exception.message : 'Failed to load leads';
                setErrorLoading(errorMessage);
            } finally {
                setLoading(false);
                setLoadingMore(false);
            }
        },
        [currentFilterObject?.selectedOrgIds, currentFilterObject?.selectedPreneedOptions, currentFilterObject?.onlyUnSyncedWith360]
    );

    useEffect(() => {
        // Load from local storage
        try {
            const savedFilter = localStorage.getItem(saveFilterToLocalStorageKey);
            if (savedFilter) {
                const parsedFilter = JSON.parse(savedFilter);
                setCurrentFilterObject(parsedFilter);
                loadList(parsedFilter);
            } else {
                loadList();
            }
        } catch (error) {
            console.error('Failed to load saved filter', error);
        }
        // only load once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMore = () => {
        if (hasMore && !loading) {
            setCurrentPage((prevPage) => prevPage + 1);
            loadList(currentFilterObject || undefined, currentPage + 1);
        }
    };

    if (loading) {
        return <div className='p-10'>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    }

    if (errorLoading) {
        return <div className='p-10'>
            <div className="alert alert-danger" role="alert">
                {errorLoading}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={() => {
                    loadList()
                }}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <div>
        <FullCircleLeadsTableHeader
            title={totalResults === 1 ? '1 Full-Circle Lead' : `${totalResults} Full-Circle Leads`}
            subtitle={!isAdmin ? '' : ''}
            currentFilterObject={currentFilterObject}
            onApplyFilter={(filterObject) => {
                setCurrentFilterObject(filterObject)
                loadList(filterObject)
                // save to local storage
                localStorage.setItem(saveFilterToLocalStorageKey, JSON.stringify(filterObject))
            }}
        />
        <div className='px-10'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>Last Updated</th>
                        <th className='min-w-150px'>
                            Funeral Home
                        </th>
                        <th>Deceased</th>
                        <th>Lead Name</th>
                        <th className='min-w-150px'>Contact</th>
                        <th>Relationship</th>
                        <th>Pre-Need?</th>
                        <th>Preneed Notes</th>
                        <th className='min-w-150px'></th>
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {results.map((item) => {
                        return <tr key={`item-${item.deal.unique_identifier}-${item.person.unique_identifier}`} data-unique-identifier={`${item.deal.unique_identifier}-${item.person.unique_identifier}`}>
                            <td>
                                {formatLeadItemDateBetter(item.deal.last_updated_from_pipedrive_at)}
                            </td>
                            <td>
                                {`${item.deal.funeral_home_name} (${item.deal.fca_org_id}) - helped by ${item.deal.fca_estate_specialist_full_name}`}
                            </td>
                            <td>
                                {/* Deceased Name, 29280234f3abc430ca561212b5dffce4d4bde41a */}
                                {item.deal.deceased_full_name}
                            </td>
                            <td>
                                {item.person.lead_full_name}
                            </td>
                            <td>
                                <div>
                                    {item.person.lead_phone_numbers.map(x => x.value).join(', ')}
                                </div>
                                <div>
                                    {item.person.lead_email_addresses.map(x => x.value).join(', ')}
                                </div>
                            </td>
                            <td>
                                {/* Relationship, 6b3f919f6bbf6be25352f07bfc0f2230ca34c87b */}
                                {item.person.relationship_to_deceased}
                            </td>
                            <td>
                                {/* Pre-Need?, 7948db556604a4bb9c39dacb28ba47352ec71b8b */}
                                {item.deal.preneed_interest_level}
                            </td>
                            <td>
                                {/* Preneed Lead Notes, 900d26a002b5b95b5d8d4c1628373416989d3541 */}
                                {item.deal.preneed_notes}
                            </td>
                            <td>
                                {item.deal.ghl_contact?.ghl_contact_id ? <div>
                                    <div>
                                        <a href={
                                            `https://app.premier360platform.com/v2/location/${item.deal.ghl_contact.ghl_location_id}/contacts/detail/${item.deal.ghl_contact.ghl_contact_id}`
                                        } target='_blank' rel='noreferrer'>
                                            View in FRM
                                        </a>
                                    </div>
                                </div> : <>
                                    <div className='mt-2'>
                                        {/* Sync to 360 */}
                                        <SyncLeadButton uniqueIdentifier={item.deal.unique_identifier} />
                                    </div>
                                </>}
                            </td>
                        </tr>
                    })}
                    {!loading && hasMore && (
                        <tr>
                            <td colSpan={8}>
                                <button
                                    disabled={loadingMore}
                                    onClick={loadMore}
                                    className='btn btn-primary'
                                >
                                    {loadingMore ? 'Loading...' : 'Load More'}
                                </button>
                            </td>
                        </tr>
                    )}
                </tbody>
                {/* end::Table body */}
            </table>
        </div>
    </div>

}