/* eslint-disable react/jsx-no-target-blank */
import { FC } from 'react'
import { KTIcon } from '../../../../helpers'
import { AuthorsTab } from './AuthorsTab'
import { MenuTab } from './MenuTab'
import { NotificationsTab } from './NotificationsTab'
import AccountsAndServiceLocationsTab from '../../../../../mogul/left-navigation/AccountsAndServiceLocationsTab'
import { SubscriptionsTab } from './SubscriptionsTab'
import { TasksTab } from './TasksTab'
import { useAuth } from '../../../../../app/modules/auth'

type Props = {
  link: string
}

const TabsBase: FC<Props> = ({ link }) => {
  const { currentUser } = useAuth()

  if (!currentUser) {
    return null
  }

  return <AccountsAndServiceLocationsTab isAdmin={currentUser.is_admin} />

}

export { TabsBase }
