import { useState, useCallback, useEffect } from 'react'

import { APIError } from '../../app/modules/auth'
import { Partner, getPartners } from '../core/_requests'

function usePartners(isAdmin) {
  const [partners, setPartners] = useState<Partner[]>([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const loadPartners = useCallback(async () => {
    /*
    if (!isAdmin) {
      setLoading(false)
      return
    }
      */
    setLoading(true)
    try {
      const updatedPartners = await getPartners()
      setPartners(updatedPartners)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load partners'
      setErrorMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadPartners()
  }, [loadPartners])

  return {
    partners,
    loading,
    errorMessage,
  }
}

export default usePartners
