import { useState, useCallback, useEffect, useMemo } from "react";
import Select from 'react-select'

import { APIError } from "../../../../app/modules/auth";
import { AvailableFRMLocation, getAllFRMLocationsForServiceLocationUniqueIdentifier, selectFRMLocationIdForServiceLocationByUniqueIdentifier, disableFRMLinkForServiceLocationByUniqueIdentifier, AvailableCustomFRMField, ServiceLocation, createContactForServiceLocationByUniqueIdentifier, getCustomFieldsAvailableForServiceLocationByUniqueIdentifier, updateCustomFieldsForServiceLocationByUniqueIdentifier, updateServiceLocationDetailsByUniqueIdentifier } from "../../../core/_requests";
import { useServiceLocationDetail } from "./ServiceLocationDetail";
import useAccounts from "../../../hooks/useAccounts";


const GetAvailableLocationsGivenAServiceLocation = ({ serviceLocationUniqueId }: { serviceLocationUniqueId: string }) => {

    const [loadingAvailableLocations, setLoadingAvailableLocations] = useState(true)
    const [errorLoadingAvailableLocations, setErrorLoadingAvailableLocations] = useState<string | null>(null)
    const [availableServiceLocations, setAvailableServiceLocations] = useState<AvailableFRMLocation[]>([])

    const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null)
    const [errorLinkingToFRM, setErrorLinkingToFRM] = useState<string | null>(null)

    const tryToGetAllFRMLocationsForAccountUniqueIdentifier = useCallback(async () => {
        try {
            const allFRMLocations = await getAllFRMLocationsForServiceLocationUniqueIdentifier(serviceLocationUniqueId)
            setAvailableServiceLocations(allFRMLocations)
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load available locations'
            setErrorLoadingAvailableLocations(errorMessage)
        } finally {
            setLoadingAvailableLocations(false)
        }
    }, [serviceLocationUniqueId])

    const handleSelectFRMLocationIdForServiceLocationByUniqueIdentifier = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await selectFRMLocationIdForServiceLocationByUniqueIdentifier({
                service_location_unique_id: serviceLocationUniqueId,
                frm_location_id: selectedLocationId
            })
            // Reload the page, for now.
            window.location.reload()
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to select FRM location'
            setErrorLinkingToFRM(errorMessage)
        }
    }, [selectedLocationId, serviceLocationUniqueId])

    useEffect(() => {
        tryToGetAllFRMLocationsForAccountUniqueIdentifier()
    }, [tryToGetAllFRMLocationsForAccountUniqueIdentifier])

    if (loadingAvailableLocations) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingAvailableLocations) {
        return <div className='mt-5'>
            <div className="alert alert-danger" role="alert">
                {errorLoadingAvailableLocations}
            </div>
        </div>
    }

    return <div className='mb-5'>
        {availableServiceLocations.length === 0 ? <div className='mt-5'>
            <div className="alert alert-info" role="alert">
                No available locations
            </div>
        </div> : <div className='mt-5'>
            {errorLinkingToFRM && <div className='mt-5'>
                <div className="alert alert-danger" role="alert">
                    {errorLinkingToFRM}
                </div>
            </div>}
            <h2>
                Select a location:
            </h2>
            <form className='mt-5' onSubmit={handleSelectFRMLocationIdForServiceLocationByUniqueIdentifier}>
                <Select
                    required
                    options={[
                        { value: '', label: '- Leave un-mapped -' },
                        ...availableServiceLocations.map((availableServiceLocation) => {
                            return {
                                value: availableServiceLocation._id,
                                label: `${availableServiceLocation.name}`
                            }
                        })]}
                    onChange={(selectedOption) => {
                        setSelectedLocationId(selectedOption ? selectedOption.value : null)
                    }}
                    value={availableServiceLocations.filter(x => x._id === selectedLocationId).map((availableServiceLocation) => {
                        return {
                            value: availableServiceLocation._id,
                            label: `${availableServiceLocation.name}`
                        }
                    })}
                    filterOption={(option, searchValue) => {
                        const words = searchValue.toLowerCase().split(' ');
                        return words.every(word => option.label.toLowerCase().includes(word));
                    }}
                />
                <div>
                    <button className='btn btn-primary btn-sm mt-5' type='submit'>
                        Link
                    </button>
                </div>
            </form>
        </div>}
        <div className='separator separator-dashed my-5'></div>
    </div>
}

const DisableLinkButton = ({
    setViewingAvailableLocations,
    serviceLocationUniqueId,
    onSuccessfullyDisabled
}: {
    setViewingAvailableLocations: (viewingAvailableLocations: boolean) => void,
    serviceLocationUniqueId: string,
    onSuccessfullyDisabled: () => void
}) => {

    const [confirmingDisableLink, setConfirmingDisableLink] = useState(false)

    const [disablingLinkToFRM, setDisablingLinkToFRM] = useState(false)
    const [errorDisablingLinkToFRM, setErrorDisablingLinkToFRM] = useState<string | null>(null)

    const disableLinkToFRM = useCallback(async () => {
        if (!serviceLocationUniqueId) {
            return
        }
        setDisablingLinkToFRM(true)
        setErrorDisablingLinkToFRM(null)
        try {
            await disableFRMLinkForServiceLocationByUniqueIdentifier(serviceLocationUniqueId)
            onSuccessfullyDisabled()
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to disable link to FRM'
            setErrorDisablingLinkToFRM(errorMessage)
        } finally {
            setDisablingLinkToFRM(false)
        }
    }, [serviceLocationUniqueId, onSuccessfullyDisabled])

    return <div>
        {!confirmingDisableLink ? <>
            <button onClick={() => {
                setViewingAvailableLocations(true)
            }} className='btn btn-secondary btn-sm me-3'>
                Re-Link to FRM
            </button>
            <button className='btn btn-danger btn-sm' onClick={() => setConfirmingDisableLink(true)}>
                Disable Link to FRM
            </button>
        </> : <>
            <div className='alert alert-info' role='alert'>
                Are you sure you want to disable the link to FRM? This is permanent.
            </div>
            <div className='mt-5'>
                <button className='btn btn-secondary btn-sm' onClick={() => setConfirmingDisableLink(false)}>
                    Cancel
                </button>
                <button className='btn btn-danger btn-sm ms-3' onClick={() => disableLinkToFRM()} disabled={disablingLinkToFRM}>
                    {disablingLinkToFRM ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : 'Yes, Disable Link to FRM'}
                </button>
            </div>
            {errorDisablingLinkToFRM && <div className='mt-5'>
                <div className="alert alert-danger" role="alert">
                    {errorDisablingLinkToFRM}
                </div>
            </div>}
        </>}
    </div>
}

function CustomFieldsPickerSelectForCustomField({ customFields, selectedCustomFieldId, setSelectedCustomFieldId }: {
    customFields: AvailableCustomFRMField[],
    selectedCustomFieldId: string | null,
    setSelectedCustomFieldId: (selectedCustomFieldId: string | null) => void
}) {
    return <Select
        options={[
            { value: '', label: '- Leave un-mapped -' },
            ...customFields.map((customField) => {
                return {
                    value: customField.id,
                    label: `${customField.name} (${customField.fieldKey})`
                }
            })]}
        onChange={(selectedOption) => {
            setSelectedCustomFieldId(selectedOption ? selectedOption.value : null)
        }}
        value={customFields.filter(x => x.id === selectedCustomFieldId).map((customField) => {
            return {
                value: customField.id,
                label: `${customField.name} (${customField.fieldKey})`
            }
        })}
        filterOption={(option, searchValue) => {
            const words = searchValue.toLowerCase().split(' ');
            return words.every(word => option.label.toLowerCase().includes(word));
        }}
    />
}

// picker options is an array of strings...

function CustomFieldsPickerSelectForPickerOptions({ pickerOptions, selectedPickerOption, setSelectedPickerOption }: {
    pickerOptions: string[],
    selectedPickerOption: string | null,
    setSelectedPickerOption: (selectedPickerOption: string | null) => void
}) {
    return <Select
        options={[
            { value: '', label: '- Leave un-mapped -' },
            ...pickerOptions.map((pickerOption) => {
                return {
                    value: pickerOption,
                    label: pickerOption
                }
            })]}
        onChange={(selectedOption) => {
            setSelectedPickerOption(selectedOption ? selectedOption.value : null)
        }}
        value={pickerOptions.filter(x => x === selectedPickerOption).map((pickerOption) => {
            return {
                value: pickerOption,
                label: pickerOption
            }
        })}
        filterOption={(option, searchValue) => {
            const words = searchValue.toLowerCase().split(' ');
            return words.every(word => option.label.toLowerCase().includes(word));
        }}
    />
}

const CustomFieldsPicker = ({
    serviceLocation,
}: {
    serviceLocation: ServiceLocation
}) => {

    const serviceLocationUniqueId = serviceLocation.unique_identifier

    const [loadingCustomFields, setLoadingCustomFields] = useState(true)
    const [errorLoadingCustomFields, setErrorLoadingCustomFields] = useState<string | null>(null)
    const [customFields, setCustomFields] = useState<AvailableCustomFRMField[]>([])

    const [mapDateOfServiceToKey, setMapDateOfServiceToKey] = useState<string | null>(serviceLocation.private_data?.date_of_service_frm_custom_field_id || null)
    const [mapDeceasedPersonNameToKey, setMapDeceasedPersonNameToKey] = useState<string | null>(serviceLocation.private_data?.deceased_person_name_frm_custom_field_id || null)
    const [mapDeceasedPersonDateOfBirthToKey, setMapDeceasedPersonDateOfBirthToKey] = useState<string | null>(serviceLocation.private_data?.deceased_person_date_of_birth_frm_custom_field_id || null)
    const [mapDeceasedPersonDateOfDeathToKey, setMapDeceasedPersonDateOfDeathToKey] = useState<string | null>(serviceLocation.private_data?.deceased_person_date_of_death_frm_custom_field_id || null)

    const [mapRelationshipToDeceasedToKey, setMapRelationshipToDeceasedToKey] = useState<string | null>(serviceLocation.private_data?.relationship_to_deceased_frm_custom_field_id || null)
    const [mapInterestedInPrePlanningToKey, setMapInterestedInPrePlanningToKey] = useState<string | null>(serviceLocation.private_data?.interested_in_pre_planning_frm_custom_field_id || null)
    const [prePlanningSelectedPicklistOption, setPrePlanningSelectedPicklistOption] = useState<string | null>(serviceLocation.private_data?.pre_planning_frm_custom_selected_picklist_option || null)

    const [mapLatestReceivedMailerImageToKey, setMapLatestReceivedMailerImageToKey] = useState<string | null>(serviceLocation.private_data?.latest_received_mailer_image_frm_custom_field_id || null)

    // At some point we're going to throw this all away, but for now metadata is mapped specifically and more specifically for mailer_code and order_number
    const [mapLatestMetadataMailerCodeToKey, setMapLatestMetadataMailerCodeToKey] = useState<string | null>(serviceLocation.private_data?.latest_metadata_mailer_code_frm_custom_field_id || null)
    const [mapLatestMetadataOrderNumberToKey, setMapLatestMetadataOrderNumberToKey] = useState<string | null>(serviceLocation.private_data?.latest_metadata_order_number_frm_custom_field_id || null)

    const [updatingCustomFields, setUpdatingCustomFields] = useState(false)
    const [errorUpdatingCustomFields, setErrorUpdatingCustomFields] = useState<string | null>(null)

    const tryToGetCustomFieldsAvailableForServiceLocationByUniqueIdentifier = useCallback(async () => {
        try {
            const customFields = await getCustomFieldsAvailableForServiceLocationByUniqueIdentifier(serviceLocationUniqueId)
            setCustomFields(customFields)
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load custom fields'
            setErrorLoadingCustomFields(errorMessage)
        } finally {
            setLoadingCustomFields(false)
        }
    }, [serviceLocationUniqueId])

    const handleUpdateCustomFields = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            setUpdatingCustomFields(true)
            setErrorUpdatingCustomFields(null)
            await updateCustomFieldsForServiceLocationByUniqueIdentifier({
                service_location_unique_id: serviceLocationUniqueId,
                date_of_service_frm_custom_field_id: mapDateOfServiceToKey,
                deceased_person_name_frm_custom_field_id: mapDeceasedPersonNameToKey,
                deceased_person_date_of_birth_frm_custom_field_id: mapDeceasedPersonDateOfBirthToKey,
                deceased_person_date_of_death_frm_custom_field_id: mapDeceasedPersonDateOfDeathToKey,
                relationship_to_deceased_frm_custom_field_id: mapRelationshipToDeceasedToKey,
                interested_in_pre_planning_frm_custom_field_id: mapInterestedInPrePlanningToKey,
                pre_planning_frm_custom_selected_picklist_option: prePlanningSelectedPicklistOption,
                latest_received_mailer_image_frm_custom_field_id: mapLatestReceivedMailerImageToKey,
                latest_metadata_mailer_code_frm_custom_field_id: mapLatestMetadataMailerCodeToKey,
                latest_metadata_order_number_frm_custom_field_id: mapLatestMetadataOrderNumberToKey
            })
            // Reload the page, for now.
            window.location.reload()
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to select FRM location'
            setErrorUpdatingCustomFields(errorMessage)
        } finally {
            setUpdatingCustomFields(false)
        }
    }, [serviceLocationUniqueId, mapDateOfServiceToKey, mapDeceasedPersonNameToKey, mapDeceasedPersonDateOfBirthToKey, mapDeceasedPersonDateOfDeathToKey, mapRelationshipToDeceasedToKey, mapInterestedInPrePlanningToKey, prePlanningSelectedPicklistOption, mapLatestReceivedMailerImageToKey, mapLatestMetadataMailerCodeToKey, mapLatestMetadataOrderNumberToKey])

    useEffect(() => {
        tryToGetCustomFieldsAvailableForServiceLocationByUniqueIdentifier()
    }, [tryToGetCustomFieldsAvailableForServiceLocationByUniqueIdentifier])

    const matchingPickListItem = useMemo(() => {
        const matchingItem = customFields.filter(x => x.id === mapInterestedInPrePlanningToKey)
        if (!matchingItem.length) {
            return null
        }
        return matchingItem[0]
    }, [customFields, mapInterestedInPrePlanningToKey])

    if (loadingCustomFields) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingCustomFields) {
        return <div className='mt-5'>
            <div className="alert alert-danger" role="alert">
                {errorLoadingCustomFields}
            </div>
        </div>
    }

    return <form className='mt-5' onSubmit={handleUpdateCustomFields}>
        <h2>
            Map custom fields to FRM fields:
        </h2>
        <div className='mt-5'>
            <h4>Date of Service</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'DATE')}
                selectedCustomFieldId={mapDateOfServiceToKey}
                setSelectedCustomFieldId={setMapDateOfServiceToKey}
            />
        </div>
        <div className='mt-5'>
            <h4>Deceased Person Name</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'TEXT')}
                selectedCustomFieldId={mapDeceasedPersonNameToKey}
                setSelectedCustomFieldId={setMapDeceasedPersonNameToKey}
            />
        </div>
        <div className='mt-5'>
            <h4>Deceased Person Date of Birth</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'DATE')}
                selectedCustomFieldId={mapDeceasedPersonDateOfBirthToKey}
                setSelectedCustomFieldId={setMapDeceasedPersonDateOfBirthToKey}
            />
        </div>
        <div className='mt-5'>
            <h4>Deceased Person Date of Death</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'DATE')}
                selectedCustomFieldId={mapDeceasedPersonDateOfDeathToKey}
                setSelectedCustomFieldId={setMapDeceasedPersonDateOfDeathToKey}
            />
        </div>
        <div className='mt-5'>
            <h4>Relationship to Deceased</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'TEXT')}
                selectedCustomFieldId={mapRelationshipToDeceasedToKey}
                setSelectedCustomFieldId={setMapRelationshipToDeceasedToKey}
            />
        </div>
        <div className='mt-5'>
            <h4>Interested in Pre-Planning</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'CHECKBOX')}
                selectedCustomFieldId={mapInterestedInPrePlanningToKey}
                setSelectedCustomFieldId={setMapInterestedInPrePlanningToKey}
            />
            {matchingPickListItem && mapInterestedInPrePlanningToKey && <div>
                {/* Get the picklistOptions also because we want to select one. */}
                <div className='mt-5'>
                    <CustomFieldsPickerSelectForPickerOptions
                        pickerOptions={matchingPickListItem.picklistOptions}
                        selectedPickerOption={prePlanningSelectedPicklistOption}
                        setSelectedPickerOption={setPrePlanningSelectedPicklistOption}
                    />
                </div>
            </div>}
        </div>
        <div className='mt-5'>
            <h4>Direct Mail - Latest Received Mailer Image</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'FILE_UPLOAD')}
                selectedCustomFieldId={mapLatestReceivedMailerImageToKey}
                setSelectedCustomFieldId={setMapLatestReceivedMailerImageToKey}
            />
            <div className='mt-5'>
                <div className="text-muted">
                    Note that in the case of multiple mailers only the latest will be on the contact for now. This will be updated in the future.
                </div>
            </div>
        </div>
        <div className='mt-5'>
            <h4>Direct Mail - Metadata - Mailer Code</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'TEXT')}
                selectedCustomFieldId={mapLatestMetadataMailerCodeToKey}
                setSelectedCustomFieldId={setMapLatestMetadataMailerCodeToKey}
            />
        </div>
        <div className='mt-5'>
            <h4>Direct Mail - Metadata - Order Number</h4>
            <CustomFieldsPickerSelectForCustomField
                customFields={customFields.filter(x => x.dataType === 'TEXT')}
                selectedCustomFieldId={mapLatestMetadataOrderNumberToKey}
                setSelectedCustomFieldId={setMapLatestMetadataOrderNumberToKey}
            />
        </div>
        <div className='mt-5'>
            <button className='btn btn-primary btn-sm' type='submit' disabled={updatingCustomFields}>
                {updatingCustomFields ? <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                </div> : 'Update Custom Fields'}
            </button>
        </div>
        {errorUpdatingCustomFields && <div className='mt-5'>
            <div className="alert alert-danger" role="alert">
                {errorUpdatingCustomFields}
            </div>
        </div>}
    </form>
}

const UpsertContactManually = ({ serviceLocationUniqueId, linkOutToFRMWithURL, linkedToFRMLocationId }: {
    serviceLocationUniqueId: string,
    linkOutToFRMWithURL: string | null,
    linkedToFRMLocationId: string | null
}) => {

    const [contactName, setContactName] = useState('')
    const [contactPhoneNumber, setContactPhoneNumber] = useState('')
    const [contactEmailAddress, setContactEmailAddress] = useState('')

    const [contactDateOfService, setContactDateOfService] = useState('')
    const [contactDeceasedPersonName, setContactDeceasedPersonName] = useState('')
    const [contactDeceasedPersonDateOfBirth, setContactDeceasedPersonDateOfBirth] = useState('')
    const [contactDeceasedPersonDateOfDeath, setContactDeceasedPersonDateOfDeath] = useState('')

    const [contactRelationshipToDeceased, setContactRelationshipToDeceased] = useState('')
    const [contactInterestedInPrePlanning, setContactInterestedInPrePlanning] = useState(false)

    const [creatingContact, setCreatingContact] = useState(false)
    const [errorCreatingContact, setErrorCreatingContact] = useState<string | null>(null)

    const [successfullyCreatedContactGHLID, setSuccessfullyCreatedContactGHLID] = useState<string | null>(null)

    const handleCreateContact = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            setCreatingContact(true)
            setErrorCreatingContact(null)
            setSuccessfullyCreatedContactGHLID(null)
            const response = await createContactForServiceLocationByUniqueIdentifier({
                service_location_unique_id: serviceLocationUniqueId,
                contact_name: contactName,
                contact_phone_number: contactPhoneNumber,
                contact_email_address: contactEmailAddress,
                contact_date_of_service: contactDateOfService,
                contact_deceased_person_name: contactDeceasedPersonName,
                contact_deceased_person_date_of_birth: contactDeceasedPersonDateOfBirth,
                contact_deceased_person_date_of_death: contactDeceasedPersonDateOfDeath,
                contact_relationship_to_deceased: contactRelationshipToDeceased,
                contact_interested_in_pre_planning: contactInterestedInPrePlanning
            })
            if (response.result?.contact?.id) {
                setSuccessfullyCreatedContactGHLID(response.result.contact.id)
            } else {
                setErrorCreatingContact('Failed to create contact (invalid response)')
            }
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to create contact'
            setErrorCreatingContact(errorMessage)
        } finally {
            setCreatingContact(false)
        }
    }, [serviceLocationUniqueId, contactName, contactPhoneNumber, contactEmailAddress, contactDateOfService, contactDeceasedPersonName, contactDeceasedPersonDateOfBirth, contactDeceasedPersonDateOfDeath, contactRelationshipToDeceased, contactInterestedInPrePlanning])

    return <div className='mt-5'>
        <div className='separator separator-dashed my-5'></div>
        <h2>
            Test FRM Sync:
        </h2>
        <div>
            <p>
                Create a contact manually and see if it syncs with the FRM.
            </p>
        </div>
        <form className='mt-5' onSubmit={handleCreateContact}>
            <div className='mb-5'>
                <label className='form-label'>Contact Name</label>
                <input
                    className='form-control'
                    type='text'
                    value={contactName}
                    onChange={(event) => setContactName(event.target.value)}
                    required
                />
            </div>
            <div className='mb-5'>
                <label className='form-label'>Contact Phone</label>
                <input
                    className='form-control'
                    type='text'
                    value={contactPhoneNumber}
                    onChange={(event) => setContactPhoneNumber(event.target.value)}
                    required
                />
            </div>
            <div className='ps-10 mb-5'>
                <h4>Optional Values:</h4>
                <div className='mt-5'>
                    <label className='form-label'>Contact Email</label>
                    <input
                        className='form-control'
                        type='email'
                        value={contactEmailAddress}
                        onChange={(event) => setContactEmailAddress(event.target.value)}
                        required={false}
                    />
                </div>
                <div className='mt-5'>
                    <label className='form-label'>Date of Service</label>
                    <input
                        className='form-control'
                        type='date'
                        value={contactDateOfService}
                        onChange={(event) => setContactDateOfService(event.target.value)}
                    />
                </div>
                <div className='mt-5'>
                    <label className='form-label'>Deceased Person Name</label>
                    <input
                        className='form-control'
                        type='text'
                        value={contactDeceasedPersonName}
                        onChange={(event) => setContactDeceasedPersonName(event.target.value)}
                    />
                </div>
                <div className='mt-5'>
                    <label className='form-label'>Deceased Person Date of Birth</label>
                    <input
                        className='form-control'
                        type='date'
                        value={contactDeceasedPersonDateOfBirth}
                        onChange={(event) => setContactDeceasedPersonDateOfBirth(event.target.value)}
                    />
                </div>
                <div className='mt-5'>
                    <label className='form-label'>Deceased Person Date of Death</label>
                    <input
                        className='form-control'
                        type='date'
                        value={contactDeceasedPersonDateOfDeath}
                        onChange={(event) => setContactDeceasedPersonDateOfDeath(event.target.value)}
                    />
                </div>
                <div className='mt-5'>
                    <label className='form-label'>Relationship to Deceased</label>
                    <input
                        className='form-control'
                        type='text'
                        value={contactRelationshipToDeceased}
                        onChange={(event) => setContactRelationshipToDeceased(event.target.value)}
                    />
                </div>
                <div className='mt-5'>
                    <label className='form-label me-3'>Interested in Pre-Planning</label>
                    <input
                        className='form-check-input'
                        type='checkbox'
                        checked={contactInterestedInPrePlanning}
                        onChange={(event) => setContactInterestedInPrePlanning(event.target.checked)}
                    />
                </div>
            </div>
            <div>
                <button className='btn btn-primary btn-sm' type='submit' disabled={creatingContact}>
                    {creatingContact ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : 'Create Contact'}
                </button>
            </div>
        </form>
        {successfullyCreatedContactGHLID && <div className='mt-5'>
            <div className="alert alert-success" role="alert">
                {(!linkOutToFRMWithURL || !linkedToFRMLocationId) ? <div>
                    Successfully created contact with GHL ID: {successfullyCreatedContactGHLID}
                </div> : <div>
                    {/* /v2/location/IQzMIO91nK1KTq0PCwq8/contacts/smart_list/All */}
                    Successfully created contact with GHL ID: <a href={`${linkOutToFRMWithURL}/v2/location/${linkedToFRMLocationId}/contacts/detail/${successfullyCreatedContactGHLID}`} target='_blank' rel='noreferrer'>
                        View in FRM
                    </a>
                </div>}
            </div>
        </div>}
        {errorCreatingContact && <div className='mt-5'>
            <div className="alert alert-danger" role="alert">
                {errorCreatingContact}
            </div>
        </div>}
    </div>

}

function ServiceLocationSettings360Details() {
    const {
        serviceLocation
    } = useServiceLocationDetail();

    const { accounts } = useAccounts() // redundant retrieval but it'll make sure it's updated. May be in a global context one day.

    const [viewingAvailableLocations, setViewingAvailableLocations] = useState(false)

    const linkOutToFRMWithURL = useMemo(() => {
        //
        // Right now service locations don't have GHL details because it's an admin detail not added to that
        // retrieval. The quickest fix right now is looking up the admin account mapping
        // and deducing the URL from that.
        //
        if (!serviceLocation) {
            return null
        }
        const matchingAccounts = accounts.filter(x => x.unique_identifier === serviceLocation?.account.unique_identifier);
        if (!matchingAccounts.length) {
            return null
        }
        const account = matchingAccounts[0];
        if (account.external_ghl_site_url) {
            return account.external_ghl_site_url
        }
        return null
    }, [accounts, serviceLocation])

    return <div>
        {viewingAvailableLocations ? <div>
            <div className='mt-5'>
                <GetAvailableLocationsGivenAServiceLocation serviceLocationUniqueId={serviceLocation.unique_identifier} />
            </div>
            <div className='mt-5'>
                <button className='btn btn-secondary btn-lg' onClick={() => setViewingAvailableLocations(false)}>
                    Back
                </button>
            </div>
        </div> : <div className='mt-5'>
            {!serviceLocation.private_data?.linked_to_frm_location_id ? <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={() => setViewingAvailableLocations(true)}>
                    Link to FRM
                </button>
            </div> : <div>
                <div className='mt-5'>
                    Linked to FRM
                </div>
                {!linkOutToFRMWithURL ? <div>
                    FRM Location ID: {serviceLocation.private_data.linked_to_frm_location_id}
                </div> : <div>
                    {/* /v2/location/IQzMIO91nK1KTq0PCwq8/contacts/smart_list/All */}
                    FRM Location ID: <a href={`${linkOutToFRMWithURL}/v2/location/${serviceLocation.private_data.linked_to_frm_location_id}/contacts/smart_list/All`} target='_blank' rel='noreferrer'>
                        {serviceLocation.private_data?.linked_to_frm_location_id}
                    </a>
                </div>}
                <div className='mt-5'>
                    <DisableLinkButton
                        setViewingAvailableLocations={setViewingAvailableLocations}
                        serviceLocationUniqueId={serviceLocation.unique_identifier}
                        onSuccessfullyDisabled={() => {
                            window.location.reload()
                        }}
                    />
                </div>
                <div className='mt-5'>
                    <div className='separator separator-dashed my-5'></div>
                    <CustomFieldsPicker serviceLocation={serviceLocation} />
                </div>
                <UpsertContactManually
                    linkOutToFRMWithURL={linkOutToFRMWithURL}
                    linkedToFRMLocationId={serviceLocation.private_data.linked_to_frm_location_id}
                    serviceLocationUniqueId={serviceLocation.unique_identifier}
                />
            </div>}
        </div>}
    </div>
}

function ServiceLocationSettingsDetails() {
    const {
        serviceLocation
    } = useServiceLocationDetail();

    const [savingServiceLocation, setSavingServiceLocation] = useState(false)

    const [serviceLocationName, setServiceLocationName] = useState(serviceLocation.name || '')
    const [errorUpdating, setErrorUpdating] = useState<string | null>(null)

    const handleUpdateServiceLocation = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setSavingServiceLocation(true)
        setErrorUpdating(null)
        try {
            await updateServiceLocationDetailsByUniqueIdentifier({
                service_location_unique_id: serviceLocation.unique_identifier,
                name: serviceLocationName,
            })
            // until we have a global context, reload the page.
            window.location.reload()
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to update service location'
            setErrorUpdating(errorMessage)
            setSavingServiceLocation(false)
        } finally {
            // We don't set setSavingServiceLocation(false) here because we're reloading the page.
        }
    }, [serviceLocation.unique_identifier, serviceLocationName])

    return <div>
        <h1>Service Location Settings</h1>
        <form className='mt-5' onSubmit={handleUpdateServiceLocation}>
            <div className='mb-5'>
                <label className='form-label'>Service Location Name</label>
                <input
                    className='form-control'
                    type='text'
                    value={serviceLocationName}
                    onChange={(event) => setServiceLocationName(event.target.value)}
                    required
                />
            </div>
            {/* submit: */}
            <div>
                <button className='btn btn-primary btn-sm' type='submit' disabled={savingServiceLocation}>
                    {savingServiceLocation ? <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : 'Save'}
                </button>
            </div>
            {errorUpdating && <div className='mt-5'>
                <div className="alert alert-danger" role="alert">
                    {errorUpdating}
                </div>
            </div>}
        </form>
    </div>
}


function ServiceLocationSettings() {
    const [viewingSubTab, setViewingSubTab] = useState<'details' | '360'>('360')

    return <div>
        <div className="header-menu align-items-stretch mb-5">
            <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                <div className="menu-item me-lg-1">
                    <button className={["menu-link py-3 btn btn-link-primary", viewingSubTab === 'details' ? "active" : ""].join(" ")}
                        onClick={() => setViewingSubTab('details')}
                        type="button"
                    >
                        <span className="menu-title">Details</span>
                    </button>
                </div>
                <div className="menu-item me-lg-1">
                    <button className={["menu-link py-3 btn btn-link-primary", viewingSubTab === '360' ? "active" : ""].join(" ")}
                        onClick={() => setViewingSubTab('360')}
                        type="button"
                    >
                        <span className="menu-title">360 integration</span>
                    </button>
                </div>
            </div>
        </div>
        <div>
            {viewingSubTab === 'details' ? <ServiceLocationSettingsDetails /> : <ServiceLocationSettings360Details />}
        </div>
    </div>
}

export default ServiceLocationSettings;