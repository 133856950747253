import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import {
  StatisticsWidget4,
} from '../../../_metronic/partials/widgets'
import DashboardGraph from '../../../mogul/components/DashboardGraph'
import { DashboardListForEvents } from '../../../mogul/components/DashboardListForEvents'
import { DashboardListForDirectMailReplies } from '../../../mogul/components/DashboardListForDirectMailReplies'
import MogulHeader from '../../../mogul/components/MogulHeader'

const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]

const DashboardPage = () => {

  const [viewingTab, setViewingTab] = useState<'graph_by_day' | 'events_list_by_day' | 'direct_mail_replies_by_day'>('graph_by_day');

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  useEffect(() => {
    // if local, show events_administrators on load:
    // if (window.location.hostname === 'localhost') {
    if (process.env.NODE_ENV === 'development') {
      return;
      setViewingTab('direct_mail_replies_by_day')
    }
  }, [])

  return (
    <>
      <MogulHeader title='Main Dashboard' />
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
        {/* begin::Col */}
        {false && <div className='col-xxl-4'>
          <StatisticsWidget4
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            svgIcon='element-11'
            color='danger'
            description='Weekly Income'
            change='750$'
          />
        </div>}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-12'>
          <div className="header-menu align-items-stretch mb-5">
            <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
              <div className="menu-item me-lg-1">
                <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'graph_by_day' ? "active" : ""].join(" ")}
                  onClick={() => setViewingTab('graph_by_day')}
                  type="button"
                >
                  <span className="menu-title">
                    Charted Data
                  </span>
                </button>
              </div>
              <div className="menu-item me-lg-1">
                <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'events_list_by_day' ? "active" : ""].join(" ")}
                  onClick={() => setViewingTab('events_list_by_day')}
                  type="button"
                >
                  <span className="menu-title">
                    List of Events
                  </span>
                </button>
              </div>
              <div className="menu-item me-lg-1">
                <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'direct_mail_replies_by_day' ? "active" : ""].join(" ")}
                  onClick={() => setViewingTab('direct_mail_replies_by_day')}
                  type="button"
                >
                  <span className="menu-title">
                    Direct Mail Replies
                  </span>
                </button>
              </div>
            </div>
          </div>

          {viewingTab === 'graph_by_day' && <DashboardGraph />}
          {viewingTab === 'events_list_by_day' && <DashboardListForEvents />}
          {viewingTab === 'direct_mail_replies_by_day' && <DashboardListForDirectMailReplies />}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
