import { useState, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { APIError } from "../../app/modules/auth"
import { linkAccountToFRMByUniqueIdentifier } from "../core/_requests"

function FRMOauthRoutes() {

    const navigate = useNavigate()

    const [loadingError, setLoadingError] = useState<string | null>(null)

    const tryToLoadCode = useCallback(async () => {
        const queryParameters = new URLSearchParams(window.location.search)
        const code = queryParameters.get('code')
        const state = queryParameters.get('state')
        if (!code) {
            navigate(state ? `/accounts/${state}` : '/')
            return
        }
        try {
            const result = await linkAccountToFRMByUniqueIdentifier({
                unique_identifier: state,
                code,
            })
            navigate(`/accounts/${result.unique_identifier}`)
            return
        } catch (error: any) {
            console.warn('error', error)
            const errorMessage = error instanceof APIError ? error.message : 'Something went wrong'
            setLoadingError(errorMessage)
        }
    }, [navigate])

    useEffect(() => {
        tryToLoadCode()
    }, [tryToLoadCode])

    if (loadingError) {
        return <div className='alert alert-danger'>{loadingError || 'Something went wrong'}</div>
    }
    return <div>
        <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
}

export default FRMOauthRoutes