import { useState, useCallback, useEffect } from "react"
import {
    DateTime
} from 'luxon'
import { APIError } from "../../../../app/modules/auth"
import { getWarningsForAccountByUniqueIdentifier, ServiceLocation } from "../../../core/_requests"
import { useAccountDetail } from "../../AccountsRoutes"
import { Link } from "react-router-dom"

function AccountWarnings() {

    const {
        account
    } = useAccountDetail()

    const [locationsMissingAssignment, setLocationsMissingAssignment] = useState<ServiceLocation[]>([])
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const loadAccountWarnings = useCallback(async () => {
        setLoading(true)
        try {
            const {
                locationsMissingAssignment: updatedLocationsMissingAssignment,
            } = await getWarningsForAccountByUniqueIdentifier(account.unique_identifier)
            setLocationsMissingAssignment(updatedLocationsMissingAssignment)
            setErrorMessage(null)
        } catch (exception) {
            console.error(exception)
            const errorMessage =
                exception instanceof APIError ? exception.message : 'Failed to load account warnings'
            setErrorMessage(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [account.unique_identifier])

    useEffect(() => {
        loadAccountWarnings()
    }, [loadAccountWarnings])

    if (loading) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorMessage) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorMessage || 'Failed to load account warnings'}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadAccountWarnings}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <div>
        <div>
            <h4>
                Account Warnings
            </h4>
        </div>

        {locationsMissingAssignment.length === 0 ? <div>
            <div className='mt-3'>
                <div className="alert alert-info" role="alert">
                    No warnings for this account
                </div>
            </div>
        </div> : <div>
            <div className='mt-3'>
                <div className="alert alert-info" role="alert">
                    The following locations have received leads but do not have assigned marketers
                </div>
            </div>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        <th scope='col' className='fw-bold'>Location</th>
                        <th scope='col' className='fw-bold'>Last Lead Received</th>
                        <th scope='col' className='fw-bold'>Last Event</th>
                        <th scope='col' className='fw-bold'>Last Direct Mail Reply</th>
                        <th scope='col' className='fw-bold'></th>
                    </tr>
                </thead>
                <tbody>
                    {locationsMissingAssignment.map((location, index) => {

                        const relevantDates = [
                            location.last_event_check_in_record_inserted_at,
                            location.last_event_administrator_inserted_at,
                            location.last_direct_mail_reply_inserted_at
                        ].filter((d): d is NonNullable<typeof d> => d != null);
                        const mostRecentDate = relevantDates.length > 0 ? relevantDates.reduce((a, b) => a > b ? a : b) : null;

                        return <tr key={index}>
                            <td>
                                <Link
                                    to={`/service-locations/${location.unique_identifier}`}
                                >
                                    {location.name}
                                </Link>
                            </td>
                            <td>
                                <div className='fw-bold text-gray-400'>
                                    {mostRecentDate ? `Last lead received: ${DateTime.fromISO(mostRecentDate).toRelative()}` : 'No leads received'}
                                </div>
                            </td>
                            <td>
                                <div className='fw-bold text-gray-400'>
                                    {location.last_event_check_in_record_inserted_at ? `Last event: ${DateTime.fromISO(location.last_event_check_in_record_inserted_at).toRelative()}` : 'No events'}
                                </div>
                            </td>
                            <td>
                                <div className='fw-bold text-gray-400'>
                                    {location.last_direct_mail_reply_inserted_at ? `Last direct mail reply: ${DateTime.fromISO(location.last_direct_mail_reply_inserted_at).toRelative()}` : 'No direct mail replies'}
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>}
    </div>
}

export default AccountWarnings;