import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export class APIError {
  message: string
  response: any

  constructor(message: string, response: any) {
    this.message = message
    this.response = response
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.api_token) {
        config.headers.Authorization = `Bearer ${auth.api_token}`
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    function (response: any) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error: any) {
      // If 404, setup custom error message
      console.log('error: ', error)
      if (error.response && error.response.status === 404) {
        const route = error.config.url.replace(error.config.baseURL, '')
        return Promise.reject(new APIError(`Not found: ${route}`, error.response))
      } else if (error?.response?.data?.error) {
        if (typeof error?.response?.data?.error === 'string') {
          return Promise.reject(new APIError(error.response.data.error, error.response))
        } else if (
          error?.response?.data?.error.message &&
          typeof error?.response?.data?.error.message === 'string'
        ) {
          return Promise.reject(new APIError(error?.response?.data?.error.message, error.response))
        } else {
          console.warn('Unexpected API error format')
          return Promise.reject(new APIError('Unknown API error', error.response))
        }
      }
      // If Network Error, setup custom error message
      if (error.message === 'Network Error') {
        return Promise.reject(new APIError('Network Error', error.response))
      }
      console.warn('axios error', error)
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
