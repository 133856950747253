/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, createContext, useContext } from 'react'
import {
  DateTime
} from 'luxon'

import {
  Account,
  getAllPipedriveAccounts,
  getAllPipedriveDeals,
  getAllPipedrivePersons
} from '../../../../mogul/core/_requests'
import { APIError, useAuth } from '../../../../app/modules/auth'
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import useAccounts from '../../../../mogul/hooks/useAccounts'
import MogulHeader from '../../../../mogul/components/MogulHeader'
import { FilterAllLeads } from './FilterAllLeads'
import { PipedriveAccount, PipedriveDeal, PipedrivePerson } from '../../../../mogul/routes/PartnersRoutes'
import { FilterPipedriveDeals } from './pipedrive/FilterPipedriveDeals'
import { PipedriveAccountDetail } from './pipedrive/PipedriveAccountDetail'
import { PipedriveDealList } from './pipedrive/PipedriveDealList'

type LeadDetailContextProps = {
  accounts: Account[],
}

const LeadDetailContext = createContext<LeadDetailContextProps>({
  accounts: [],
})

export const useLeadDetail = () => {
  return useContext(LeadDetailContext)
}

export const formatLeadItemDate = (fromDatetime: string, relativeTiming = false) => {
  try {
    const dateTime = DateTime.fromISO(fromDatetime)
      .setZone('America/New_York');
    const isDST = dateTime.isInDST; // Check if the date is in daylight saving time
    const tzAbbreviation = isDST ? 'EDT' : 'EST'; // Choose abbreviation based on DST
    const baseDate = dateTime.toFormat(`yyyy-MM-dd HH:mm '${tzAbbreviation}' (EEEE)`);
    const relativeDate = dateTime.toRelative();
    return relativeTiming ? relativeDate : baseDate;
  } catch (e) {
    return 'Invalid DateTime';
  }
}


export const formatLeadItemDateBetter = (fromDatetime: string) => {
  /*
  Explanation:

  Within an hour: If the difference is less than 60 minutes, it returns the number of minutes ago.
  Within a day: If the difference is less than 24 hours, it returns the number of hours ago.
  Within a week: If the difference is less than 7 days, it returns the number of days ago along with the date.
  If it was exactly one day ago, it specifies "Yesterday".
  Older than a week: It formats the date as MM/DD HH:mm EDT (Day of Week) without the year.
  Example Outputs:

  For a timestamp 15 minutes ago: "15 minutes ago"
  For a timestamp 5 hours ago: "5 hours ago"
  For a timestamp from yesterday: "Yesterday (10/10)"
  For a timestamp 3 days ago: "3 days ago (10/08)"
  For a timestamp older than a week: "10/01 14:00 EDT (Tuesday)"
  This function should meet your requirements and provide a more human-readable date format based on the age of the timestamp.
  */
  try {
    const dateTime = DateTime.fromISO(fromDatetime).setZone('America/New_York');
    const nowDateTime = DateTime.now().setZone('America/New_York');

    const diffInMinutes = nowDateTime.diff(dateTime, 'minutes').minutes;

    if (diffInMinutes < 60) {
      const minutesAgo = Math.floor(diffInMinutes);
      return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
    }

    const diffInHours = nowDateTime.diff(dateTime, 'hours').hours;
    if (diffInHours < 24) {
      const hoursAgo = Math.floor(diffInHours);
      return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
    }

    const diffInDays = nowDateTime.diff(dateTime, 'days').days;
    if (diffInDays < 7) {
      const daysAgo = Math.floor(diffInDays);
      if (daysAgo === 1) {
        return `Yesterday (${dateTime.toFormat('MM/dd')})`;
      } else {
        return `${daysAgo} days ago (${dateTime.toFormat('MM/dd')})`;
      }
    }

    // For dates older than a week
    const isDST = dateTime.isInDST; // Check if the date is in daylight saving time
    const tzAbbreviation = isDST ? 'EDT' : 'EST'; // Choose abbreviation based on DST

    return dateTime.toFormat(`MM/dd HH:mm '${tzAbbreviation}' (EEEE)`);
  } catch (e) {
    return 'Invalid DateTime';
  }
};

const getSingleLinePhones = (pipedrivePerson: PipedrivePerson) => {
  // Will probably come from the back-end one day, or PipedrivePerson will become a class
  return pipedrivePerson.latest_person_json.phone.map((phone) => phone.value).join(', ')
}

const getSingleLineEmails = (pipedrivePerson: PipedrivePerson) => {
  // Will probably come from the back-end one day, or PipedrivePerson will become a class
  return pipedrivePerson.latest_person_json.email.map((email) => email.value).join(', ')
}

function Header({
  title,
  subtitle,
  includeTokenRequests,
  setIncludeTokenRequests,
  setFilteringByLocation,
}: {
  title: string,
  subtitle?: string,
  includeTokenRequests: boolean,
  setIncludeTokenRequests: (value: boolean) => void,
  setFilteringByLocation: (locationUniqueIdentifier: string | null) => void,
}) {

  const [viewingFilter, setViewingFilter] = useState(false)

  return <>
    {/* begin::Header */}
    <div className='card-header align-items-center border-0 mt-3'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='fw-bolder text-dark fs-3'>
          {title}
        </span>
        {subtitle && <span className='text-gray-400 mt-2 fw-bold fs-6'>{subtitle}</span>}
      </h3>
      {false && <div className='card-toolbar'>
        {/* begin::Menu */}
        <button
          type='button'
          className='btn btn-sm btn-light me-2'
          onClick={() => setViewingFilter(!viewingFilter)}
        >
          <span className='indicator-label'>
            Filter List
          </span>
        </button>
        {/* end::Menu */}
      </div>}
    </div>
  </>

}

function AccountsTable() {

  const {
    currentUser
  } = useAuth()

  const {
    accounts
  } = useLeadDetail()

  const isAdmin = !!currentUser?.is_admin

  const [showLeadsRegardlessofGHLStatus, setShowLeadsRegardlessofGHLStatus] = useState(isAdmin ? false : true)

  const [filteringByLocationUniqueIdentifier, setFilteringByLocationUniqueIdentifier] = useState<string | null>(null)

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState<string | null>(null);

  const [pipedriveAccounts, setPipedriveAccounts] = useState<PipedriveAccount[]>([])

  const loadList = useCallback(async () => {
    // We reload on viewingTab for now
    setErrorLoading(null)
    setLoading(true)
    try {
      const result = await getAllPipedriveAccounts()
      setPipedriveAccounts(result)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load leads'
      setErrorLoading(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadList()
  }, [loadList])

  if (loading) {
    return <div className='p-10'>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  if (errorLoading) {
    return <div className='p-10'>
      <div className="alert alert-danger" role="alert">
        {errorLoading}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={loadList}>
          Retry
        </button>
      </div>
    </div>
  }

  return <div>
    <Header
      title={pipedriveAccounts.length === 1 ? '1 Account' : `${pipedriveAccounts.length} Accounts`}
      subtitle={''}
      includeTokenRequests={showLeadsRegardlessofGHLStatus}
      setIncludeTokenRequests={setShowLeadsRegardlessofGHLStatus}
      setFilteringByLocation={setFilteringByLocationUniqueIdentifier}
    />
    <div className='px-10'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-150px'>Domain</th>
            <th className='min-w-150px'>When Created?</th>
            <th className='min-w-150px'>Last Update?</th>
            {/*<th className='min-w-150px'>Link to PipeDrive</th>*/}
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {pipedriveAccounts.map((pipedriveAccount) => {
            return <tr key={`event-administrator-${pipedriveAccount.unique_identifier}`} data-unique-identifier={pipedriveAccount.unique_identifier}>
              {/*<td>
                <Link to={`/debugging/all-pipedrive/accounts/${pipedriveAccount.unique_identifier}`}>
                  {pipedriveAccount.api_domain}
                </Link>
              </td>
              */}
              <td>
                <a href={pipedriveAccount.api_domain.includes('http') ? pipedriveAccount.api_domain : `https://${pipedriveAccount.api_domain}`}
                  target='_blank' rel='noreferrer'>
                  {pipedriveAccount.api_domain}
                </a>
              </td>
              <td>
                {formatLeadItemDate(pipedriveAccount.inserted_at)}
              </td>
              <td>
                {formatLeadItemDate(pipedriveAccount.updated_at, true)}
              </td>
            </tr>
          })}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  </div>

}

function PeopleTable() {

  const {
    currentUser
  } = useAuth()

  const {
    accounts
  } = useLeadDetail()

  const isAdmin = !!currentUser?.is_admin

  const [showLeadsRegardlessofGHLStatus, setShowLeadsRegardlessofGHLStatus] = useState(isAdmin ? false : true)

  const [filteringByLocationUniqueIdentifier, setFilteringByLocationUniqueIdentifier] = useState<string | null>(null)

  const [loading, setLoading] = useState(true);
  const [errorLoading, setErrorLoading] = useState<string | null>(null);

  const [pipedrivePersons, setPipedrivePersons] = useState<PipedrivePerson[]>([])

  const loadList = useCallback(async () => {
    // We reload on viewingTab for now
    setErrorLoading(null)
    setLoading(true)
    try {
      const result = await getAllPipedrivePersons()
      setPipedrivePersons(result)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load leads'
      setErrorLoading(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadList()
  }, [loadList])

  if (loading) {
    return <div className='p-10'>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  if (errorLoading) {
    return <div className='p-10'>
      <div className="alert alert-danger" role="alert">
        {errorLoading}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={loadList}>
          Retry
        </button>
      </div>
    </div>
  }

  return <div>
    <Header
      title={pipedrivePersons.length === 1 ? '1 Person' : `${pipedrivePersons.length} People`}
      subtitle={''}
      includeTokenRequests={showLeadsRegardlessofGHLStatus}
      setIncludeTokenRequests={setShowLeadsRegardlessofGHLStatus}
      setFilteringByLocation={setFilteringByLocationUniqueIdentifier}
    />
    <div className='px-10'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bold text-muted'>
            <th className='min-w-150px'>When Created?</th>
            <th className='min-w-150px'>Last Update?</th>
            <th className='min-w-150px'>Name</th>
            <th className='min-w-150px'>Phone(s)</th>
            <th className='min-w-150px'>Email(s)</th>
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {pipedrivePersons.map((pipedrivePerson) => {

            return <tr key={`pipedrive-person-${pipedrivePerson.unique_identifier}`} data-unique-identifier={pipedrivePerson.unique_identifier}>
              <td>
                {formatLeadItemDate(pipedrivePerson.inserted_at)}
              </td>
              <td>
                {formatLeadItemDate(pipedrivePerson.updated_at, true)}
              </td>
              <td>
                {pipedrivePerson.latest_person_json.name}
              </td>
              <td>
                {getSingleLinePhones(pipedrivePerson)}
              </td>
              <td>
                {getSingleLineEmails(pipedrivePerson)}
              </td>
            </tr>
          })}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  </div>

}

function LeadList({ children }: { children: React.ReactNode }) {

  const {
    accounts,
    loadingAccounts,
  } = useAccounts()

  if (loadingAccounts) {
    return <div className='mt-5'>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  }

  return <LeadDetailContext.Provider value={{
    accounts
  }}>
    {children}
  </LeadDetailContext.Provider>
}

export function DebugPipedrive() {

  const { pathname } = useLocation()

  return (
    <>
      {/* Manage Premier Preneed */}
      <MogulHeader title={`All Pipedrive Data`} />
      <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
        <div className='col-xxl-12'>
          <div className="header-menu align-items-stretch mb-5">
            <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
              <div className="menu-item me-lg-1">
                <Link className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-pipedrive/accounts` ? "active" : ""].join(" ")}
                  to={`/debugging/all-pipedrive/accounts`}
                >
                  <span className="menu-title">
                    Accounts
                  </span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <Link
                  className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-pipedrive/deals` ? "active" : ""].join(" ")}
                  to={`/debugging/all-pipedrive/deals`}
                >
                  <span className="menu-title">
                    Deals
                  </span>
                </Link>
              </div>
              <div className="menu-item me-lg-1">
                <Link
                  className={["menu-link py-3 btn btn-link-primary", pathname === `/debugging/all-pipedrive/people` ? "active" : ""].join(" ")}
                  to={`/debugging/all-pipedrive/people`}
                >
                  <span className="menu-title">
                    People
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            <Routes>
              <Route path='accounts' element={<LeadList>
                <AccountsTable />
              </LeadList>} />
              <Route path='accounts/:pipedriveAccountUniqueIdentifier' element={<PipedriveAccountDetail />} />
              <Route path='deals' element={<LeadList>
                <PipedriveDealList />
              </LeadList>} />
              <Route path='people' element={<LeadList>
                <PeopleTable />
              </LeadList>} />
              <Route path='*' element={<Navigate to='/debugging/all-pipedrive/deals' />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}