/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  DateTime
} from 'luxon'

import { DashboardHighLevelData, HighLevelRequestData, getDashboardHighLevelRequests, getHighLevelRequestsWithPage } from '../../../../mogul/core/_requests'
import { APIError } from '../../../../app/modules/auth'
import MogulHeader from '../../../../mogul/components/MogulHeader'

type Props = {
}

const formatHighlevelRequestDate = (highlevelRequest) => {
  try {
    const dateTime = DateTime.fromISO(highlevelRequest.inserted_at)
      .setZone('America/New_York');
    const isDST = dateTime.isInDST; // Check if the date is in daylight saving time
    const tzAbbreviation = isDST ? 'EDT' : 'EST'; // Choose abbreviation based on DST
    return dateTime.toFormat(`yyyy-MM-dd HH:mm '${tzAbbreviation}' (EEEE)`);
  } catch (e) {
    return 'Invalid DateTime';
  }
}


function HighlevelRequestRow({ highlevelRequest }: { highlevelRequest: HighLevelRequestData }) {

  const parsedResponse = useMemo(() => {
    try {
      const result = JSON.parse(highlevelRequest.response_text)
      return result
    } catch (e) {
      return highlevelRequest.response_text
    }
  }, [highlevelRequest.response_text])

  return <tr key={`ghl-req-${highlevelRequest.unique_identifier}`}>
    <td>
      {formatHighlevelRequestDate(highlevelRequest)}
    </td>
    <td>
      {highlevelRequest.account?.name || 'N/A'}
    </td>
    <td>
      {highlevelRequest.location?.name || 'N/A'}
    </td>
    <td>
      {highlevelRequest.request_url.replace('https://services.leadconnectorhq.com/', '')}
    </td>
    <td>
      {highlevelRequest.response_status}
    </td>
    <td>
      {[200, 201].indexOf(highlevelRequest.response_status) > -1 ? <>
        {typeof parsedResponse === 'object' ? <>
          {highlevelRequest.request_url.endsWith('contacts/upsert') ? <>
            {parsedResponse?.contact?.id && parsedResponse?.contact?.locationId ? <>
              {highlevelRequest.account?.external_ghl_site_url ? <>
                <a
                  href={`${highlevelRequest.account?.external_ghl_site_url}/v2/location/${parsedResponse?.contact?.locationId}/contacts/detail/${parsedResponse?.contact?.id}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {`Contact ${parsedResponse?.contact?.id}`}
                </a>
              </> : <>
                {`Contact ${parsedResponse?.contact?.id} with location ${parsedResponse?.contact?.locationId} created`}
              </>}
            </> : <>
              Bad Response
            </>}
          </> : <>
            Unknown Success
          </>}
        </> : 'Bad Response'}
      </> : <>
        {highlevelRequest.response_text}
      </>}
    </td>
    <td>
      {[200, 201].indexOf(highlevelRequest.response_status) > -1 ? <>
        {highlevelRequest.request_body}
      </> : <>
        {highlevelRequest.request_body}
      </>}
    </td>
    <td>
      {[200, 201].indexOf(highlevelRequest.response_status) > -1 ? <>
      </> : <>
        {highlevelRequest.request_query_params}
      </>}
    </td>
  </tr>
}

function Header({
  loadInitialRequestData,
  includeTokenRequests,
  setIncludeTokenRequests
}: {
  loadInitialRequestData: () => void,
  includeTokenRequests: boolean,
  setIncludeTokenRequests: (value: boolean) => void
}) {
  const [viewingSettings, setViewingSettings] = useState(false)

  return <>
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>
          360 Requests
        </span>
        {false && <span className='text-muted mt-1 fw-semibold fs-7'>
          Subtitle goes here
        </span>}
      </h3>
      <div
        className='card-toolbar'
      >
        <button
          className='btn btn-sm btn-light-primary'
          onClick={() => setViewingSettings(!viewingSettings)}
        >
          Settings
        </button>
      </div>
    </div>
    {viewingSettings && <div>
      <div className='card-body'>
        <div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='includeTokenRequests'
              checked={includeTokenRequests}
              onChange={(e) => setIncludeTokenRequests(e.target.checked)}
            />
            <label
              className='form-check-label'
              htmlFor='includeTokenRequests'
            >
              Include Token Requests
            </label>
          </div>
        </div>
      </div>
    </div>}
  </>
}

export const DebugHighlevelRequests: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [dashboardResponse, setDashboardResponse] = useState<DashboardHighLevelData | null>(null)
  const [additionalRequests, setAdditionalRequests] = useState<HighLevelRequestData[]>([])

  const [loadedPage, setLoadedPage] = useState(1)
  const [paginationError, setPaginationError] = useState<string | null>(null)
  const [hasMore, setHasMore] = useState(true)

  const [includeTokenRequests, setIncludeTokenRequests] = useState(false)

  const loadInitialRequestData = useCallback(async () => {
    setError(null)
    setLoading(true)
    try {
      const result = await getDashboardHighLevelRequests({
        limit: 100,
        includeTokenRequests
      })
      setDashboardResponse(result)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load 360 requests'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [includeTokenRequests])

  const fetchMoreRequestData = useCallback(async () => {
    getHighLevelRequestsWithPage({
      page: loadedPage + 1,
      limit: 100,
      includeTokenRequests
    }).then((result) => {
      setPaginationError(null)
      if (result.length > 0) {
        setAdditionalRequests([...additionalRequests, ...result])
        setLoadedPage(loadedPage + 1)
        setHasMore(result.length === 100)
      } else {
        setHasMore(false)
      }
    }).catch((exception) => {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load 360 requests'
      setPaginationError(errorMessage)
    })
  }, [additionalRequests, includeTokenRequests, loadedPage])

  useEffect(() => {
    loadInitialRequestData()
  }, [loadInitialRequestData])

  if (loading) {
    return <>
      <span className='indicator-progress' style={{ display: 'block' }}>
        Loading...{' '}
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    </>
  }

  if (error) {
    return <div>
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={loadInitialRequestData}>
          Retry
        </button>
      </div>
    </div>
  }

  return (
    <>
      <MogulHeader title='360 Requests' />
      <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>

            <Header
              loadInitialRequestData={loadInitialRequestData}
              includeTokenRequests={includeTokenRequests}
              setIncludeTokenRequests={setIncludeTokenRequests}
            />

            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px'>Date</th>
                      <th className='min-w-150px'>Account</th>
                      <th className='min-w-150px'>Location</th>
                      <th className='min-w-150px'>URL</th>
                      <th className='min-w-150px'>Status</th>
                      <th className='min-w-150px'>Response</th>
                      <th className='min-w-150px'>Request Body</th>
                      <th className='min-w-150px'>Query Params</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {[
                      ...(dashboardResponse?.highlevel_requests || []),
                      ...additionalRequests
                    ].map((highlevelRequest) => {
                      return <HighlevelRequestRow key={`ghl-req-${highlevelRequest.unique_identifier}`} highlevelRequest={highlevelRequest} />
                    })}
                    {hasMore && <tr>
                      <td colSpan={8}>
                        <button onClick={fetchMoreRequestData} className='btn btn-primary'>
                          Load More
                        </button>
                      </td>
                    </tr>}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </>
  )
}