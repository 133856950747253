/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useState } from 'react'

import { KTIcon } from '../../_metronic/helpers'
import useAccounts from '../hooks/useAccounts'
import { createServiceLocation } from '../core/_requests'
import { APIError } from '../../app/modules/auth'

const AddServiceLocationModal: FC = () => {

  const {
    accounts,
    loadingAccounts,
    errorLoadingAccounts,
  } = useAccounts()

  const [saving, setSaving] = useState(false)
  const [errorSaving, setErrorSaving] = useState<string | null>(null)

  const onFormSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    const formData = new FormData(form)
    const data = Object.fromEntries(formData.entries())
    setErrorSaving(null)
    setSaving(true)
    try {
      const result = await createServiceLocation({
        account_unique_identifier: data.account_unique_identifier,
        name: data.name,
      })
      window.location.href = `/service-locations/${result.unique_identifier}`
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to create service location'
      setErrorSaving(errorMessage)
      setSaving(false)
    }
  }, [])

  return (
    <div className='modal fade' id='modal_create_service_location' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <form className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15' onSubmit={onFormSubmit}>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                Add a Service Location
              </h1>

              <div className='text-muted fw-bold fs-5'>
                Enter the service location details below
              </div>
            </div>

            <label className='form-label'>Account:</label>
            {loadingAccounts ? <div>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div> : <>
              {errorLoadingAccounts ? <>
                <div className='w-100'>
                  <div className='alert alert-danger'>
                    {errorLoadingAccounts}
                  </div>
                </div>
              </> : <>
                <select
                  name="account_unique_identifier"
                  className='form-select form-select-solid mb-8' required>
                  <option value=''>Select an account...</option>
                  {accounts.map((account) => <option key={`account-${account.unique_identifier}`} value={account.unique_identifier}>{account.name}</option>)}
                </select>
              </>}
            </>}

            <label className='form-label'>Location Name:</label>
            <input required name="name" type="text" className="form-control form-control-solid mb-8" placeholder="Enter name..." />

            <div className='modal-footer'>
              <div className='flex-center'>
                <button type='reset' className='btn btn-white me-3' data-bs-dismiss='modal' id='add_service_location_modal_cancel'>
                  Cancel
                </button>
                <button type='submit' className='btn btn-primary'>
                  {!saving && 'Add Location'}
                  {saving && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Adding...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {errorSaving ? <div className='w-100 mt-5'>
                <div className='alert alert-danger'>
                  {errorSaving}
                </div>
              </div> : null}
            </div>

          </form>

        </div>
      </div>
    </div>
  )
}

export default AddServiceLocationModal
