import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { Routes, Route, Navigate, useParams, Link, useLocation } from "react-router-dom"

import ServiceLocationDashboard from "./ServiceLocationDashboard"
import { APIError, useAuth } from "../../../../app/modules/auth"
import { ServiceLocation, getServiceLocationByUniqueIdentifier } from "../../../core/_requests"
import { PageTitle } from "../../../../_metronic/layout/core"
import ServiceLocationEvents from "./ServiceLocationEvents"
import ServiceLocationEventAdministrators from "./ServiceLocationEventAdministrators"
import ServiceLocationEventCheckInRecords from "./ServiceLocationEventCheckInRecords"
import ServiceLocationDirectMailReplies from "./ServiceLocationDirectMailReplies"
import ServiceLocationSettings from "./ServiceLocationSettings"
import MogulHeader from "../../../components/MogulHeader"

type ServiceLocationDetailContextProps = {
    serviceLocation: ServiceLocation
}

const ServiceLocationDetailContext = createContext<ServiceLocationDetailContextProps>({
    serviceLocation: {} as ServiceLocation,
})

export const useServiceLocationDetail = () => {
    return useContext(ServiceLocationDetailContext)
}

function ServiceLocationDetail() {

    const { service_location_unique_identifier: serviceLocationUniqueIdentifier } = useParams<{
        service_location_unique_identifier: string
    }>()

    const {
        currentUser
    } = useAuth()

    const { pathname } = useLocation();

    const [serviceLocation, setServiceLocation] = useState<ServiceLocation | null>(null);
    const [loadingServiceLocations, setLoadingServiceLocations] = useState(true);
    const [errorLoadingServiceLocations, setErrorLoadingServiceLocations] = useState<string | null>(null);

    const [viewingTab, setViewingTab] = useState<'events' | 'events_administrators' | 'events_check_in_records' | 'settings'>('events')

    const loadServiceLocation = useCallback(async () => {
        if (!serviceLocationUniqueIdentifier) {
            return
        }
        setLoadingServiceLocations(true)
        setErrorLoadingServiceLocations(null)
        try {
            const updatedServiceLocation = await getServiceLocationByUniqueIdentifier(serviceLocationUniqueIdentifier)
            setServiceLocation(updatedServiceLocation)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load service location'
            setErrorLoadingServiceLocations(errorMessage)
        } finally {
            setLoadingServiceLocations(false)
        }
    }, [serviceLocationUniqueIdentifier])

    useEffect(() => {
        loadServiceLocation()
    }, [loadServiceLocation])

    if (loadingServiceLocations) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingServiceLocations || !serviceLocation) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorLoadingServiceLocations || 'Failed to load service location'}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadServiceLocation}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <ServiceLocationDetailContext.Provider value={{
        serviceLocation,
    }}>
        <MogulHeader title={`${serviceLocation.name}`} />
        {/*}
        <PageTitle breadcrumbs={[
            {
                title: 'Home',
                path: '/dashboard',
                isSeparator: false,
                isActive: false,
            },
        ]}>
            {`Service Location: ${serviceLocation.name}`}
        </PageTitle>
        <div>
            {`Service Location Identifier: ${serviceLocation.unique_identifier}`}
        </div>
        */}
        <div>
            Account: <strong>
                <Link to={`/accounts/${serviceLocation.account.unique_identifier}`}>
                    {`${serviceLocation.account.name}`}
                </Link>
            </strong>
        </div>
        <div className="position-relative">
            {/* One day we will have a better design, but for now relative is important to get the lead download button correctly positioned */}
            <div className="header-menu align-items-stretch my-5">
                <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                    <div className="menu-item me-lg-1">
                        <Link
                            to={`/service-locations/${serviceLocationUniqueIdentifier}/dashboard`}
                            className={["menu-link py-3 btn btn-link-primary", pathname === `/service-locations/${serviceLocationUniqueIdentifier}/dashboard` ? "active" : ""].join(" ")}
                        >
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </div>
                    {/* // old ...
                <div className="menu-item me-lg-1">
                    <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'events' ? "active" : ""].join(" ")}
                        onClick={() => setViewingTab('events')}
                        type="button"
                    >
                        <span className="menu-title">Events</span>
                    </button>
                </div>
                <div className="menu-item me-lg-1">
                    <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'events_administrators' ? "active" : ""].join(" ")}
                        onClick={() => setViewingTab('events_administrators')}
                        type="button"
                    >
                        <span className="menu-title">Event Administrators</span>
                    </button>
                </div>
                <div className="menu-item me-lg-1">
                    <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'events_check_in_records' ? "active" : ""].join(" ")}
                        onClick={() => setViewingTab('events_check_in_records')}
                        type="button"
                    >
                        <span className="menu-title">Check-In Records</span>
                    </button>
                </div>
                */}
                    <div className="menu-item me-lg-1">
                        <Link
                            to={`/service-locations/${serviceLocationUniqueIdentifier}/events`}
                            className={["menu-link py-3 btn btn-link-primary", pathname === `/service-locations/${serviceLocationUniqueIdentifier}/events` ? "active" : ""].join(" ")}
                        >
                            <span className="menu-title">Events</span>
                        </Link>
                    </div>
                    <div className="menu-item me-lg-1">
                        <Link
                            to={`/service-locations/${serviceLocationUniqueIdentifier}/event-administrators`}
                            className={["menu-link py-3 btn btn-link-primary", pathname === `/service-locations/${serviceLocationUniqueIdentifier}/event-administrators` ? "active" : ""].join(" ")}
                        >
                            <span className="menu-title">Event Administrators</span>
                        </Link>
                    </div>
                    <div className="menu-item me-lg-1">
                        <Link
                            to={`/service-locations/${serviceLocationUniqueIdentifier}/event-check-in-records`}
                            className={["menu-link py-3 btn btn-link-primary", pathname === `/service-locations/${serviceLocationUniqueIdentifier}/event-check-in-records` ? "active" : ""].join(" ")}
                        >
                            <span className="menu-title">Check-In Records</span>
                        </Link>
                    </div>
                    <div className="menu-item me-lg-1">
                        <Link
                            to={`/service-locations/${serviceLocationUniqueIdentifier}/direct-mail-replies`}
                            className={["menu-link py-3 btn btn-link-primary", pathname === `/service-locations/${serviceLocationUniqueIdentifier}/direct-mail-replies` ? "active" : ""].join(" ")}
                        >
                            <span className="menu-title">Direct Mail Replies</span>
                        </Link>
                    </div>
                    {currentUser?.is_admin && <div className="menu-item me-lg-1">
                        <Link
                            to={`/service-locations/${serviceLocationUniqueIdentifier}/settings`}
                            className={["menu-link py-3 btn btn-link-primary", pathname === `/service-locations/${serviceLocationUniqueIdentifier}/settings` ? "active" : ""].join(" ")}
                        >
                            <span className="menu-title">Settings</span>
                        </Link>
                    </div>}
                </div>
            </div>
            <Routes>
                <Route path='dashboard' element={<ServiceLocationDashboard />} />
                <Route path='events' element={<ServiceLocationEvents />} />
                <Route path='event-administrators' element={<ServiceLocationEventAdministrators />} />
                <Route path='event-check-in-records' element={<ServiceLocationEventCheckInRecords />} />
                <Route path='direct-mail-replies' element={<ServiceLocationDirectMailReplies />} />
                <Route path='settings' element={<ServiceLocationSettings />} />
                <Route path='*' element={<Navigate to={`/service-locations/${serviceLocationUniqueIdentifier}/dashboard`} replace />} />
            </Routes>
        </div>
    </ServiceLocationDetailContext.Provider>

}

export default ServiceLocationDetail