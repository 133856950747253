import { Navigate, Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'
import LoginConfirm from './components/LoginConfirm'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login-with-email' element={<Login />} />
      <Route path='confirm-email-code' element={<LoginConfirm />} />
      {false && <>
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
      </>}
      <Route path="*" element={<Navigate to='/auth/login-with-email' replace />} />
    </Route>
  </Routes>
)

export { AuthPage }
