import { useMemo } from "react";
import useAccounts from "./useAccounts";

function useCurrentAccountPage() {

    const {
        accounts,
        loadingAccounts,
        errorLoadingAccounts,
    } = useAccounts()

    const matchingUserAccount = useMemo(() => {
        // for now, match against the path /accounts/acct_umiCdC9RE2U9Hwfl
        const currentUrl = new URL(window.location.href)
        const path = currentUrl.pathname
        const match = path.match(/^\/accounts\/([^/]+)(\/.*)?$/);
        if (!match) {
            return null
        }
        const accountUniqueIdentifier = match[1]
        return accounts.find(account => account.unique_identifier === accountUniqueIdentifier)
    }, [accounts, window.location.href])

    return {
        currentAccount: matchingUserAccount,
    }

}

export default useCurrentAccountPage;