import {useState, useCallback, useEffect} from 'react'

import {APIError} from '../../app/modules/auth'
import {Account, getAccounts} from '../core/_requests'

function useAccounts() {
  const [accounts, setAccounts] = useState<Account[]>([])
  const [loadingAccounts, setLoadingAccounts] = useState(true)
  const [errorLoadingAccounts, setErrorLoadingAccounts] = useState<string | null>(null)

  const loadAccounts = useCallback(async () => {
    setLoadingAccounts(true)
    try {
      const updatedAccounts = await getAccounts()
      setAccounts(updatedAccounts)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load accounts'
      setErrorLoadingAccounts(errorMessage)
    } finally {
      setLoadingAccounts(false)
    }
  }, [])

  useEffect(() => {
    loadAccounts()
  }, [loadAccounts])

  return {
    accounts,
    loadingAccounts,
    errorLoadingAccounts,
  }
}

export default useAccounts
