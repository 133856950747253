/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react'
import { DateTime } from 'luxon'
import { getPassareRequests, DashboardPassareData, PassareRequestData } from '../../../../mogul/core/_requests'
import { APIError } from '../../../../app/modules/auth'
import MogulHeader from '../../../../mogul/components/MogulHeader'

type Props = {}

// We'll treat `includeTokenRequests` as part of a filter object:
interface FilterObject {
  includeTokenRequests: boolean;
}

const saveFilterToLocalStorageKey = 'debug-passare-requests-filter';

const formatPassareRequestDate = (passareRequest: PassareRequestData) => {
  try {
    const dateTime = DateTime.fromISO(passareRequest.inserted_at)
      .setZone('America/New_York');
    const isDST = dateTime.isInDST;
    const tzAbbreviation = isDST ? 'EDT' : 'EST';
    return dateTime.toFormat(`yyyy-MM-dd HH:mm '${tzAbbreviation}' (EEEE)`);
  } catch (e) {
    return 'Invalid DateTime';
  }
}

function DebugPassareRequestsRow({ passareRequest }: { passareRequest: PassareRequestData }) {
  return (
    <tr key={`passare-req-${passareRequest.unique_identifier}`}>
      <td>
        {formatPassareRequestDate(passareRequest)}
      </td>
      <td>
        {passareRequest.trigger_type}<br />
        {passareRequest.event_id}
      </td>
      <td>
        <pre>{JSON.stringify(passareRequest.parsed_payload, null, 2)}</pre>
      </td>
      <td>
        {passareRequest.email_address}
      </td>
      <td>
        <pre>
          {passareRequest.phone_numbers ? JSON.stringify(passareRequest.phone_numbers, null, 2) : ''}
        </pre>
      </td>
      <td>
        <pre>
          {passareRequest.address ? JSON.stringify(passareRequest.address, null, 2) : ''}
        </pre>
      </td>
    </tr>
  )
}

function Header({
  currentFilterObject,
  onApplyFilter
}: {
  currentFilterObject: FilterObject | null,
  onApplyFilter: (filterObject: FilterObject) => void
}) {
  const [viewingSettings, setViewingSettings] = useState(false)
  const includeTokenRequests = currentFilterObject?.includeTokenRequests || false

  return <>
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>
          Passare Requests
        </span>
      </h3>
      <div className='card-toolbar'>
        <button
          className='btn btn-sm btn-light-primary'
          onClick={() => setViewingSettings(!viewingSettings)}
        >
          Settings
        </button>
      </div>
    </div>
    {viewingSettings && (
      <div className='card-body'>
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='includeTokenRequests'
            checked={includeTokenRequests}
            onChange={(e) => {
              const newFilter = { ...currentFilterObject, includeTokenRequests: e.target.checked };
              onApplyFilter(newFilter)
            }}
          />
          <label
            className='form-check-label'
            htmlFor='includeTokenRequests'
          >
            Include Token Requests
          </label>
        </div>
      </div>
    )}
  </>
}

export const DebugPassareRequests: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [errorLoading, setErrorLoading] = useState<string | null>(null);

  const [results, setResults] = useState<PassareRequestData[]>([]);
  const [totalResults, setTotalResults] = useState<number | null>(null);

  const [currentFilterObject, setCurrentFilterObject] = useState<FilterObject | null>(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const loadList = useCallback(
    async (forceFilter?: FilterObject, page: number = 1) => {
      setErrorLoading(null);
      if (page === 1) {
        setLoading(true);
      } else {
        setLoadingMore(true);
      }

      try {
        const filterObj = forceFilter || currentFilterObject || { includeTokenRequests: false };
        const limit = 100;
        const result: DashboardPassareData = await getPassareRequests({
          limit,
          page,
          includeTokenRequests: filterObj.includeTokenRequests
        });

        const fetchedRequests = result.passare_requests || [];

        if (page === 1) {
          setResults(fetchedRequests);
        } else {
          setResults((prev) => [...prev, ...fetchedRequests]);
        }

        // If the API doesn't return total_count, you can estimate based on returned results.
        // For demonstration, assume no total count is returned and handle pagination by hasMore:
        setTotalResults(null); // or a known total if available
        setHasMore(fetchedRequests.length === limit);
      } catch (exception) {
        console.error(exception);
        const errorMessage =
          exception instanceof APIError ? exception.message : 'Failed to load Passare requests';
        setErrorLoading(errorMessage);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    },
    [currentFilterObject]
  );

  useEffect(() => {
    // Load from local storage
    try {
      const savedFilter = localStorage.getItem(saveFilterToLocalStorageKey);
      if (savedFilter) {
        const parsedFilter = JSON.parse(savedFilter);
        setCurrentFilterObject(parsedFilter);
        loadList(parsedFilter);
      } else {
        loadList();
      }
    } catch (error) {
      console.error('Failed to load saved filter', error);
      loadList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMore = () => {
    if (hasMore && !loading && !loadingMore) {
      setCurrentPage((prevPage) => prevPage + 1);
      loadList(currentFilterObject || undefined, currentPage + 1);
    }
  };

  if (loading && currentPage === 1) {
    return <>
      <span className='indicator-progress' style={{ display: 'block' }}>
        Loading...{' '}
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    </>
  }

  if (errorLoading) {
    return <div>
      <div className="alert alert-danger" role="alert">
        {errorLoading}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={() => {
          loadList()
        }}>
          Retry
        </button>
      </div>
    </div>
  }

  return (
    <>
      <MogulHeader title='Passare Requests' />
      <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
        <div className='col-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            <Header
              currentFilterObject={currentFilterObject}
              onApplyFilter={(filterObject) => {
                setCurrentFilterObject(filterObject)
                localStorage.setItem(saveFilterToLocalStorageKey, JSON.stringify(filterObject))
                setCurrentPage(1);
                loadList(filterObject, 1)
              }}
            />

            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px'>Date</th>
                      <th className='min-w-150px'>Trigger Type</th>
                      <th className='min-w-150px'>Parsed Body</th>
                      <th className='min-w-150px'>Email</th>
                      <th className='min-w-150px'>Phone Numbers</th>
                      <th className='min-w-150px'>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((passareRequest) => (
                      <DebugPassareRequestsRow key={`passare-req-${passareRequest.unique_identifier}`} passareRequest={passareRequest} />
                    ))}
                    {hasMore && (
                      <tr>
                        <td colSpan={8}>
                          <button
                            disabled={loadingMore}
                            onClick={loadMore}
                            className='btn btn-primary'
                          >
                            {loadingMore ? 'Loading...' : 'Load More'}
                          </button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
