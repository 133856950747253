import styled from "styled-components";
import { getLogoURL } from "../../../app/modules/auth/AuthLayout";

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
`

function LoadingScreen() {
    return <Container>
        <img
            alt='Logo'
            src={getLogoURL()}
            className='h-80px'
        />
    </Container>
}

export default LoadingScreen