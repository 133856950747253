import { useState, useCallback, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    DateTime
} from "luxon"

import { useAuth, APIError } from "../../../../app/modules/auth";
import {
    getServiceLocationEventsByUniqueIdentifier,
    getServiceLocationEventAdministratorsByUniqueIdentifier,
    getServiceLocationEventCheckInRecordsByUniqueIdentifier,
    getServiceLocationDirectMailRepliesByUniqueIdentifier,
    DirectMailReply,
    syncLinkManuallyByUniqueIdentifier
} from "../../../core/_requests";
import useAccounts from "../../../hooks/useAccounts";
import { useServiceLocationDetail } from "./ServiceLocationDetail";

type DownloadFunctionType = (uniqueIdentifier: string, isForExport: boolean) => Promise<any>;

export const formatEventDatetime = (event: any) => {
    try {
        return DateTime.fromFormat(
            event.event_datetime,
            'yyyy-MM-dd\'T\'HH:mm:ss\'Z\''
        ).toFormat('yyyy-MM-dd HH:mm (EEEE)')
    } catch (e) {
        return 'Invalid DateTime'
    }
}

const GenericTableRenderer = ({ apiRequest, columns, title }: {
    apiRequest: (uniqueIdentifier: string) => Promise<any[]>,
    columns: any[],
    title: string
}) => {
    const { service_location_unique_identifier: serviceLocationUniqueId } = useParams<{ service_location_unique_identifier: string }>()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<string | null>(null)
    const [data, setData] = useState<any[]>([])

    const loadData = useCallback(async () => {
        if (!serviceLocationUniqueId) {
            return
        }
        setLoading(true)
        setError(null)
        try {
            const data = await apiRequest(serviceLocationUniqueId)
            setData(data)
        } catch (exception: any) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load data'
            setError(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [serviceLocationUniqueId, apiRequest])



    useEffect(() => {
        loadData()
    }, [loadData])

    return <div>
        <h2>
            {title}
        </h2>
        {loading ? <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div> : error ? <div className='mt-5'>
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        </div> : <div>
            <table className='table table-striped'>
                <thead>
                    <tr>
                        {columns.map((column, index) => {
                            return <th key={index}>
                                {column.title}
                            </th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        return <tr key={index}>
                            {columns.map((column, index) => {
                                return <td key={index}>
                                    {column.render(row)}
                                </td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>}
    </div>
}

function getDayWithSuffix(day: number) {
    if (day > 3 && day < 21) return day + 'th';
    switch (day % 10) {
        case 1: return day + 'st';
        case 2: return day + 'nd';
        case 3: return day + 'rd';
        default: return day + 'th';
    }
}

function ServiceLocationItemRenderer({
    viewingTab
}: {
    viewingTab: 'events' | 'events_administrators' | 'events_check_in_records' | 'direct_mail_replies'
}) {

    const {
        serviceLocation
    } = useServiceLocationDetail();

    const {
        currentUser
    } = useAuth()

    const { accounts } = useAccounts() // redundant retrieval but it'll make sure it's updated. May be in a global context one day.

    const [loadingServiceLocations, setLoadingServiceLocations] = useState(true);
    const [errorLoadingServiceLocations, setErrorLoadingServiceLocations] = useState<string | null>(null);

    const [errorGeneratingCSV, setErrorGeneratingCSV] = useState<string | null>(null)

    const [viewingAvailableLocations, setViewingAvailableLocations] = useState(false)

    const [syncing, setSyncing] = useState(false)
    const [syncError, setSyncError] = useState<string | null>(null)

    const onSyncClicked = useCallback(async (syncUniqueIdentifier: string) => {
        setSyncing(true)
        setSyncError(null)
        // todo
        try {
            await syncLinkManuallyByUniqueIdentifier(syncUniqueIdentifier)
            // for now, reload
            window.location.reload()
        } catch (exception) {
            console.error(exception)
            const errorMessage =
                exception instanceof APIError ? exception.message : 'Failed to sync'
            console.error(errorMessage)
            setSyncError(`${syncUniqueIdentifier}`)
        }
        setSyncing(false)
    }, [])

    const linkOutToFRMWithURL = useMemo(() => {
        //
        // Right now service locations don't have GHL details because it's an admin detail not added to that
        // retrieval. The quickest fix right now is looking up the admin account mapping
        // and deducing the URL from that.
        //
        if (!serviceLocation) {
            return null
        }
        const matchingAccounts = accounts.filter(x => x.unique_identifier === serviceLocation?.account.unique_identifier);
        if (!matchingAccounts.length) {
            return null
        }
        const account = matchingAccounts[0];
        if (account.external_ghl_site_url) {
            return account.external_ghl_site_url
        }
        return null
    }, [accounts, serviceLocation])

    const eventsColumns = useMemo(() => {
        return [
            {
                title: 'Event Name',
                render: (row: any) => row.event_name
            },
            {
                title: 'Person Details',
                render: (row: any) => {
                    const uploadedPerson = row.public_data?.person
                    if (!uploadedPerson) {
                        return '(none)'
                    }
                    if (uploadedPerson.full_name && uploadedPerson.date_of_birth && uploadedPerson.date_of_death) {
                        return `${row.public_data?.person.full_name}: ${uploadedPerson.date_of_birth} to ${uploadedPerson.date_of_death}`
                    }
                    if (!uploadedPerson.full_name) {
                        // this should never be allowed
                        return '(invalid...)'
                    }
                    if (uploadedPerson.date_of_birth) {
                        return `${uploadedPerson.full_name}: ${uploadedPerson.date_of_birth} to (unknown death date)`
                    }
                    if (uploadedPerson.date_of_death) {
                        return `${uploadedPerson.full_name}: (unknown birth date) to ${uploadedPerson.date_of_death}`
                    }
                    return `${uploadedPerson.full_name}`
                }
            },
            {
                title: 'Event Date',
                render: (row: any) => {
                    return formatEventDatetime(row);
                }
            },
        ]
    }, [])

    const eventAdministratorsColumns = useMemo(() => {
        return [
            {
                title: 'Event',
                render: (row: any) => row.event.event_name
            },
            {
                title: 'Event Date',
                render: (row: any) => {
                    return formatEventDatetime(row.event);
                }
            },
            {
                title: 'Person Full Name',
                render: (row: any) => row.person_full_name
            },
            {
                title: 'Person Phone Number',
                render: (row: any) => row.person_phone_number
            },
            {
                title: 'Person Email Address',
                render: (row: any) => row.public_data?.person_email_address || '(none)'
            },
            {
                title: 'Person Relationship to Deceased',
                render: (row: any) => row.public_data?.person_relationship_to_deceased || '(none)'
            },
            {
                title: 'Person Interested in Pre-Planning',
                render: (row: any) => {
                    if (typeof row.public_data?.person_interested_in_pre_planning === 'undefined') {
                        return '(none)'
                    }
                    return row.public_data?.person_interested_in_pre_planning ? 'Yes' : 'No'
                }
            },
            {
                title: 'FRM Link',
                render: (row: any, renderForCSV = false) => {
                    if (!serviceLocation || !serviceLocation.private_data?.linked_to_frm_location_id) {
                        return '(invalid...)'
                    }
                    if (row.public_data?.latest_highlevel_contact_id) {
                        // /v2/location/HQglPtKDmuFindfd45Ax/contacts/detail/u7GI189xye8yRqaW8x74
                        const link = `${linkOutToFRMWithURL}/v2/location/${serviceLocation.private_data?.linked_to_frm_location_id}/contacts/detail/${row.public_data.latest_highlevel_contact_id}`
                        if (renderForCSV) {
                            return link
                        }
                        return <>
                            {(currentUser?.is_admin || true) && <>
                                {syncError === row.unique_identifier && <div className='alert alert-danger' role='alert'>
                                    Failed to sync
                                </div>}
                                <button
                                    className='btn btn-sm btn-light-primary'
                                    onClick={() => {
                                        onSyncClicked(row.unique_identifier)
                                    }}
                                    disabled={syncing}
                                >
                                    {!row.public_data?.latest_highlevel_contact_id ? <>
                                        {!syncing ? `Sync` : `Syncing...`}
                                    </> : <>
                                        {!syncing ? `Re-Sync` : `Syncing...`}
                                    </>}
                                </button>
                            </>}
                            <a href={link} target='_blank' rel='noreferrer'>
                                View in FRM
                            </a>
                        </>
                    }
                    return '(none)'
                }
            },
        ]
    }, [serviceLocation, linkOutToFRMWithURL])

    const eventCheckInRecordsColumns = useMemo(() => {
        return [
            {
                title: 'Event',
                render: (row: any) => row.event.event_name
            },
            {
                title: 'Event Date',
                render: (row: any) => {
                    return formatEventDatetime(row.event);
                }
            },
            {
                title: 'Visitor Full Name',
                render: (row: any) => row.visitor_full_name
            },
            {
                title: 'Visitor Phone Number',
                render: (row: any) => row.visitor_phone_number
            },
            {
                title: 'Visitor Email Address',
                render: (row: any) => row.public_data?.visitor_email_address || '(none)'
            },
            {
                title: 'Visitor Relationship to Deceased',
                render: (row: any) => row.public_data?.visitor_relationship_to_deceased || '(none)'
            },
            {
                title: 'Visitor Interested in Pre-Planning',
                render: (row: any) => {
                    if (typeof row.public_data?.visitor_interested_in_pre_planning === 'undefined') {
                        return '(none)'
                    }
                    return row.public_data?.visitor_interested_in_pre_planning ? 'Yes' : 'No'
                }
            },
            {
                title: 'FRM Link',
                render: (row: any, renderForCSV = false) => {
                    if (!serviceLocation || !serviceLocation.private_data?.linked_to_frm_location_id) {
                        return '(invalid...)'
                    }
                    if (row.public_data?.latest_highlevel_contact_id) {
                        // /v2/location/HQglPtKDmuFindfd45Ax/contacts/detail/u7GI189xye8yRqaW8x74
                        const link = `${linkOutToFRMWithURL}/v2/location/${serviceLocation.private_data?.linked_to_frm_location_id}/contacts/detail/${row.public_data.latest_highlevel_contact_id}`;
                        if (renderForCSV) {
                            return link
                        }
                        return <>
                            {(currentUser?.is_admin || true) && <>
                                {syncError === row.unique_identifier && <div className='alert alert-danger' role='alert'>
                                    Failed to sync
                                </div>}
                                <button
                                    className='btn btn-sm btn-light-primary'
                                    onClick={() => {
                                        onSyncClicked(row.unique_identifier)
                                    }}
                                    disabled={syncing}
                                >
                                    {!row.public_data?.latest_highlevel_contact_id ? <>
                                        {!syncing ? `Sync` : `Syncing...`}
                                    </> : <>
                                        {!syncing ? `Re-Sync` : `Syncing...`}
                                    </>}
                                </button>
                            </>}
                            <a href={link} target='_blank' rel='noreferrer'>
                                View in FRM
                            </a>
                        </>
                    }
                    return '(none)'
                }
            },
        ]
    }, [serviceLocation, linkOutToFRMWithURL])

    const directMailRepliesColumns = useMemo(() => {
        return [
            {
                title: 'When?',
                render: (row: DirectMailReply) => {
                    const dateTime = DateTime.fromFormat(
                        row.inserted_at,
                        'yyyy-MM-dd\'T\'HH:mm:ss\'Z\''
                    );
                    const formattedDate = `${dateTime.toFormat('MMM')} ${getDayWithSuffix(dateTime.day)}, ${dateTime.toFormat('yyyy')} (${dateTime.toFormat('cccc')}) at ${dateTime.toFormat('hh:mm a')}`;
                    return formattedDate;
                }
            },
            {
                title: 'Respondent',
                render: (row: DirectMailReply) => {
                    return row.public_data.respondent_person?.full_name
                }
            },
            {
                title: 'Birth Date',
                render: (row: DirectMailReply) => {
                    return row.public_data.respondent_person?.birth_date
                }
            },
            {
                title: 'Email',
                render: (row: DirectMailReply) => {
                    return row.public_data.respondent_person?.email_address
                }
            },
            {
                title: 'Phone 1',
                render: (row: DirectMailReply) => {
                    return row.public_data.respondent_person?.phone_number1
                }
            },
            {
                title: 'Phone 2',
                render: (row: DirectMailReply) => {
                    return row.public_data.respondent_person?.phone_number2
                }
            },
            {
                title: 'Metadata',
                render: (row: DirectMailReply) => {
                    return [
                        row.public_data.additional_metadata?.mailer_code,
                        row.public_data.additional_metadata?.order_number
                    ].filter(x => x).join(', ')
                }
            },
            {
                title: 'Address',
                render: (row: DirectMailReply) => {
                    return `${row.public_data.respondent_address?.street_address_line_1 || ''} ${row.public_data.respondent_address?.street_address_line_2 || ''}, ${row.public_data.respondent_address?.city || ''}, ${row.public_data.respondent_address?.state || ''} ${row.public_data.respondent_address?.postal_code || ''}`
                }
            },
            {
                title: 'Image',
                render: (row: DirectMailReply) => {
                    if (row.public_data?.image_urls && row.public_data.image_urls.length) {
                        return <>
                            <a href={row.public_data.image_urls[0]} target='_blank' rel='noreferrer'>
                                View Original Image
                            </a>
                        </>
                    }
                    return '-'
                }
            },
            {
                title: 'FRM Link',
                render: (row: DirectMailReply, renderForCSV = false) => {
                    if (!serviceLocation || !serviceLocation.private_data?.linked_to_frm_location_id) {
                        return '(not linked...)'
                    }
                    // /v2/location/HQglPtKDmuFindfd45Ax/contacts/detail/u7GI189xye8yRqaW8x74
                    const link = `${linkOutToFRMWithURL}/v2/location/${serviceLocation.private_data?.linked_to_frm_location_id}/contacts/detail/${row.public_data.latest_highlevel_contact_id}`
                    if (renderForCSV) {
                        return row.public_data?.latest_highlevel_contact_id ? link : ''
                    }
                    return <div>
                        {(currentUser?.is_admin || true) && <>
                            {syncError === row.unique_identifier && <div className='alert alert-danger' role='alert'>
                                Failed to sync
                            </div>}
                            <button
                                className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    onSyncClicked(row.unique_identifier)
                                }}
                                disabled={syncing}
                            >
                                {!row.public_data?.latest_highlevel_contact_id ? <>
                                    {!syncing ? `Sync` : `Syncing...`}
                                </> : <>
                                    {!syncing ? `Re-Sync` : `Syncing...`}
                                </>}
                            </button>
                        </>}
                        {row.public_data?.latest_highlevel_contact_id ? <div>
                            <a href={link} target='_blank' rel='noreferrer'>
                                View in FRM
                            </a>
                        </div> : ''}
                    </div>
                }
            },
        ]
    }, [serviceLocation, linkOutToFRMWithURL, currentUser?.is_admin, syncError, syncing, onSyncClicked])

    const handleCSVDownload = useCallback(async () => {
        if (!serviceLocation.unique_identifier) {
            return;
        }
        setErrorGeneratingCSV(null);
        let functionToDownloadLatestData: DownloadFunctionType | null = null;
        let columns: any[] = [];

        switch (viewingTab) {
            case 'events':
                functionToDownloadLatestData = getServiceLocationEventsByUniqueIdentifier;
                columns = [
                    { title: 'System Unique Identifier', render: (row: any) => row.unique_identifier },
                    { title: 'External Identifier', render: (row: any) => row.external_identifier },
                    { title: 'Inserted At', render: (row: any) => row.inserted_at },
                    ...eventsColumns
                ];
                break;
            case 'events_administrators':
                functionToDownloadLatestData = getServiceLocationEventAdministratorsByUniqueIdentifier;
                columns = [
                    { title: 'Event System Unique Identifier', render: (row: any) => row.event.unique_identifier },
                    { title: 'Event External Identifier', render: (row: any) => row.event.external_identifier },
                    { title: 'System Unique Identifier', render: (row: any) => row.unique_identifier },
                    { title: 'External Identifier', render: (row: any) => row.external_identifier },
                    { title: 'Inserted At', render: (row: any) => row.inserted_at },
                    ...eventAdministratorsColumns
                ];
                break;
            case 'events_check_in_records':
                functionToDownloadLatestData = getServiceLocationEventCheckInRecordsByUniqueIdentifier;
                columns = [
                    { title: 'Event System Unique Identifier', render: (row: any) => row.event.unique_identifier },
                    { title: 'Event External Identifier', render: (row: any) => row.event.external_identifier },
                    { title: 'System Unique Identifier', render: (row: any) => row.unique_identifier },
                    { title: 'External Identifier', render: (row: any) => row.external_identifier },
                    { title: 'Inserted At', render: (row: any) => row.inserted_at },
                    ...eventCheckInRecordsColumns
                ];
                break;
            case 'direct_mail_replies':
                functionToDownloadLatestData = getServiceLocationDirectMailRepliesByUniqueIdentifier;
                columns = [
                    { title: 'System Unique Identifier', render: (row: any) => row.unique_identifier },
                    { title: 'External Identifier', render: (row: any) => row.external_identifier },
                    { title: 'Inserted At', render: (row: any) => row.inserted_at },
                    ...directMailRepliesColumns
                ];
                break;
            default:
                return;
        }

        if (!functionToDownloadLatestData) {
            return;
        }

        try {
            const data = await functionToDownloadLatestData(serviceLocation.unique_identifier, true); // true indicates "is_for_export" is "yes"
            let headerRow = columns.map(column => column.title).join(',') + '\n';
            const csvData = data.map(row => {
                return columns.map(column => column.render(row, true)).join(',');
            }).join('\n');

            const blob = new Blob([headerRow + csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'data.csv';
            a.click();
            URL.revokeObjectURL(url);
        } catch (exception) {
            console.error(exception);
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to download data';
            setErrorGeneratingCSV(errorMessage);
        }
    }, [eventAdministratorsColumns, eventCheckInRecordsColumns, directMailRepliesColumns, eventsColumns, serviceLocation.unique_identifier, viewingTab]);

    return <div>
        <div className="position-absolute top-0 end-0">
            <div className="menu-item text-end ms-auto">
                <button className=" btn btn-secondary"
                    onClick={handleCSVDownload}
                    type="button"
                >
                    Download as CSV
                </button>
            </div>
        </div>
        {viewingTab === 'events' && <>
            <GenericTableRenderer
                apiRequest={getServiceLocationEventsByUniqueIdentifier}
                columns={eventsColumns}
                title='Events'
            />
        </>}
        {viewingTab === 'events_administrators' && <>
            <GenericTableRenderer
                apiRequest={getServiceLocationEventAdministratorsByUniqueIdentifier}
                columns={eventAdministratorsColumns}
                title='Administrators'
            />
        </>}
        {viewingTab === 'events_check_in_records' && <>
            <GenericTableRenderer
                apiRequest={getServiceLocationEventCheckInRecordsByUniqueIdentifier}
                columns={eventCheckInRecordsColumns}
                title='Check-In Records'
            />
        </>}
        {viewingTab === 'direct_mail_replies' && <>
            <GenericTableRenderer
                apiRequest={getServiceLocationDirectMailRepliesByUniqueIdentifier}
                columns={directMailRepliesColumns}
                title='Direct Mail Replies'
            />
        </>}
    </div>
}

export default ServiceLocationItemRenderer;