/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useCallback, useEffect } from 'react'

import { KTIcon } from '../../_metronic/helpers'
import { Dropdown1 } from '../../_metronic/partials'
import { DateTime } from 'luxon'
import { APIError } from '../../app/modules/auth'
import { DashboardDirectMailRepliesListData, getDashboardListDataForDirectMailReplies } from '../core/_requests'
import { formatDateForDatePickers } from './DashboardGraph'
import { Link } from 'react-router-dom'
import useAccounts from '../hooks/useAccounts'

export function DashboardListForDirectMailReplies() {

    const { accounts } = useAccounts() // redundant retrieval but it'll make sure it's updated. May be in a global context one day.

    const [isLoading, setIsLoading] = React.useState(false)
    const [loadingError, setLoadingError] = React.useState<string | null>(null)
    const [dashboardResponse, setDashboardResponse] = React.useState<DashboardDirectMailRepliesListData | null>(
        null
    )

    const [startingUnixTimestamp, setStartingUnixTimestamp] = React.useState(
        Math.floor(DateTime.local().setZone('America/Chicago').minus({ days: 7 }).startOf('day').toSeconds())
    )

    const [endingUnixTimestamp, setEndingUnixTimestamp] = React.useState(
        Math.floor(DateTime.local().setZone('America/Chicago').toSeconds())
    )

    const loadDashboardData = useCallback(async () => {
        setIsLoading(true)
        try {
            let loadedDashboardData: DashboardDirectMailRepliesListData | null = null
            loadedDashboardData = await getDashboardListDataForDirectMailReplies(
                startingUnixTimestamp,
                endingUnixTimestamp,
            )
            if (!loadedDashboardData) {
                throw new APIError('No dashboard data returned', {})
            }
            setLoadingError(null)
            setDashboardResponse(loadedDashboardData)
        } catch (error: any) {
            console.warn(error)
            const errorMessage = error instanceof APIError ? error.message : 'Something went wrong'
            setLoadingError(errorMessage)
        } finally {
            setIsLoading(false)
        }
    }, [endingUnixTimestamp, startingUnixTimestamp])

    useEffect(() => {
        loadDashboardData()
    }, [loadDashboardData])

    const externalGHLSiteURLForAccountUniqueIdentifier = useCallback((accountUniqueIdentifier: string) => {
        if (!accountUniqueIdentifier) {
            return null
        }
        const matchingAccounts = accounts.filter(x => x.unique_identifier === accountUniqueIdentifier)
        if (!matchingAccounts.length) {
            return null
        }
        const account = matchingAccounts[0];
        if (account.external_ghl_site_url) {
            return account.external_ghl_site_url
        }
        return null
    }, [accounts])

    return (
        <div className='row gy-5 g-xl-8'>
            <div className='col-xxl-12'>
                <div className={clsx('card', 'card-xxl-stretch-100 mb-5 mb-xl-8')}>
                    <div className='card-header align-items-center border-0 mt-3'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='fw-bolder text-dark fs-3'>Direct Mail Replies by Date (Last Week Only)</span>
                            {false && <div className='d-flex flex-stack flex-wrap flex-grow-1 pt-3 pb-3'>
                                <div>
                                    <input
                                        type='date'
                                        value={formatDateForDatePickers(startingUnixTimestamp)}
                                        onChange={(e) => {
                                            const userSelectedDate = DateTime.fromISO(e.target.value, { zone: 'America/Chicago' }).startOf('day');
                                            const seconds = Math.floor(userSelectedDate.toSeconds());
                                            setStartingUnixTimestamp(seconds);
                                        }}
                                    />
                                </div>
                                <div className='px-2 text-gray-600 fw-bold fs-6'>
                                    to
                                </div>
                                <div>
                                    <input
                                        type='date'
                                        value={formatDateForDatePickers(endingUnixTimestamp)}
                                        onChange={(e) => {
                                            const userSelectedDate = DateTime.fromISO(e.target.value, { zone: 'America/Chicago' }).endOf('day');
                                            const seconds = Math.floor(userSelectedDate.toSeconds());
                                            setEndingUnixTimestamp(seconds);
                                        }}
                                    />
                                </div>
                            </div>}
                        </h3>
                        {false && 'enableForFilter' && <div className='card-toolbar'>
                            <button
                                type='button'
                                className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                            >
                                <KTIcon iconName='category' className='fs-2' />
                            </button>
                            <Dropdown1 />
                        </div>}
                    </div>
                    {loadingError ? <>
                        <div className='card-body pt-5'>
                            <div className='d-flex flex-column align-items-center'>
                                <span className='fw-bold fs-3 text-danger'>Error loading data</span>
                                <span className='fs-6 text-gray-400'>{loadingError}</span>
                            </div>
                        </div>
                    </> : <>
                        <div className='card-body pt-5'>
                            {isLoading ? (
                                <div>
                                    <div className='spinner-border spinner-border-sm align-middle ms-2'></div>
                                </div>
                            ) : (
                                <div>
                                    {dashboardResponse?.direct_mail_replies.slice().reverse().map((dateAndReply, index) => {
                                        return <div key={`reply-${dateAndReply.full_date}`} className={clsx('d-flex mb-7', index !== 0 && 'border-top border-gray-200 pt-5')}>
                                            <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                                                <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                                                    <div className='fs-5 text-gray-800 fw-bolder'>
                                                        {`${dateAndReply.full_date} (${DateTime.fromFormat(dateAndReply.full_date, 'yyyy-MM-dd').toFormat('cccc')})`}
                                                    </div>
                                                    {dateAndReply.replies.length > 0 ? <>
                                                        {dateAndReply.replies.map((replyObject, replyIndex) => {
                                                            const correspondingGHLURL = externalGHLSiteURLForAccountUniqueIdentifier(replyObject.reply.account_unique_identifier)
                                                            const linkedToFRMLocationID = replyObject.reply.location.linked_to_frm_location_id || null
                                                            const latestHighlevelContactID = replyObject.reply.public_data?.latest_highlevel_contact_id
                                                            const fullBaseURL = !correspondingGHLURL || !linkedToFRMLocationID ? null : `${correspondingGHLURL}/v2/location/${linkedToFRMLocationID}/contacts/detail`
                                                            return <div className={clsx('mt-3 ps-4', replyIndex !== 0 && 'border-top border-gray-200 pt-5')} key={`reply-${replyObject.reply.reply_unique_identifier}`}>
                                                                <Link
                                                                    to={`/service-locations/${replyObject.reply.location.unique_identifier}`}
                                                                    className='fs-5 text-gray-800 fw-bolder text-hover-primary'
                                                                >
                                                                    {`Location: ${replyObject.reply.location.name}`}
                                                                </Link>
                                                                <div className='fs-5 text-gray-800 fw-bolder'>
                                                                    {`Reply Date: ${DateTime.fromISO(replyObject.reply.reply_inserted_at).toFormat('FF')}`}
                                                                </div>
                                                                <div>
                                                                    <div className='fs-6 text-gray-500 mt-2'>
                                                                        Reply Details:
                                                                    </div>
                                                                    <div className='mt-3 fs-6 text-gray-400'>
                                                                        Order Number: {`${replyObject.reply.public_data?.additional_metadata?.order_number}`}
                                                                    </div>
                                                                    <div className='mt-1 fs-6 text-gray-400'>
                                                                        Mailer Code: {`${replyObject.reply.public_data?.additional_metadata?.mailer_code}`}
                                                                    </div>
                                                                    <div className='mt-1 fs-6 text-gray-400'>
                                                                        {replyObject.reply.public_data?.image_urls && replyObject.reply.public_data?.image_urls?.length > 0 ? <>
                                                                            <div className='mt-1 fs-6 text-gray-400'>
                                                                                Image URLs:
                                                                            </div>
                                                                            <div className='mt-1 fs-6 text-gray-400'>
                                                                                {replyObject.reply.public_data?.image_urls.map((url, urlIndex) => {
                                                                                    return <div key={`url-${urlIndex}`}>
                                                                                        <a href={url} target='_blank' rel='noreferrer'>
                                                                                            {url}
                                                                                        </a>
                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                        </> : <div>
                                                                            No image URLs
                                                                        </div>}
                                                                    </div>
                                                                    {latestHighlevelContactID && fullBaseURL && (
                                                                        <div>
                                                                            <a href={`${fullBaseURL}/${latestHighlevelContactID}`} target='_blank' rel='noreferrer'>
                                                                                Direct 360 Link
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        })}
                                                    </> : <div className='mt-3 fs-6 text-gray-400'>
                                                        No direct mail replies
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            )}
                        </div>
                    </>}
                </div>
            </div>
        </div>
    )
}
