import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, Route, Routes, useParams } from "react-router-dom"
import { CopyToClipboard } from 'react-copy-to-clipboard'

import {
    getPartnerByUniqueIdentifier,
    getPartnerApiKeys,
    createPartnerApiKey,
    getPartnerPermissions,
    createPartnerPermission,
    deletePermissionByUniqueId,
    Partner,
    PartnerAPIKey,
    PartnerAccountMembership,
    deleteApiKeyByUniqueId,
    BASE_API_URL,
    getAllPipedriveAccounts,
} from "../core/_requests"
import { APIError } from "../../app/modules/auth"
import useAccounts from "../hooks/useAccounts"
import MogulHeader from "../components/MogulHeader"
import { KTIcon } from "../../_metronic/helpers"

interface PipedriveDealItem {
    add_time: string
    channel: string | null
    channel_id: string | null
    close_time: string
    creator_user_id: string
    currency: string
    custom_fields: string | null
    expected_close_date: string | null
    id: string
    is_deleted: boolean
    label_ids: string[]
    local_close_date: string
    local_lost_date: string
    local_won_date: string
    lost_reason: string | null
    lost_time: string
    org_id: string | null
    origin: string
    origin_id: string | null
    owner_id: string
    person_id: string
    pipeline_id: string
    probability: string | null
    stage_change_time: string | null
    stage_id: string
    status: string
    title: string
    update_time: string
    value: number
    visible_to: number
    won_time: string | null

}

interface PipedriveDealResponse {
    data: PipedriveDealItem[]
}

export interface PipedriveAccount {
    inserted_at: string
    updated_at: string
    unique_identifier: string
    api_domain: string
    public_data: {
        latest_deal_fields_response: any
    }
}

export interface LimitedFieldsWithValuesJson {
    fields_with_values: {
        value: any // This is typically a string or null, but not sure if that's always true yet.
        field: {
            key: string
            name: string
        }
    }[]
}

export interface PipedriveDeal {
    latest_deal_json: {
        status: string
        title: string
        person_name: string
        org_id: number
        owner_name: string
        org_name: string
    }

    limited_hydrated_deal_json?: LimitedFieldsWithValuesJson

    inserted_at: string
    updated_at: string
    unique_identifier: string

    pipedrive_account: PipedriveAccount
}

export interface PipedrivePerson {
    latest_person_json: {
        name: string
        phone: {
            value: string
            primary: boolean
            label: string
        }[]
        email: {
            value: string
            primary: boolean
            label: string
        }[]
    }

    limited_hydrated_person_json?: LimitedFieldsWithValuesJson

    inserted_at: string
    updated_at: string
    unique_identifier: string
}

function PartnerPipedrive({ partnerUniqueId }: { partnerUniqueId: string }) {

    const [loadingAccounts, setLoadingAccounts] = useState(true)

    const [pipedriveAccounts, setPipedriveAccounts] = useState<PipedriveAccount[]>([])

    const url = useMemo(() => {
        return `${BASE_API_URL}/oauth/pipedrive?partner_unique_identifier=${partnerUniqueId}`
    }, [partnerUniqueId])

    const loadPartnerPipedrive = useCallback(async () => {
        setLoadingAccounts(true)
        try {
            const updatedPipedriveAccounts = await getAllPipedriveAccounts(
                partnerUniqueId
            )
            setPipedriveAccounts(updatedPipedriveAccounts)
        } catch (exception) {
            console.error(exception)
        } finally {
            setLoadingAccounts(false)
        }
    }, [partnerUniqueId])

    useEffect(() => {
        loadPartnerPipedrive()
    }, [loadPartnerPipedrive])

    return <div>
        <div>
            <a
                href={url}
                className='btn btn-primary btn-lg'
                target="_blank"
                rel="noreferrer"
            >
                Link Pipedrive Account
            </a>
        </div>
        <div>
            <div className='my-5'>
                <button
                    className='btn btn-secondary btn-lg'
                    data-bs-toggle='modal'
                    data-bs-target='#modal_invite_partner_to_link_pipedrive'
                >
                    <KTIcon iconName='plus' className='fs-2 text-primary me-0 me-md-2' />
                    <span className='d-none d-md-inline'>
                        Invite Partner to Link Pipedrive
                    </span>
                </button>
            </div>
        </div>
        <div className='mt-5'>
            {loadingAccounts ? <>
                <div>
                    Loading Pipedrive Deals...
                </div>
            </> : <div>
                {pipedriveAccounts.length === 0 ? <>
                    <div>
                        No Pipedrive Accounts linked
                    </div>
                </> : <div>
                    <h1>
                        All Linked Accounts
                    </h1>
                    <div>
                        {pipedriveAccounts.map((account, index) => <div key={index} className='border p-3 mb-3'>
                            <h1>
                                <Link to={`/debugging/all-pipedrive/accounts/${account.unique_identifier}`}>
                                    {account.api_domain}
                                </Link>
                            </h1>
                        </div>)}
                    </div>
                </div>}
            </div>}
        </div>
    </div>
}

function PartnerPermissions({ partnerUniqueId }: { partnerUniqueId: string }) {

    const [partnerPermissions, setPartnerPermissions] = useState<PartnerAccountMembership[]>([]);
    const [loadingPartnerPermissions, setLoadingPartnerPermissions] = useState(true);
    const [errorLoadingPartnerPermissions, setErrorLoadingPartnerPermissions] = useState<string | null>(null);

    const [creatingPartnerPermission, setCreatingPartnerPermission] = useState(false);
    const [errorCreatingPartnerPermission, setErrorCreatingPartnerPermission] = useState<string | null>(null);

    const [selectedAccountForNewPermission, setSelectedAccountForNewPermission] = useState<string | null>(null);
    const [successfullyCreatedPermissionMessage, setSuccessfullyCreatedPermissionMessage] = useState<string | null>(null);

    const [confirmingDeletePermissionAccountUniqueId, setConfirmingDeletePermissionAccountUniqueId] = useState<string | null>(null);
    const [errorDeletingPermission, setErrorDeletingPermission] = useState<string | null>(null);

    const {
        accounts,
        loadingAccounts,
    } = useAccounts()

    const loadPartnerPermissions = useCallback(async () => {
        setLoadingPartnerPermissions(true)
        setErrorLoadingPartnerPermissions(null)
        try {
            const updatedPartnerPermissions = await getPartnerPermissions(partnerUniqueId)
            setPartnerPermissions(updatedPartnerPermissions)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load partner permissions'
            setErrorLoadingPartnerPermissions(errorMessage)
        } finally {
            setLoadingPartnerPermissions(false)
        }
    }, [partnerUniqueId])

    const onCreatePartnerPermission = useCallback(async () => {
        setCreatingPartnerPermission(true)
        setErrorCreatingPartnerPermission(null)
        setSuccessfullyCreatedPermissionMessage(null)
        try {
            const {
                created: createdMembership,
                accountMembership
            } = await createPartnerPermission(partnerUniqueId, {
                account_unique_identifier: selectedAccountForNewPermission
            })
            if (createdMembership) {
                setSuccessfullyCreatedPermissionMessage(`Permission for ${accountMembership.account.name} successfully created`)
                setPartnerPermissions([...partnerPermissions, accountMembership])
            } else {
                setSuccessfullyCreatedPermissionMessage(`Permission for ${accountMembership.account.name} already exists`)
            }
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to create partner permission'
            setErrorCreatingPartnerPermission(errorMessage)
        } finally {
            setCreatingPartnerPermission(false)
        }
    }, [partnerPermissions, partnerUniqueId, selectedAccountForNewPermission])

    useEffect(() => {
        loadPartnerPermissions()
    }, [loadPartnerPermissions])

    if (loadingPartnerPermissions) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingPartnerPermissions) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorLoadingPartnerPermissions}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadPartnerPermissions}>
                    Retry
                </button>
            </div>
        </div>
    }

    return (
        <div>
            <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    {partnerPermissions.length > 0 ? <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-150px'>
                                        Account
                                    </th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {partnerPermissions.map((permission, index) => <tr key={index}>
                                    <td>
                                        {permission.account.name}
                                    </td>
                                    <td style={{ width: 400 }} className='text-end'>
                                        {confirmingDeletePermissionAccountUniqueId !== permission.account.unique_identifier ? <button
                                            className='btn btn-secondary btn-sm'
                                            onClick={() => {
                                                setConfirmingDeletePermissionAccountUniqueId(permission.account.unique_identifier)
                                            }}
                                        >
                                            Delete
                                        </button> : <div className="flex-column align-items-center justify-content-center d-flex gap-3">
                                            {errorDeletingPermission && <div className="alert alert-danger" role="alert">
                                                {errorDeletingPermission}
                                            </div>}
                                            <div>
                                                Are you sure you want to delete this permission?
                                            </div>
                                            <div>
                                                <button
                                                    className='btn btn-danger btn-sm'
                                                    onClick={async () => {
                                                        setErrorDeletingPermission(null)
                                                        try {
                                                            await deletePermissionByUniqueId(permission.unique_identifier)
                                                            setPartnerPermissions(partnerPermissions.filter(p => p.account.unique_identifier !== permission.account.unique_identifier))
                                                            setConfirmingDeletePermissionAccountUniqueId(null)
                                                        } catch (exception) {
                                                            console.error(exception)
                                                            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to delete permission'
                                                            setErrorDeletingPermission(errorMessage)
                                                        }
                                                    }}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className='btn btn-secondary btn-sm'
                                                    onClick={() => {
                                                        setConfirmingDeletePermissionAccountUniqueId(null)
                                                    }}
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div> : <div className="pt-4">
                        <div className="alert alert-info" role="alert">
                            {`No permissions have been created for this partner yet.`}
                        </div>
                    </div>}
                </div>
            </div>
            {!loadingAccounts && <div>
                <h1 className='mb-3'>
                    Create a new permission
                </h1>
                <form onSubmit={(event) => {
                    event.preventDefault()
                    onCreatePartnerPermission()
                }}>
                    {errorCreatingPartnerPermission && <div className="alert alert-danger" role="alert">
                        {errorCreatingPartnerPermission}
                    </div>}
                    {successfullyCreatedPermissionMessage && <div className="alert alert-success" role="alert">
                        {successfullyCreatedPermissionMessage}
                    </div>}
                    <div className="mb-3">
                        <select
                            className="form-select"
                            aria-label="Select an account"
                            onChange={(event) => setSelectedAccountForNewPermission(event.target.value)}
                            value={selectedAccountForNewPermission || ''}
                            required
                        >
                            <option value="">Select an account</option>
                            {accounts.map((account, index) => <option
                                key={index}
                                value={account.unique_identifier}
                            >
                                {account.name}
                            </option>)}
                        </select>
                    </div>
                    <button
                        type="submit"
                        className='btn btn-primary btn-lg'
                        disabled={creatingPartnerPermission}
                    >
                        {!creatingPartnerPermission ? `Create Permission` : `Creating...`}
                    </button>
                </form>
            </div>}
        </div>
    )
}

function PartnerAPIKeys({ partnerUniqueId }: { partnerUniqueId: string }) {

    const [partnerApiKeys, setPartnerApiKeys] = useState<PartnerAPIKey[]>([]);
    const [loadingPartnerApiKeys, setLoadingPartnerApiKeys] = useState(true);
    const [errorLoadingPartnerApiKeys, setErrorLoadingPartnerApiKeys] = useState<string | null>(null);

    const [creatingPartnerApiKey, setCreatingPartnerApiKey] = useState(false);
    const [errorCreatingPartnerApiKey, setErrorCreatingPartnerApiKey] = useState<string | null>(null);

    const [confirmingDeleteApiKey, setConfirmingDeleteApiKey] = useState<string | null>(null);
    const [errorDeletingApiKey, setErrorDeletingApiKey] = useState<string | null>(null);

    const [copied, setCopied] = useState(false)

    // display this once:

    const [mostRecentlyCreatedApiKey, setMostRecentlyCreatedApiKey] = useState<PartnerAPIKey | null>(null);

    const apiDocumentationURL = useMemo(() => {
        if (process.env.NODE_ENV === 'development') {
            return 'http://localhost:3000';
        }
        return "https://docs.mogulbridge.com"
    }, [])

    const loadPartnerApiKeys = useCallback(async () => {
        setLoadingPartnerApiKeys(true)
        setErrorLoadingPartnerApiKeys(null)
        try {
            const updatedPartnerApiKeys = await getPartnerApiKeys(partnerUniqueId)
            setPartnerApiKeys(updatedPartnerApiKeys)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load partner API keys'
            setErrorLoadingPartnerApiKeys(errorMessage)
        } finally {
            setLoadingPartnerApiKeys(false)
        }
    }, [partnerUniqueId])

    const onCreatePartnerApiKey = useCallback(async () => {
        setCreatingPartnerApiKey(true)
        setErrorCreatingPartnerApiKey(null)
        try {
            const createdKey = await createPartnerApiKey(partnerUniqueId)
            setMostRecentlyCreatedApiKey(createdKey)
            setPartnerApiKeys([...partnerApiKeys, createdKey])
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to create partner API key'
            setErrorCreatingPartnerApiKey(errorMessage)
        } finally {
            setCreatingPartnerApiKey(false)
        }
    }, [partnerApiKeys, partnerUniqueId])

    useEffect(() => {
        loadPartnerApiKeys()
    }, [loadPartnerApiKeys])

    useEffect(() => {
        if (!copied) {
            return
        }

        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }, [copied])

    if (loadingPartnerApiKeys) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingPartnerApiKeys) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorLoadingPartnerApiKeys}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadPartnerApiKeys}>
                    Retry
                </button>
            </div>
        </div>
    }

    return (
        <div>
            <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    {partnerApiKeys.length > 0 ? <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='min-w-150px'>Secret Key</th>
                                    <th className='min-w-150px'>Created</th>
                                    <th className='min-w-150px'>Last Used</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {partnerApiKeys.map((apiKey, index) => <tr key={index}>
                                    <td>
                                        {`${apiKey.key_prefix}_...${apiKey.key_last_4}`}
                                    </td>
                                    <td>
                                        {apiKey.inserted_at}
                                    </td>
                                    <td>
                                        {apiKey.last_used_at || 'Never'}
                                    </td>
                                    <td style={{ width: 400 }} className='text-end'>
                                        {confirmingDeleteApiKey !== apiKey.unique_identifier ? <button
                                            className='btn btn-secondary btn-sm'
                                            onClick={() => {
                                                setConfirmingDeleteApiKey(apiKey.unique_identifier)
                                            }}
                                        >
                                            Delete
                                        </button> : <div className="flex-column align-items-center justify-content-center d-flex gap-3">
                                            {errorDeletingApiKey && <div className="alert alert-danger" role="alert">
                                                {errorDeletingApiKey}
                                            </div>}
                                            <div>
                                                Are you sure you want to delete this API key?
                                            </div>
                                            <div>
                                                <button
                                                    className='btn btn-danger btn-sm'
                                                    onClick={async () => {
                                                        setErrorDeletingApiKey(null)
                                                        try {
                                                            await deleteApiKeyByUniqueId(apiKey.unique_identifier)
                                                            setPartnerApiKeys(partnerApiKeys.filter(p => p.unique_identifier !== apiKey.unique_identifier))
                                                            setConfirmingDeleteApiKey(null)
                                                        } catch (exception) {
                                                            console.error(exception)
                                                            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to delete API key'
                                                            setErrorDeletingApiKey(errorMessage)
                                                        }
                                                    }}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    className='btn btn-secondary btn-sm'
                                                    onClick={() => {
                                                        setConfirmingDeleteApiKey(null)
                                                    }}
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div> : <div className="pt-4">
                        <div className="alert alert-info" role="alert">
                            {`No API keys have been created for this partner yet.`}
                        </div>
                    </div>}
                </div>
            </div>
            <div>
                {errorCreatingPartnerApiKey && <div className="alert alert-danger" role="alert">
                    {errorCreatingPartnerApiKey}
                </div>}
                {mostRecentlyCreatedApiKey && <div>
                    <div className="alert alert-success" role="alert">
                        {`API key successfully created`}
                    </div>
                    <div>
                        <h1>
                            Save your key
                        </h1>
                    </div>
                    <div>
                        Please save this secret key somewhere safe and accessible. For security reasons, <strong>you won't be able to view it again</strong>. If you lose this secret key, you'll need to generate a new one.
                    </div>
                    <div className='my-3' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <pre className='bg-light p-3' style={{ fontSize: '1.1rem', margin: 0 }}>
                            {mostRecentlyCreatedApiKey.secret_key}
                        </pre>
                        <div className='p-3'>
                            <CopyToClipboard
                                text={mostRecentlyCreatedApiKey.secret_key || ''}
                                onCopy={() => setCopied(true)}
                            >
                                <a className='highlight-copy btn'>{copied ? 'Copied' : 'Copy Key'}</a>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <a href={`${apiDocumentationURL}`} target="_blank" rel="noreferrer">
                            View API documentation
                        </a>
                    </div>
                </div>}
                <button
                    className='btn btn-primary btn-lg'
                    onClick={onCreatePartnerApiKey}
                    disabled={creatingPartnerApiKey}
                >
                    {!creatingPartnerApiKey ? `Create API Key` : `Creating...`}
                </button>
            </div>
        </div>
    )
}


function PartnerDetail() {
    const {
        partner_unique_identifier: partnerUniqueId
    } = useParams<{ partner_unique_identifier: string }>()

    const [viewingTab, setViewingTab] = useState<'permissions' | 'api_keys' | 'pipedrive'>('permissions')

    const [partner, setPartner] = useState<Partner | null>(null);
    const [loadingPartner, setLoadingPartner] = useState(true);
    const [errorLoadingPartner, setErrorLoadingPartner] = useState<string | null>(null);

    const loadPartner = useCallback(async () => {
        if (!partnerUniqueId) {
            return
        }
        setLoadingPartner(true)
        setErrorLoadingPartner(null)
        try {
            const updatedPartner = await getPartnerByUniqueIdentifier(partnerUniqueId)
            setPartner(updatedPartner)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load partner'
            setErrorLoadingPartner(errorMessage)
        } finally {
            setLoadingPartner(false)
        }
    }, [partnerUniqueId])

    useEffect(() => {
        loadPartner()
    }, [loadPartner])

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            // use pipedrive
            setViewingTab('pipedrive')
        }
    }, [])

    if (loadingPartner) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingPartner || !partner) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorLoadingPartner || 'Failed to load partner'}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadPartner}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <>
        <MogulHeader title={`Manage ${partner.name}`} />
        <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
            <div className='col-xxl-12'>
                <div className="header-menu align-items-stretch mb-5">
                    <div className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch">
                        <div className="menu-item me-lg-1">
                            <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'permissions' ? "active" : ""].join(" ")}
                                onClick={() => setViewingTab('permissions')}
                                type="button"
                            >
                                <span className="menu-title">Permissions</span>
                            </button>
                        </div>
                        <div className="menu-item me-lg-1">
                            <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'api_keys' ? "active" : ""].join(" ")}
                                onClick={() => setViewingTab('api_keys')}
                                type="button"
                            >
                                <span className="menu-title">API Keys</span>
                            </button>
                        </div>
                        <div className="menu-item me-lg-1">
                            <button className={["menu-link py-3 btn btn-link-primary", viewingTab === 'pipedrive' ? "active" : ""].join(" ")}
                                onClick={() => setViewingTab('pipedrive')}
                                type="button"
                            >
                                <span className="menu-title">PipeDrive</span>
                            </button>
                        </div>
                    </div>
                </div>
                {partnerUniqueId && <>
                    {viewingTab === 'permissions' && <PartnerPermissions partnerUniqueId={partnerUniqueId} />}
                    {viewingTab === 'api_keys' && <PartnerAPIKeys partnerUniqueId={partnerUniqueId} />}
                    {viewingTab === 'pipedrive' && <PartnerPipedrive partnerUniqueId={partnerUniqueId} />}
                </>}
            </div>
        </div>
    </>
}

function PartnersRoutes() {
    return (
        <>
            <Routes>
                <Route path=':partner_unique_identifier' element={<PartnerDetail />} />
            </Routes>
        </>
    )
}

export default PartnersRoutes