/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, { useCallback, useEffect, useRef } from 'react'

import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers'
import { Dropdown1 } from '../../_metronic/partials'
import { DateTime } from 'luxon'
import { APIError } from '../../app/modules/auth'
import { DashboardEventsListData, getDashboardListDataForEvents } from '../core/_requests'
import { formatDateForDatePickers } from './DashboardGraph'
import { Link } from 'react-router-dom'
import { formatEventDatetime } from '../routes/service-locations/detail/_ServiceLocationItemRenderer'
import useAccounts from '../hooks/useAccounts'

export function DashboardListForEvents() {

  const { accounts } = useAccounts() // redundant retrieval but it'll make sure it's updated. May be in a global context one day.

  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingError, setLoadingError] = React.useState<string | null>(null)
  const [dashboardResponse, setDashboardResponse] = React.useState<DashboardEventsListData | null>(
    null
  )

  const [startingUnixTimestamp, setStartingUnixTimestamp] = React.useState(
    Math.floor(DateTime.local().setZone('America/Chicago').minus({ days: 7 }).startOf('day').toSeconds())
  )

  const [endingUnixTimestamp, setEndingUnixTimestamp] = React.useState(
    Math.floor(DateTime.local().setZone('America/Chicago').toSeconds())
  )

  const loadDashboardData = useCallback(async () => {
    setIsLoading(true)
    try {
      let loadedDashboardData: DashboardEventsListData | null = null
      loadedDashboardData = await getDashboardListDataForEvents(
        startingUnixTimestamp,
        endingUnixTimestamp,
      )
      if (!loadedDashboardData) {
        throw new APIError('No dashboard data returned', {})
      }
      setLoadingError(null)
      setDashboardResponse(loadedDashboardData)
    } catch (error: any) {
      console.warn(error)
      const errorMessage = error instanceof APIError ? error.message : 'Something went wrong'
      setLoadingError(errorMessage)
    } finally {
      setIsLoading(false)
    }
    // Disabled because it leads to infinite reloads for some reason
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endingUnixTimestamp, startingUnixTimestamp])

  useEffect(() => {
    loadDashboardData()
  }, [loadDashboardData])

  const externalGHLSiteURLForAccountUniqueIdentifier = useCallback((accountUniqueIdentifier: string) => {
    //
    // Right now service locations don't have GHL details because it's an admin detail not added to that
    // retrieval. The quickest fix right now is looking up the admin account mapping
    // and deducing the URL from that.
    //
    if (!accountUniqueIdentifier) {
      return null
    }
    const matchingAccounts = accounts.filter(x => x.unique_identifier === accountUniqueIdentifier)
    if (!matchingAccounts.length) {
      return null
    }
    const account = matchingAccounts[0];
    if (account.external_ghl_site_url) {
      return account.external_ghl_site_url
    }
    return null
  }, [accounts])

  // See ListsWidget9 for a full implementation

  return (
    <div className='row gy-5 g-xl-8'>
      {/* begin::Col */}
      <div className='col-xxl-12'>
        <div className={clsx('card', 'card-xxl-stretch-100 mb-5 mb-xl-8')}>
          {/* begin::Header */}
          <div className='card-header align-items-center border-0 mt-3'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bolder text-dark fs-3'>
                Events by Date with People Interested in Planning Ahead (Last Week Only)
              </span>
              {false && <div className='d-flex flex-stack flex-wrap flex-grow-1 pt-3 pb-3'>
                <div >
                  <input
                    type='date'
                    value={formatDateForDatePickers(startingUnixTimestamp)}
                    onChange={(e) => {
                      // Parse the date selected by the user. Assume it's in 'America/Chicago' time zone from the start.
                      // This is crucial to avoid it being interpreted in the local time zone first, which can cause the date to shift.
                      const userSelectedDate = DateTime.fromISO(e.target.value, { zone: 'America/Chicago' }).startOf('day');

                      // Now, we directly have the date in the 'America/Chicago' time zone at the start of the day.
                      // Convert this to seconds for the backend.
                      const seconds = Math.floor(userSelectedDate.toSeconds());

                      // Update the state with this new value
                      setStartingUnixTimestamp(seconds);
                    }}
                  />
                </div>
                <div className='px-2 text-gray-600 fw-bold fs-6'>
                  to
                </div>
                <div>
                  <input
                    type='date'
                    value={formatDateForDatePickers(endingUnixTimestamp)}
                    onChange={(e) => {
                      // Parse the date selected by the user. Assume it's in 'America/Chicago' time zone from the start.
                      // This is crucial to avoid it being interpreted in the local time zone first, which can cause the date to shift.
                      const userSelectedDate = DateTime.fromISO(e.target.value, { zone: 'America/Chicago' }).endOf('day');

                      // Now, we directly have the date in the 'America/Chicago' time zone at the end of the day.
                      // Convert this to seconds for the backend.
                      const seconds = Math.floor(userSelectedDate.toSeconds());

                      // Update the state with this new value
                      setEndingUnixTimestamp(seconds);
                    }}
                  />
                </div>
              </div>}
            </h3>
            {false && 'enableForFilter' && <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
              <Dropdown1 />
              {/* end::Menu */}
            </div>}
          </div>
          {/* end::Header */}
          {loadingError ? <>
            <div className='card-body pt-5'>
              <div className='d-flex flex-column align-items-center'>
                <span className='fw-bold fs-3 text-danger'>Error loading data</span>
                <span className='fs-6 text-gray-400'>{loadingError}</span>
              </div>
            </div>
          </> : <>
            <div className='card-body pt-5'>
              {isLoading ? (
                <div>
                  <div className='spinner-border spinner-border-sm align-middle ms-2'></div>
                </div>
              ) : (
                <div>
                  {dashboardResponse?.events.slice().reverse().map((dateAndEvent, index) => {
                    return <div key={`event-${dateAndEvent.full_date}`} className={clsx('d-flex mb-7', index !== 0 && 'border-top border-gray-200 pt-5')}>
                      {/*begin::Section*/}
                      <div className='d-flex align-items-center flex-wrap flex-grow-1 mt-n2 mt-lg-n1'>
                        {/*begin::Title*/}
                        <div className='d-flex flex-column flex-grow-1 my-lg-0 my-2 pe-3'>
                          <div className='fs-5 text-gray-800 fw-bolder'>
                            {`${dateAndEvent.full_date} (${DateTime.fromFormat(dateAndEvent.full_date, 'yyyy-MM-dd').toFormat('cccc')})`}
                          </div>
                          {dateAndEvent.events.length > 0 ? <>
                            {dateAndEvent.events.map((eventObject, eventIndex) => {
                              const correspondingGHLURL = externalGHLSiteURLForAccountUniqueIdentifier(eventObject.event.account_unique_identifier)
                              const linkedToFRMLocationID = eventObject.event.location?.private_data?.linked_to_frm_location_id || null
                              const fullBaseURL = !correspondingGHLURL || !linkedToFRMLocationID ? null : `${correspondingGHLURL}/v2/location/${linkedToFRMLocationID}/contacts/detail`
                              return <div className={clsx('mt-3 ps-4', eventIndex !== 0 && 'border-top border-gray-200 pt-5')} key={`event-${eventObject.event.unique_identifier}`}>
                                <Link
                                  to={`/service-locations/${eventObject.event.location.unique_identifier}`}
                                  className='fs-5 text-gray-800 fw-bolder text-hover-primary'
                                >
                                  {`Location: ${eventObject.event.location.name}`}
                                </Link>
                                <div className='fs-5 text-gray-800 fw-bolder'>
                                  {`Event Name: ${eventObject.event.event_name} ... event took place at ${formatEventDatetime(eventObject.event)}`}
                                </div>
                                <div>
                                  <div className='fs-6 text-gray-500 mt-2'>
                                    Administrators:
                                  </div>
                                  {eventObject.event_administrators.length > 0 ? <div>
                                    {eventObject.event_administrators.map((record, adminIndex) => {
                                      return <div key={`admin-${adminIndex}`} className='my-3 ps-4'>
                                        <div>
                                          {`${record.person_full_name} - ${record.person_phone_number}`}
                                        </div>
                                        {record.public_data?.person_email_address && <div>
                                          Email: {`${record.public_data?.person_email_address}`}
                                        </div>}
                                        <div>
                                          Relationship: {`${record.public_data?.person_relationship_to_deceased || '(unknown)'}`}
                                        </div>
                                        {record.public_data?.latest_highlevel_contact_id && <div>
                                          {fullBaseURL ? <a href={`${fullBaseURL}/${record.public_data?.latest_highlevel_contact_id}`} target='_blank' rel='noreferrer'>
                                            Direct 360 Link
                                          </a> : <div className='text-gray-400'>No 360 URL available</div>}
                                        </div>}
                                      </div>
                                    })}
                                  </div> : <div className='mt-3 fs-6 text-gray-400'>
                                    No administrators
                                  </div>}
                                  <div className='fs-6 text-gray-500 mt-2'>
                                    Check-In Records:
                                  </div>
                                  {eventObject.check_in_records.length > 0 ? <div>
                                    {eventObject.check_in_records.map((record, recordIndex) => {
                                      return <div key={`check-in-${recordIndex}`} className={clsx('my-3 ps-4', recordIndex !== 0 && 'border-top border-gray-200 pt-5')}>
                                        <div>
                                          {`${record.visitor_full_name} - ${record.visitor_phone_number}`}
                                        </div>
                                        {record.public_data?.visitor_email_address && <div>
                                          Email: {`${record.public_data?.visitor_email_address}`}
                                        </div>}
                                        <div>
                                          Relationship: {`${record.public_data?.visitor_relationship_to_deceased || '(unknown)'}`}
                                        </div>
                                        {record.public_data?.latest_highlevel_contact_id && <div>
                                          {fullBaseURL ? <a href={`${fullBaseURL}/${record.public_data?.latest_highlevel_contact_id}`} target='_blank' rel='noreferrer'>
                                            Direct 360 Link
                                          </a> : <div className='text-gray-400'>No 360 URL available</div>}
                                        </div>}
                                      </div>
                                    })}
                                  </div> : <div className='mt-3 fs-6 text-gray-400'>
                                    No check-in records
                                  </div>}
                                </div>
                              </div>
                            })}
                          </> : <div className='mt-3 fs-6 text-gray-400'>
                            No events
                          </div>}
                        </div>
                        {/*end::Title*/}
                        {/*begin::Info*/}
                        {false && 'niceNumbersOnRight' && <div className='text-end py-lg-0 py-2'>
                          <span className='text-gray-800 fw-boldest fs-3'>24,900</span>
                          <span className='text-gray-400 fs-7 fw-bold d-block'>Sales</span>
                        </div>}
                        {/*end::Info*/}
                      </div>
                      {/*end::Section*/}
                    </div>
                  })}
                </div>
              )}
            </div>
          </>}
        </div>

      </div>
    </div>
  )
}
