import clsx from "clsx"
import { useState, useEffect, Fragment } from "react"
import { Link } from "react-router-dom"
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers"
import { DefaultTitle } from "../../_metronic/layout/components/header/page-title/DefaultTitle"
import { Topbar } from "../../_metronic/layout/components/header/Topbar"
import { PageLink, useLayout } from "../../_metronic/layout/core"

function MogulHeader({ title }: { title: string }) {
    const pageTitle = title
    const pageDescription = ''

    const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])

    const { config, classes, attributes } = useLayout()
    const { header } = config
    const [offset, setOffset] = useState<string>(`{default: '200px', lg: '300px'}`)

    useEffect(() => {
        let newString = `{default: '200px', lg: '300px'}`
        if (header.fixed.desktop) {
            if (!header.fixed.tabletAndMobile) {
                newString = `{lg: '300px'}`
            }
        } else {
            newString = `{default: '200px', lg: false}`
        }

        setOffset(newString)
    }, [header.fixed])

    return (
        <div
            className="mt-8 mb-4"
        >
            {/* begin::Container */}
            <div
                className={clsx(
                    'd-flex align-items-center justify-content-between'
                )}
            >
                <div
                    data-kt-swapper='true'
                    data-kt-swapper-mode='prepend'
                    data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_container'}"
                    className={clsx(
                        'page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0',
                        classes.pageTitle.join(' ')
                    )}
                >
                    {/* begin::Heading */}
                    <h1 className='text-dark fw-bolder my-0 fs-2'>
                        {pageTitle && pageTitle}
                        {pageDescription && (
                            <span className='text-muted fs-6 fw-normal ms-1'>{pageDescription}</span>
                        )}
                    </h1>
                    {/* end::Heading */}

                    {pageBreadcrumbs &&
                        pageBreadcrumbs.length > 0 &&
                        config.pageTitle &&
                        config.pageTitle.breadCrumbs && (
                            <>
                                <ul className='breadcrumb fw-bold fs-base my-1'>
                                    {Array.from(pageBreadcrumbs)
                                        .filter((t) => !t.isSeparator)
                                        .map((item, index) => (
                                            <Fragment key={`${item.path}${index}`}>
                                                {item.isActive ? (
                                                    <li className='breadcrumb-item text-dark'>{item.title}</li>
                                                ) : (
                                                    <li className='breadcrumb-item text-muted'>
                                                        <Link to={item.path} className='text-muted'>
                                                            {item.title}
                                                        </Link>
                                                    </li>
                                                )}
                                            </Fragment>
                                        ))}
                                    <li className='breadcrumb-item text-dark'>{pageTitle}</li>
                                </ul>
                            </>
                        )}
                </div>
                {/* begin::Wrapper */}
                <div className={'d-flex d-lg-none align-items-center ms-n2 me-2'}>
                    {/* begin::Aside mobile toggle */}
                    <div className='btn btn-icon btn-active-icon-primary aside-toggle' id='kt_aside_toggle'>
                        <KTIcon iconName='abstract-14' className='fs-1' />
                    </div>
                    {/* begin::Logo */}
                    <Link to='/dashboard' className='d-flex align-items-center'>
                        <img alt='Logo' src={toAbsoluteUrl('/media/logos/demo7.svg')} className='h-30px' />
                    </Link>
                    {/* end::Logo */}
                </div>
                {/* end::Wrapper */}
                <Topbar />
            </div>
            {/* end::Container */}
        </div>
    )
}

export default MogulHeader