/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Select from 'react-select'

import useLocations from '../../../../mogul/hooks/useLocations'
import { useAuth } from '../../../../app/modules/auth'

export function FilterAllLeads({
  onCancel,
  onApply,
  showLeadsRegardlessofGHLStatus,
  setShowLeadsRegardlessofGHLStatus
}: {
  onCancel: () => void,
  onApply: (selectedLocationUniqueIdentifier: string | null) => void,
  showLeadsRegardlessofGHLStatus: boolean,
  setShowLeadsRegardlessofGHLStatus: (value: boolean) => void,
}) {
  const {
    currentUser
  } = useAuth()

  const {
    locations,
    loading: loadingLocations,
    errorMessage: errorMessageLocations
  } = useLocations()

  const [selectedLocationUniqueIdentifier, setSelectedLocationUniqueIdentifier] = useState<string | null>(null)

  return (
    <div>
      <div className='px-7 py-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter List</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>
        {false && <>
          <div className='mb-10'>
            <label className='form-label fw-bold'>Status:</label>

            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                defaultValue={'1'}
              >
                <option></option>
                <option value='1'>Approved</option>
                <option value='2'>Pending</option>
                <option value='3'>In Process</option>
                <option value='4'>Rejected</option>
              </select>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Member Type:</label>

            <div className='d-flex'>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input className='form-check-input' type='checkbox' value='1' />
                <span className='form-check-label'>Author</span>
              </label>

              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <input className='form-check-input' type='checkbox' value='2' defaultChecked={true} />
                <span className='form-check-label'>Customer</span>
              </label>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Notifications:</label>

            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                name='notifications'
                defaultChecked={true}
              />
              <label className='form-check-label'>Enabled</label>
            </div>
          </div>
        </>}

        <div className='mb-5'>
          {loadingLocations ? <>
            <div className='text-center'>
              <div className='spinner-border text-primary'></div>
            </div>
          </> : <>
            {errorMessageLocations ? <>
              <div className="alert alert-danger" role="alert">
                {errorMessageLocations}
              </div>
            </> : <>
              {currentUser?.is_admin && <div className='mb-5'>
                <label className='form-label fw-bold'>Show All Leads:</label>
                <div>
                  <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      name='showLeadsRegardlessofGHLStatus'
                      checked={showLeadsRegardlessofGHLStatus}
                      onChange={(e) => setShowLeadsRegardlessofGHLStatus(e.target.checked)}
                    />
                    <label className='form-check-label'>Enabled</label>
                  </div>
                </div>
              </div>}
              <label className='form-label fw-bold'>Filter by Location:</label>
              <Select
                options={[
                  { value: '', label: '- Leave un-mapped -' },
                  ...locations.map((customField) => {
                    return {
                      value: customField.unique_identifier,
                      label: `${customField.name}`
                    }
                  })]}
                onChange={(selectedOption) => {
                  setSelectedLocationUniqueIdentifier(selectedOption ? selectedOption.value : null)
                }}
                value={locations.filter(x => x.unique_identifier === selectedLocationUniqueIdentifier).map((customField) => {
                  return {
                    value: customField.unique_identifier,
                    label: `${customField.name}`
                  }
                })}
                filterOption={(option, searchValue) => {
                  const words = searchValue.toLowerCase().split(' ');
                  return words.every(word => option.label.toLowerCase().includes(word));
                }}
              />
            </>}
          </>}
        </div>

        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            onClick={() => onCancel()}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={() => onApply(selectedLocationUniqueIdentifier)}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}
