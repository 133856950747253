import { useState, useCallback, useEffect } from 'react'

import { APIError } from '../../app/modules/auth'
import { Partner, getAvailableFullCircleOptions } from '../core/_requests'

function useAvailableFullCircleAfterCareOptions() {
  const [availablePreneedLeadInterestOptions, setAvailablePreneedLeadInterestOptions] = useState([
    {
      "id": 224,
      "label": "Yes - Now (In the next 8 weeks)"
    },
    {
      "id": 315,
      "label": "Yes - Later"
    },
    {
      "id": 225,
      "label": "No"
    },
    {
      "id": 247,
      "label": "Maybe"
    },
    {
      "id": 226,
      "label": "Preneed Not Discussed"
    },
    {
      "id": 248,
      "label": "Already Has A Policy"
    },
    {
      "id": 249,
      "label": "Could Not Contact"
    }
  ])

  /*
  SELECT 'const [availableOrgNames, setAvailableOrgNames] = useState([' || 
        string_agg(DISTINCT '''' || (latest_deal_json::jsonb->>'org_name') || '''', ', ') || 
        ']);' AS react_code
  FROM partners_pipedrive_accounts_deals
  WHERE (latest_deal_json::jsonb->>'org_name') IS NOT NULL;
  */

  // or:

  /*
  SELECT DISTINCT (latest_deal_json->>'org_name') AS org_name
  FROM partners_pipedrive_accounts_deals
  WHERE latest_deal_json->>'org_name' IS NOT NULL
  ORDER BY org_name ASC;
  */

  // const [availableOrgNames, setAvailableOrgNames] = useState(['Angeleno Mortuary', 'Angeleno Valley Mortuary', 'Antelope Valley Cremation', 'Basham & Lara Funeral Care', 'Crawford Lorenzen Mortuary', 'Fontana Mortuary', 'Hadley-Marcom Funeral Chapel (Visalia)', 'Halley Olsen Chapel of the Valley', 'Halley-Olsen-Murphy Funerals & Cremations', 'Ingold Funeral and Cremation', 'Kevin Brennan Family Funeral Home (TRIAL)', 'Reardon Simi Valley Funeral Home', 'Sterling & Smith Funeral Directors (Dinuba)', 'Sterling & Smith Funeral Directors (Fresno)', 'Sterling & Smith Funeral Directors (Tulare)', 'Testing Organization']);

  const [availableOrgSelections, setAvailableOrgSelections] = useState<{ org_name: string, org_id: string }[]>([])

  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const loadPartners = useCallback(async () => {
    setLoading(true)
    try {
      const {
        org_options: organizationOptions
      } = await getAvailableFullCircleOptions()
      setAvailableOrgSelections(organizationOptions)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load available options'
      setErrorMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadPartners()
  }, [loadPartners])

  return {
    availablePreneedLeadInterestOptions,
    availableOrgSelections,
    loading,
    errorMessage,
  }
}

export default useAvailableFullCircleAfterCareOptions
