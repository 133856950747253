import { Link } from 'react-router-dom'
import { KTIcon } from '../../_metronic/helpers'
import { Dropdown1, Search } from '../../_metronic/partials'
import SimpleServiceLocationSearch from '../components/search/SimpleServiceLocationSearch';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DateTime
} from 'luxon'
import {
  Account,
  ServiceLocation, getServiceLocations, Partner
} from '../core/_requests';
import { APIError, useAuth } from '../../app/modules/auth';
import useAccounts from '../hooks/useAccounts';
import usePartners from '../hooks/usePartners';

function PartnerRow({ partner }: { partner: Partner }) {
  return <Link
    to={false ? '/crafted/pages/profile/projects' : `/partners/${partner.unique_identifier}`}
    className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3'
    style={{
      border: '1px solid #ebebeb',
      borderRadius: 6
    }}
  >
    {/*begin::Description*/}
    <div className='d-flex flex-column flex-grow-1'>
      {/*begin::Title*/}
      <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{partner.name}</h5>
      {/*end::Title*/}
    </div>
    {/*begin::Description*/}
  </Link>
}

const AccountRow = ({ account }: { account: Account }) => {
  const relevantDates = [
    account.last_event_check_in_record_inserted_at,
    account.last_event_administrator_inserted_at,
    account.last_direct_mail_reply_inserted_at
  ].filter((d): d is NonNullable<typeof d> => d != null);
  const mostRecentDate = relevantDates.length > 0 ? relevantDates.reduce((a, b) => a > b ? a : b) : null;

  const {
    currentUser
  } = useAuth()

  return <Link
    to={false ? '/crafted/pages/profile/projects' : `/accounts/${account.unique_identifier}`}
    className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3 position-relative'
    style={{
      border: '1px solid #ebebeb',
      borderRadius: 6
    }}
  >
    {/*begin::Description*/}
    <div className='d-flex flex-column flex-grow-1'>
      {/*begin::Title*/}
      <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>
        {account.name}
      </h5>
      {/*end::Title*/}
      {/*begin::Info*/}
      <div>
        <div className='fw-bold text-gray-400'>
          {mostRecentDate ? `Last lead received: ${DateTime.fromISO(mostRecentDate).toRelative()}` : 'No leads received'}
        </div>
      </div>
      {currentUser?.is_admin && !account.private_data?.linked_to_frm_company_id && <div style={{
        position: 'absolute',
        right: 4,
        top: 4,
        bottom: 0,
      }}>
        <span
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <i
            className='fas fa-exclamation-circle mx-2 fs-7'
            data-bs-toggle='tooltip'
            title={`${account.name}'s account has not been linked to the 360 platform.`}
          ></i>
        </span>
      </div>}
    </div>
    {/*begin::Description*/}
  </Link>
}

const ServiceLocationRow = ({ serviceLocation }: { serviceLocation: ServiceLocation }) => {

  const relevantDates = [
    serviceLocation.last_event_check_in_record_inserted_at,
    serviceLocation.last_event_administrator_inserted_at,
    serviceLocation.last_direct_mail_reply_inserted_at
  ].filter((d): d is NonNullable<typeof d> => d != null);
  const mostRecentDate = relevantDates.length > 0 ? relevantDates.reduce((a, b) => a > b ? a : b) : null;

  return <Link
    to={false ? '/crafted/pages/profile/projects' : `/service-locations/${serviceLocation.unique_identifier}`}
    className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3 position-relative'
    style={{
      border: '1px solid #ebebeb',
      borderRadius: 6
    }}
  >

    {/*begin::Description*/}
    <div className='d-flex flex-column flex-grow-1'>
      {/*begin::Title*/}
      <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>{serviceLocation.name}</h5>
      {/*end::Title*/}

      {/*begin::Info*/}
      <div>
        <div className='fw-bold text-gray-400'>
          {serviceLocation.account.name}
        </div>
        <div className='text-gray-600 mt-1'>
          {mostRecentDate ? `Last lead received: ${DateTime.fromISO(mostRecentDate).toRelative()}` : 'No leads received'}
        </div>
      </div>

      {!serviceLocation.private_data?.linked_to_frm_location_id && <div style={{
        position: 'absolute',
        right: 4,
        top: 4,
        bottom: 0,
      }}>
        <span
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
        >
          <i
            className='fas fa-exclamation-circle mx-2 fs-7'
            data-bs-toggle='tooltip'
            title={`${serviceLocation.name}'s location has not been linked to the 360 platform.`}
          ></i>
        </span>
      </div>}

    </div>
    {/*begin::Description*/}
  </Link>
}

const AccountsAndServiceLocationsTab = ({ isAdmin }: { isAdmin: boolean }) => {

  const [searchText, setSearchText] = useState<string>('')

  const {
    accounts,
    loadingAccounts,
    errorLoadingAccounts,
  } = useAccounts()

  const {
    partners,
    loading: loadingPartners,
    errorMessage: errorLoadingPartners,
  } = usePartners(isAdmin)

  const [serviceLocations, setServiceLocations] = useState<ServiceLocation[]>([])
  const [loadingServiceLocations, setLoadingServiceLocations] = useState(true);
  const [errorLoadingServiceLocations, setErrorLoadingServiceLocations] = useState<string | null>(null);

  const loadServiceLocations = useCallback(async () => {
    setLoadingServiceLocations(true)
    try {
      const updatedServiceLocations = await getServiceLocations()
      setServiceLocations(updatedServiceLocations)
    } catch (exception) {
      console.error(exception)
      const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load service locations'
      setErrorLoadingServiceLocations(errorMessage)
    } finally {
      setLoadingServiceLocations(false)
    }
  }, [])

  useEffect(() => {
    loadServiceLocations()
  }, [loadServiceLocations])

  const filteredAccounts = useMemo(() => {
    if (searchText.length > 0) {
      return accounts.filter((p) => p.name.toLowerCase().includes(searchText.toLowerCase()))
    } else {
      return accounts
    }
  }, [searchText, accounts])

  const filteredServiceLocations = useMemo(() => {
    if (searchText.length > 0) {
      return serviceLocations.filter((p) => p.name.toLowerCase().includes(searchText.toLowerCase()) || p.account.name.toLowerCase().includes(searchText.toLowerCase()) || p.account.unique_identifier.toLowerCase().includes(searchText.toLowerCase()) || p.unique_identifier.toLowerCase().includes(searchText.toLowerCase()))
    } else {
      return serviceLocations
    }
  }, [searchText, serviceLocations])

  const filteredPartners = useMemo(() => {
    if (searchText.length > 0) {
      return partners.filter((p) => p.name.toLowerCase().includes(searchText.toLowerCase()))
    } else {
      return partners
    }
  }, [searchText, partners])

  return (
    <div className='m-0'>

      {/* This is a much more interesting and complex search... */}

      {false && <>
        {/* begin::Toolbar */}
        <div className='d-flex mb-10'>
          <Search />
          {/* begin::Filter */}
          <div className='flex-shrink-0 ms-2'>
            {/* begin::Menu toggle */}
            <button
              type='button'
              className='btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTIcon iconName='filter' className='fs-2' />
            </button>
            {/* end::Menu toggle */}

            <Dropdown1 />
          </div>
          {/* end::Filter */}
        </div>
        {/* end::Toolbar */}
      </>}

      <div className='d-flex mb-10'>
        <SimpleServiceLocationSearch onChangedSearchText={(searchText: string) => setSearchText(searchText)} />
      </div>

      {!searchText && <div className='mb-8'>

        {/*begin::Heading*/}
        <h1 className='text-gray-800 fw-bold mb-6 mx-5'>Utilities</h1>
        {/*end::Heading*/}

        <div>
          <Link
            to={`/`}
            className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3'
            style={{
              border: '1px solid #ebebeb',
              borderRadius: 6
            }}
          >
            {/*begin::Description*/}
            <div className='d-flex flex-column flex-grow-1'>
              {/*begin::Title*/}
              <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>
                Main Dashboard
              </h5>
              {/*end::Title*/}
            </div>
            {/*begin::Description*/}
          </Link>
        </div>

        <div>
          <Link
            to={`/debugging/all-leads`}
            className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3'
            style={{
              border: '1px solid #ebebeb',
              borderRadius: 6
            }}
          >
            {/*begin::Description*/}
            <div className='d-flex flex-column flex-grow-1'>
              {/*begin::Title*/}
              <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>
                All Leads
              </h5>
              {/*end::Title*/}
            </div>
            {/*begin::Description*/}
          </Link>
        </div>

        {isAdmin && <>

          {false && 'pipeDriveDataIsInAllLeadsNow' && <div>
            <Link
              to={`/debugging/all-pipedrive`}
              className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3'
              style={{
                border: '1px solid #ebebeb',
                borderRadius: 6
              }}
            >
              {/*begin::Description*/}
              <div className='d-flex flex-column flex-grow-1'>
                {/*begin::Title*/}
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>
                  All Pipedrive Data
                </h5>
                {/*end::Title*/}
              </div>
              {/*begin::Description*/}
            </Link>
          </div>}

          {false && 'ghlIsWorkingSoNoNeedForThis' && <div>
            <Link
              to={`/debugging/ghl-requests`}
              className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3'
              style={{
                border: '1px solid #ebebeb',
                borderRadius: 6
              }}
            >
              {/*begin::Description*/}
              <div className='d-flex flex-column flex-grow-1'>
                {/*begin::Title*/}
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>
                  360 Requests
                </h5>
                {/*end::Title*/}
              </div>
              {/*begin::Description*/}
            </Link>
          </div>}

          {false && 'passareRequestAreInAllLeadsNow' && <div>
            <Link
              to={`/debugging/passare-requests`}
              className='custom-list d-flex align-items-center px-5 py-4 w-100 mb-3'
              style={{
                border: '1px solid #ebebeb',
                borderRadius: 6
              }}
            >
              {/*begin::Description*/}
              <div className='d-flex flex-column flex-grow-1'>
                {/*begin::Title*/}
                <h5 className='custom-list-title fw-bold text-gray-800 mb-1'>
                  Passare Requests
                </h5>
                {/*end::Title*/}
              </div>
              {/*begin::Description*/}
            </Link>
          </div>}

        </>}

      </div>}

      {/*begin::Heading*/}
      <h1 className='text-gray-800 fw-bold mb-6 mx-5'>
        Accounts
      </h1>
      {/*end::Heading*/}

      <div className='mb-5'>
        <div className='d-flex flex-column flex-center'>
          {loadingAccounts ? <>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </> : <>
            {errorLoadingAccounts ? <>
              <div className='w-100'>
                <div className='alert alert-danger'>
                  {errorLoadingAccounts}
                </div>
              </div>
            </> : <>
              {filteredAccounts.length === 0 ? <>
                <div>
                  {!searchText ? 'No accounts are available.' : 'No accounts match your search.'}
                </div>
              </> : <>
                {filteredAccounts.map((account) => <AccountRow key={`account-${account.unique_identifier}`} account={account} />)}
              </>}
            </>}
          </>}
        </div>
      </div>

      {isAdmin && <>

        {true && <>

          {/*begin::Heading*/}
          <h1 className='text-gray-800 fw-bold mb-6 mx-5'>Partners</h1>
          {/*end::Heading*/}

          {isAdmin && <>

            {!searchText && <div className='my-5'>
              <button
                className='btn btn-secondary btn-sm w-100'
                data-bs-toggle='modal'
                data-bs-target='#modal_create_partner'
              >
                <KTIcon iconName='plus' className='fs-2 text-primary me-0 me-md-2' />
                <span className='d-none d-md-inline'>
                  Add a Partner
                </span>
              </button>
            </div>}

          </>}

          <div className='mb-5'>
            <div className='d-flex flex-column flex-center'>
              {loadingPartners ? <>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </> : <>
                {errorLoadingPartners ? <>
                  <div className='w-100'>
                    <div className='alert alert-danger'>
                      {errorLoadingPartners}
                    </div>
                  </div>
                </> : <>
                  {filteredPartners.length === 0 ? <>
                    <div>
                      {!searchText ? 'No partners are available.' : 'No partners match your search.'}
                    </div>
                  </> : <>
                    {filteredPartners.map((partner) => <PartnerRow key={`partner-${partner.unique_identifier}`} partner={partner} />)}
                  </>}
                </>}
              </>}
            </div>
          </div>

        </>}

      </>}

      {/*begin::Heading*/}
      <h1 className='text-gray-800 fw-bold mb-6 mx-5'>Service Locations</h1>
      {/*end::Heading*/}

      <div className='mb-5'>

        {isAdmin && <>

          {!searchText && <div className='my-5'>
            <button
              className='btn btn-secondary btn-sm w-100'
              data-bs-toggle='modal'
              data-bs-target='#modal_create_service_location'
            >
              <KTIcon iconName='plus' className='fs-2 text-primary me-0 me-md-2' />
              <span className='d-none d-md-inline'>
                Add a Location
              </span>
            </button>
          </div>}

        </>}

        <div className='d-flex flex-column flex-center'>
          {loadingServiceLocations ? <>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </> : <>
            {errorLoadingServiceLocations ? <>
              <div className='w-100'>
                <div className='alert alert-danger'>
                  {errorLoadingServiceLocations}
                </div>
              </div>
            </> : <>
              {filteredServiceLocations.length === 0 ? <>
                <div>
                  {!searchText ? 'No service locations are available.' : 'No service locations match your search.'}
                </div>
              </> : <>
                {filteredServiceLocations.map((serviceLocation) => <ServiceLocationRow key={`service-location-${serviceLocation.unique_identifier}`} serviceLocation={serviceLocation} />)}
              </>}
            </>}
          </>}
        </div>
      </div>

    </div>
  )
}

export default AccountsAndServiceLocationsTab
