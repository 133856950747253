import { useState, useCallback, useEffect } from 'react'

import { APIError } from '../../app/modules/auth'
import { Partner, getLocations } from '../core/_requests'

function useLocations() {
  const [locations, setLocations] = useState<Partner[]>([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const loadLocations = useCallback(async () => {
    setLoading(true)
    try {
      const updatedPartners = await getLocations()
      setLocations(updatedPartners)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load partners'
      setErrorMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadLocations()
  }, [loadLocations])

  return {
    locations,
    loading,
    errorMessage,
  }
}

export default useLocations
