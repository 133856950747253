import axios from 'axios'
import { AuthModel, SimplifiedPhoenixUser, UserModel } from './_models'

const API_URL = `${process.env.REACT_APP_API_URL}/admin/api/v1`

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(`${API_URL}/login-with-email`, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios
    .post<UserModel>(`${API_URL}/verify-user-token`, {
      api_token: token,
    })
    .then((res) => res.data)
    .then((response: any) => {
      return new SimplifiedPhoenixUser(response.data)
    })
}

export function requestCodeForEmailAddress(email: string) {
  return axios.post(`${API_URL}/request-code-for-email`, {
    email,
  })
}

export function confirmCodeForEmailAddress(email: string, code: string) {
  return axios.post(`${API_URL}/confirm-code-for-email`, {
    email,
    code,
  })
}
