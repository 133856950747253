/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, Navigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, requestCodeForEmailAddress, confirmCodeForEmailAddress } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { APIError } from '../core/AuthHelpers'

const confirmCodeSchema = Yup.object().shape({
  code: Yup.string()
    .min(0, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Code is required'),
})

const initialValues = {
  code: '',
}

function LoginConfirm() {

  const queryParameters = new URLSearchParams(window.location.search)
  const emailAddress = (queryParameters.get('e') || '').trim()

  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const [successfulResendMessage, setSuccessfulResendMessage] = useState('')
  const [resending, setResending] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: confirmCodeSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setSuccessfulResendMessage('')
      try {
        const { data: { data: auth } } = await confirmCodeForEmailAddress(emailAddress, values.code)
        if (!auth.api_token) {
          throw new Error('Malformed response', auth)
        }
        saveAuth(auth)
        const user = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (exception) {
        console.error(exception)
        const errorMessage = exception instanceof APIError ? exception.message : 'Your login information is incorrect. Please try again.'
        setStatus(errorMessage)
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const onResend = useCallback(async () => {
    setResending(true)
    setSuccessfulResendMessage('')
    try {
      await requestCodeForEmailAddress(emailAddress)
      setSuccessfulResendMessage('Code resent successfully.')
    } catch (exception) {
      console.error(exception)
      const errorMessage = exception instanceof APIError ? exception.message : 'Failed to resend code. Please try again.'
      formik.setStatus(errorMessage)
    } finally {
      setResending(false)
    }
  }, [])

  if (!emailAddress) {
    return <Navigate to='/auth/login-with-email' replace />
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Confirm Email Address</h1>
        <h3 className='fw-semibold fs-3'>
          We have sent a code to your email address. Please enter it below.
        </h3>
      </div>
      {/* begin::Heading */}

      {false && <>

        {/* begin::Login options */}
        <div className='row g-3 mb-9'>
          {/* begin::Col */}
          <div className='col-md-12'>
            {/* begin::Google link */}
            <button
              type="button"
              className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              onClick={() => {
                formik.setStatus('Google login is not implemented yet.')
              }}
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                className='h-15px me-3'
              />
              Sign in with Google
            </button>
            {/* end::Google link */}
          </div>
          {/* end::Col */}

          {/* begin::Col */}
          {false && <div className='col-md-6'>
            {/* begin::Google link */}
            <a
              href='#'
              className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
            >
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                className='theme-light-show h-15px me-3'
              />
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
                className='theme-dark-show h-15px me-3'
              />
              Sign in with Apple
            </a>
            {/* end::Google link */}
          </div>}
          {/* end::Col */}
        </div>
        {/* end::Login options */}

        {/* begin::Separator */}
        <div className='separator separator-content my-14'>
          <span className='w-100 text-gray-500 fw-semibold fs-7'>Or login with your email address:</span>
        </div>
        {/* end::Separator */}

      </>}

      {successfulResendMessage ? <>
        <div className='mb-lg-15 alert alert-success'>
          <div className='alert-text font-weight-bold text-center'>{successfulResendMessage}</div>
        </div>
      </> : <>
        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold text-center'>{formik.status}</div>
          </div>
        ) : (
          <>
            {false && <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>
                Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
                continue.
              </div>
            </div>}
          </>
        )}
      </>}

      {/* begin::Form group */}
      <div className='fv-row mb-6'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <div>
          <h3 className='fw-semibold fs-3'>
            {emailAddress}
          </h3>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-0'>
        <label className='form-label fs-6 fw-bolder text-dark'>Code</label>
        <input
          placeholder='Code'
          {...formik.getFieldProps('code')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.code && formik.errors.code },
            {
              'is-valid': formik.touched.code && !formik.errors.code,
            }
          )}
          type='text'
          name='code'
          autoComplete='off'
          required
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.code}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />
        {/* begin::Link */}
        {false && <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <button
          type='button'
          className='btn btn-secondary mt-3'
          disabled={resending}
          onClick={onResend}
        >
          {!resending && <span className='indicator-label'>Resend Code</span>}
          {resending && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Resending...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth' className='btn btn-light mt-3'>
          Cancel
        </Link>
      </div>
      {/* end::Action */}

      {false && <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>}
    </form>
  )
}

export default LoginConfirm