/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from 'react'
import Select from 'react-select'

import useLocations from '../../../../../mogul/hooks/useLocations'
import useAvailableFullCircleAfterCareOptions from '../../../../../mogul/hooks/useAvailableFullCircleAfterCareOptions'

export interface PipedriveDealFilter {
  by_pipedrive_account_unique_identifier?: string
  by_status?: string
  by_preneed_option?: string
}

export function FilterPipedriveDeals({
  currentFilter,
  onReset,
  onCancel,
  onApply,
}: {
  currentFilter: PipedriveDealFilter | undefined,
  onReset: () => void,
  onCancel: () => void,
  onApply: (filter: PipedriveDealFilter) => void,
}) {
  const {
    locations,
    loading: loadingLocations,
    errorMessage: errorMessageLocations
  } = useLocations()

  const [selectedLocationUniqueIdentifier, setSelectedLocationUniqueIdentifier] = useState<string | null>(null)

  const [selectedPipedriveAccount, setSelectedPipedriveAccount] = useState<string>(currentFilter?.by_pipedrive_account_unique_identifier || '')
  const [selectedStatus, setSelectedStatus] = useState<string>(currentFilter?.by_status || '')
  const [selectedPreneedOption, setSelectedPreneedOption] = useState<string>(currentFilter?.by_preneed_option || '')

  const {
    availablePreneedLeadInterestOptions
  } = useAvailableFullCircleAfterCareOptions()

  return (
    <div>
      <div className='px-7 mb-5'>
        <div className='fs-5 text-dark fw-bolder'>Filter List</div>
      </div>

      <div className='separator border-gray-200'></div>

      <div className='px-7 py-5'>

        {false && <>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Member Type:</label>

            <div className='d-flex'>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input className='form-check-input' type='checkbox' value='1' />
                <span className='form-check-label'>Author</span>
              </label>

              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <input className='form-check-input' type='checkbox' value='2' defaultChecked={true} />
                <span className='form-check-label'>Customer</span>
              </label>
            </div>
          </div>

          <div className='mb-10'>
            <label className='form-label fw-bold'>Notifications:</label>

            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                name='notifications'
                defaultChecked={true}
              />
              <label className='form-check-label'>Enabled</label>
            </div>
          </div>
        </>}

        <div className='mb-5'>
          <div className='mb-5'>
            <label className='form-label fw-bold'>Account:</label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={selectedPipedriveAccount}
                onChange={(e) => setSelectedPipedriveAccount(e.target.value)}
              >
                <option value=''>-All Accounts-</option>
                <option value='piac_32bLcR4QuuXcJoDtToIrn'>Full-Circle</option>
                <option value='piac_TShX27nNgAbK5KZMndbk5'>Chris Sandbox</option>
              </select>
            </div>
          </div>
          <div className='mb-5'>
            <label className='form-label fw-bold'>Status:</label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value=''>-All Statuses-</option>
                <option value='won'>Won</option>
                <option value='open'>Open</option>
                <option value='lost'>Lost</option>
              </select>
            </div>
          </div>
          <div className='mb-5'>
            <label className='form-label fw-bold'>Pre-Need?:</label>
            <div>
              <select
                className='form-select form-select-solid'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={selectedPreneedOption}
                onChange={(e) => setSelectedPreneedOption(e.target.value)}
              >
                <option value=''>-All Options-</option>
                {availablePreneedLeadInterestOptions.map((option) => {
                  return <option key={option.id} value={`${option.id}`}>{option.label}</option>
                })}
              </select>
            </div>
          </div>
          {false && <>
            {/* For location filtering... */}
            {
              loadingLocations ? <>
                <div className='text-center'>
                  <div className='spinner-border text-primary'></div>
                </div>
              </> : <>
                {errorMessageLocations ? <>
                  <div className="alert alert-danger" role="alert">
                    {errorMessageLocations}
                  </div>
                </> : <>
                  <label className='form-label fw-bold'>Filter by Location:</label>
                  <Select
                    options={[
                      { value: '', label: '- Leave un-mapped -' },
                      ...locations.map((customField) => {
                        return {
                          value: customField.unique_identifier,
                          label: `${customField.name}`
                        }
                      })]}
                    onChange={(selectedOption) => {
                      setSelectedLocationUniqueIdentifier(selectedOption ? selectedOption.value : null)
                    }}
                    value={locations.filter(x => x.unique_identifier === selectedLocationUniqueIdentifier).map((customField) => {
                      return {
                        value: customField.unique_identifier,
                        label: `${customField.name}`
                      }
                    })}
                    filterOption={(option, searchValue) => {
                      const words = searchValue.toLowerCase().split(' ');
                      return words.every(word => option.label.toLowerCase().includes(word));
                    }}
                  />
                </>}
              </>
            }
          </>}
        </div >

        <div className='d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            onClick={() => onReset()}
          >
            Reset
          </button>
          <button
            type='button'
            className='btn btn-sm btn-light btn-active-light-primary me-2'
            onClick={() => onCancel()}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={() => {
              const result = {
                by_pipedrive_account_unique_identifier: selectedPipedriveAccount,
                by_status: selectedStatus,
                by_preneed_option: selectedPreneedOption,
              } as PipedriveDealFilter
              onApply(result)
            }}
          >
            Apply
          </button>
        </div>
      </div >

      <div className='separator border-gray-200 mb-5'></div>

    </div >
  )
}
