/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react'
import { KTIcon } from '../../../helpers'

type Props = {
  className: string
}

const TablesWidget9: React.FC<Props> = ({ className }) => {
  const [waiverCount, setWaiverCount] = useState(1)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const loadWaivers = useCallback(async () => {
    setError('This has been deprecated')
  }, [])

  useEffect(() => {
    loadWaivers()
  }, [loadWaivers])

  if (loading) {
    return <></>
  }

  if (error) {
    return <div>
      {error}
    </div>
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      {false && <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            Signed Waivers
          </span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {`${waiverCount} waiver${waiverCount > 1 ? 's' : ''}`}
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTIcon iconName='plus' className='fs-3' />
            New Member
          </a>
        </div>
      </div>}


      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Users</th>
                <th className='min-w-140px'>Artist & Tattoo </th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {[].map((waiver, index) => {
                {/* 
                return <tr>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input className='form-check-input widget-9-check' type='checkbox' value='1' />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      {false && <div className='symbol symbol-45px me-5'>
                        <img src={toAbsoluteUrl('/media/avatars/300-14.jpg')} alt='' />
                      </div>}
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {`${waiver.first_name} ${waiver.last_name}`}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {`${DateTime.fromSeconds(waiver.created_at_timestamp).toRelative()} (${DateTime.fromSeconds(waiver.created_at_timestamp).toFormat('yyyy-MM-dd')})`}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {waiver.name_of_artist}
                    </a>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      {waiver.tattoo_description}
                    </span>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href={waiver.download_url}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <KTIcon iconName='document' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
                */}
                return <tr>
                  <td>
                    Need to implement this item
                  </td>
                </tr>
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget9 }
