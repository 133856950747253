import React, { useCallback, useEffect, useRef } from "react"
import ApexCharts, { ApexOptions } from 'apexcharts'
import {
    DateTime
} from 'luxon'
import { Link } from "react-router-dom"
import styled from "styled-components"
import { RefreshCw } from 'react-feather'
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils"
import { APIError } from "../../app/modules/auth"
import { DashboardGraphData, ServiceLocation, getDashboardGraphData } from "../core/_requests"


export const ReactFeatherButton = styled.button`
  color: #a2a5b7;
  /* add a hover color: */
  &:hover {
    color: #5e6278;
  }
`

export const formatDateForDatePickers = (unixTimestamp: number) => {
    // Given a unix timestamp, return a string in the format of 2021-10-08
    // We use luxon for compatibility / ease of use
    const parsedDate = DateTime.fromSeconds(unixTimestamp)
    return parsedDate.toFormat('yyyy-MM-dd')
}

const CountContainer = function ({ children, backgroundColor }: { children: React.ReactNode, backgroundColor: string }) {
    return <span style={{ background: backgroundColor, padding: '3px 5px', color: 'white', borderRadius: '3px' }}>
        {children}
    </span>
}


const DateRangePicker = ({ startingUnixTimestamp, endingUnixTimestamp, setStartingUnixTimestamp, setEndingUnixTimestamp, fetchData }) => {
    const formatDateForDatePickers = (timestamp) => {
        return DateTime.fromSeconds(timestamp).toISODate();
    };

    return (
        <div className="d-flex flex-stack flex-wrap flex-grow-1 pt-3 pb-3">
            <div className="d-flex align-items-center">
                <input
                    type="date"
                    value={formatDateForDatePickers(startingUnixTimestamp)}
                    onChange={(e) => {
                        const userSelectedDate = DateTime.fromISO(e.target.value, { zone: 'America/Chicago' }).startOf('day');
                        const seconds = Math.floor(userSelectedDate.toSeconds());
                        setStartingUnixTimestamp(seconds);
                    }}
                />
            </div>
            <div className="d-flex align-items-center px-2">to</div>
            <div className="d-flex align-items-center">
                <input
                    type="date"
                    value={formatDateForDatePickers(endingUnixTimestamp)}
                    onChange={(e) => {
                        const userSelectedDate = DateTime.fromISO(e.target.value, { zone: 'America/Chicago' }).endOf('day');
                        const seconds = Math.floor(userSelectedDate.toSeconds());
                        setEndingUnixTimestamp(seconds);
                    }}
                />
            </div>
            <div className="d-flex align-items-center px-2">
                <button onClick={fetchData} className="btn btn-primary btn-sm">Update</button>
            </div>
        </div>
    );
};


export default function DashboardGraph({ onlyShowServiceLocation, removeExtraHeightBy = 0 }: { onlyShowServiceLocation?: ServiceLocation, removeExtraHeightBy?: number }) {

    const chartColor = 'primary'

    // To make the graph shorter, change `250` to a larger number,
    // or find where this is used and change `removeExtraHeightBy` to a smaller number.

    const chartHeight = (window.innerHeight - 250 - removeExtraHeightBy) + 'px'

    const chartRef = useRef<HTMLDivElement | null>(null)

    const [hideDashboard, setHideDashboard] = React.useState(false)

    const [isLoading, setIsLoading] = React.useState(false)
    const [loadingError, setLoadingError] = React.useState<string | null>(null)
    const [dashboardResponse, setDashboardResponse] = React.useState<DashboardGraphData | null>(
        null
    )

    const [startingUnixTimestamp, setStartingUnixTimestamp] = React.useState(
        Math.floor(DateTime.local().setZone('America/Chicago').minus({ days: 7 }).startOf('day').toSeconds())
    )

    const [endingUnixTimestamp, setEndingUnixTimestamp] = React.useState(
        Math.floor(DateTime.local().setZone('America/Chicago').toSeconds())
    )

    const loadDashboardData = useCallback(async () => {
        setIsLoading(true)
        try {
            let loadedDashboardData: DashboardGraphData | null = null
            loadedDashboardData = await getDashboardGraphData(
                startingUnixTimestamp,
                endingUnixTimestamp,
                onlyShowServiceLocation?.unique_identifier || null
            )
            if (!loadedDashboardData) {
                throw new APIError('No dashboard data returned', {})
            }
            setLoadingError(null)
            setDashboardResponse(loadedDashboardData)
            if (loadedDashboardData.total_events > 0 || loadedDashboardData.total_direct_mail_replies > 0 || loadedDashboardData.total_event_administrators > 0 || loadedDashboardData.total_event_check_in_records > 0) {
                setHideDashboard(false)
                const chart = new ApexCharts(
                    chartRef.current,
                    chartOptions(chartColor, chartHeight, loadedDashboardData)
                )
                if (chart) {
                    chart.render()
                }
            } else {
                setHideDashboard(true)
            }
        } catch (error: any) {
            console.warn(error)
            const errorMessage = error instanceof APIError ? error.message : 'Something went wrong'
            setLoadingError(errorMessage)
        } finally {
            setIsLoading(false)
        }
        // Disabled because it leads to infinite reloads for some reason
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartHeight, endingUnixTimestamp, startingUnixTimestamp, onlyShowServiceLocation?.unique_identifier])

    useEffect(() => {
        loadDashboardData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`card shadow`}>
            {/* begin::Body */}
            <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
                {/* begin::Hidden */}
                <div className='d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-3 pb-3'>
                    <div className='me-2'>
                        {/* <span className='fw-bold text-gray-800 d-block fs-3'>{`${currentPhoenixUser.current_client.name}`}</span> */}
                        {/* && <span className='fw-bold text-gray-800 d-block fs-3'>{!onlyShowServiceLocation ? `All Received Data` : `${onlyShowServiceLocation.name}'s Received Data`}</span> */}
                        <DateRangePicker
                            startingUnixTimestamp={startingUnixTimestamp}
                            endingUnixTimestamp={endingUnixTimestamp}
                            setStartingUnixTimestamp={setStartingUnixTimestamp}
                            setEndingUnixTimestamp={setEndingUnixTimestamp}
                            fetchData={loadDashboardData}
                        />
                    </div>

                    <div className={`fw-bold fs-3 text-${chartColor}`}>
                        {isLoading ? (
                            <div>
                                <div className='spinner-border spinner-border-sm align-middle ms-2'></div>
                            </div>
                        ) : (
                            <div>
                                {loadingError ? (
                                    <>
                                        <ReactFeatherButton
                                            type='button'
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            onClick={loadDashboardData}
                                        >
                                            <RefreshCw size={14} />
                                        </ReactFeatherButton>
                                    </>
                                ) : (
                                    <>
                                        {!dashboardResponse ? <></> : <>
                                            {/* e.g. events */}
                                            <CountContainer backgroundColor="#4cd1d0">{dashboardResponse.getFormattedEventsLabel()}</CountContainer><span></span>
                                            <span>
                                                {/* e.g. event administrators */}
                                                &nbsp;
                                            </span>
                                            <CountContainer backgroundColor="#43acf8">{dashboardResponse.getFormattedEventAdministratorsLabel()}</CountContainer>
                                            <span>
                                                {/* e.g. event check-in records */}
                                                &nbsp;
                                            </span>
                                            <CountContainer backgroundColor="#ffd045">{dashboardResponse.getFormattedEventCheckInRecordsLabel()}</CountContainer>
                                            <span>
                                                {/* e.g. direct mail replies or direct mail reply */}
                                                &nbsp;
                                            </span>
                                            <CountContainer backgroundColor="#f56183">{dashboardResponse.getFormattedDirectMailRepliesLabel()}</CountContainer>
                                            <span>
                                            </span>
                                        </>}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {/* end::Hidden */}
                {/* begin::Chart */}
                {loadingError ? (
                    <div className='p-5'>
                        <div className='alert alert-danger'>
                            <div className='alert-text'>{loadingError}</div>
                        </div>
                    </div>
                ) : (
                    <>
                        {isLoading ? (
                            <div className='mb-8'></div>
                        ) : (
                            <div>
                                {dashboardResponse?.events_by_day?.length === 0 ? (
                                    <div className='p-5'>
                                        <div className='alert alert-info'>
                                            <div className='alert-text text-center'>
                                                {'datePickerIsNotReadyYet' ? (
                                                    <>
                                                        No events found for the selected date range. Please{' '}
                                                        <Link to='/create-obituary'>create an obituary</Link>.
                                                    </>
                                                ) : (
                                                    <>
                                                        No events found for the selected date range. Please select a
                                                        different date range or{' '}
                                                        <Link to='/create-obituary'>create an obituary</Link>.
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>{/* Chart gets shown */}</>
                                )}
                            </div>
                        )}
                    </>
                )}
                {/* end::Chart */}
                <div
                    ref={chartRef}
                    className='chart relative'
                    style={{ height: chartHeight }}
                >
                    {hideDashboard && (
                        <div className="absolute-top-0 w-100 h-100 d-flex flex-center">
                            <div className='d-flex flex-center flex-column'>
                                <div className='p-5'>
                                    No data is available for the selected date range.
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}


const chartOptions = (
    chartColor: string,
    chartHeight: string,
    responseData: DashboardGraphData
): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')

    const fivePleasingHexColors = [
        getCSSVariableValue('--bs-primary'),
        getCSSVariableValue('--bs-success'),
        getCSSVariableValue('--bs-warning'),
        getCSSVariableValue('--bs-danger'),
        getCSSVariableValue('--bs-info'),
    ]

    console.warn('rename unique_events and other properties in back-end / here...')

    const resultingEvents = responseData.events_by_day.map((uniqueSessionByDay) => {
        return {
            x: uniqueSessionByDay.full_date,
            y: uniqueSessionByDay.unique_events,
        }
    })

    const resultingEventAdministratorsByDay = responseData.event_administrators_by_day.map((uniqueUserByDay) => {
        return {
            x: uniqueUserByDay.full_date,
            y: uniqueUserByDay.unique_administrators
        }
    })

    const resultingEventCheckInRecords = responseData.event_check_in_records_by_day.map((pageViewByDay) => {
        return {
            x: pageViewByDay.full_date,
            y: pageViewByDay.unique_check_in_records
        }
    })

    // direct_mail_replies_by_day:

    const resultingDirectMailReplies = responseData.direct_mail_replies_by_day.map((pageViewByDay) => {
        return {
            x: pageViewByDay.full_date,
            y: pageViewByDay.unique_direct_mail_replies
        }
    })

    const datesAndYears = responseData.events_by_day.map((uniqueSessionByDay) => {
        const fullDate = uniqueSessionByDay.full_date // "2021-02-01"
        const parsedString = DateTime.fromISO(fullDate)
        // Format this with a day of the week and then the LL dd, yyyy format
        const formattedString = parsedString.toFormat('EEE LLL dd, yyyy')
        return formattedString
    })

    // TODO: Add new "series" item

    const availableSeries = [
        {
            name: 'Events',
            data: resultingEvents,
            tooltip: {
                singularLabel: 'event',
                pluralLabel: 'events',
            }
        },
        {
            name: 'Administrators',
            data: resultingEventAdministratorsByDay,
            tooltip: {
                singularLabel: 'administrator',
                pluralLabel: 'administrators',
            }
        },
        {
            name: 'Check-Ins',
            data: resultingEventCheckInRecords,
            tooltip: {
                singularLabel: 'check-in',
                pluralLabel: 'check-ins',
            }
        },
        {
            name: 'Direct Mail Replies',
            data: resultingDirectMailReplies,
            tooltip: {
                singularLabel: 'direct mail reply',
                pluralLabel: 'direct mail replies',
            }
        }
    ]

    return {
        series: availableSeries,
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: chartHeight,
            toolbar: {
                show: false,
            },
            animations: {
                enabled: false,
                easing: 'easeinout',
                speed: 500,
                animateGradually: {
                    enabled: true,
                    delay: 50,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: datesAndYears,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
                formatter: function (val) {
                    // Integers only
                    return val.toFixed(0)
                },
            },
        },
        fill: {
            type: 'solid',
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val, opts) {
                    const desiredSeries = availableSeries[opts.seriesIndex]
                    return `${val} ${val === 1 ? desiredSeries.tooltip.singularLabel : desiredSeries.tooltip.pluralLabel}`
                },
            },
        },
        colors: fivePleasingHexColors,
        grid: {
            padding: {
                top: 10,
            },
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}