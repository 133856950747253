import { useState, useCallback, useEffect } from 'react'

import { APIError } from '../../app/modules/auth'
import { AccountUserMembership, Partner, getAccountMemberships } from '../core/_requests'

function useAccountMemberships({
  accountUniqueIdentifier,
  onlyRole,
}: {
  accountUniqueIdentifier: string,
  onlyRole: 'account_admin' | 'marketer'
}) {
  const [accountMemberships, setAccountMemberships] = useState<AccountUserMembership[]>([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const loadAccountMemberships = useCallback(async () => {
    setLoading(true)
    try {
      const updatedAccountMemberships = await getAccountMemberships(accountUniqueIdentifier, onlyRole)
      setAccountMemberships(updatedAccountMemberships)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load account memberships'
      setErrorMessage(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    loadAccountMemberships()
  }, [loadAccountMemberships])

  return {
    accountMemberships,
    loading,
    errorMessage,
  }
}

export default useAccountMemberships
