import { useState, useCallback, useEffect } from "react"
import { useAuth, APIError } from "../../../../../app/modules/auth"
import { getAllPipedriveDeals } from "../../../../../mogul/core/_requests"
import { PipedriveDeal } from "../../../../../mogul/routes/PartnersRoutes"
import { formatLeadItemDate } from "../DebugPipedrive"
import { FilterPipedriveDeals, PipedriveDealFilter } from "./FilterPipedriveDeals"

const saveFilterToLocalStorageKey = 'pipedrive_deal_filter_v1'

export function PipedriveDealList() {

    const [viewingFilter, setViewingFilter] = useState(false)

    const [loading, setLoading] = useState(true);
    const [errorLoading, setErrorLoading] = useState<string | null>(null);

    const [pipedriveDeals, setPipedriveDeals] = useState<PipedriveDeal[]>([])
    const [totalPipedriveDeals, setTotalPipedriveDeals] = useState(0)

    const [currentFilter, setCurrentFilter] = useState<PipedriveDealFilter | undefined>(undefined)

    const loadList = useCallback(async (filterObject?: PipedriveDealFilter) => {
        // We reload on viewingTab for now
        setErrorLoading(null)
        setLoading(true)
        try {
            const {
                deals,
                totalCount: updatedTotalCount
            } = await getAllPipedriveDeals({
                filter: filterObject || null,
            })
            setPipedriveDeals(deals)
            setTotalPipedriveDeals(updatedTotalCount)
        } catch (exception) {
            console.error(exception)
            const errorMessage =
                exception instanceof APIError ? exception.message : 'Failed to load leads'
            setErrorLoading(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        try {
            const savedFilter = localStorage.getItem(saveFilterToLocalStorageKey)
            if (savedFilter) {
                const parsedFilter = JSON.parse(savedFilter)
                setCurrentFilter(parsedFilter)
                loadList(parsedFilter)
            } else {
                loadList()
            }
        } catch (error) {
            console.error('Failed to load saved filter', error)
        }
    }, [loadList])

    if (loading) {
        return <div className='p-10'>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    }

    if (errorLoading) {
        return <div className='p-10'>
            <div className="alert alert-danger" role="alert">
                {errorLoading}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={() => {
                    loadList(currentFilter)
                }}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <div>
        <>
            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-3'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='fw-bolder text-dark fs-3'>
                        {totalPipedriveDeals === 1 ? '1 Pipedrive Deal' : `${totalPipedriveDeals} Pipedrive Deals`}
                    </span>
                    {'' && <span className='text-gray-400 mt-2 fw-bold fs-6'>{'subtitle'}</span>}
                </h3>
                {true && <div className='card-toolbar'>
                    {/* begin::Menu */}
                    <button
                        type='button'
                        className='btn btn-sm btn-light me-2'
                        onClick={() => { setViewingFilter(true) }}
                    >
                        <span className='indicator-label'>
                            Filter List
                        </span>
                    </button>
                    {/* end::Menu */}
                </div>}
            </div>
            {/* end::Header */}
            {viewingFilter && <>
                <FilterPipedriveDeals
                    currentFilter={currentFilter}
                    onReset={() => {
                        setCurrentFilter(undefined)
                        loadList()
                        localStorage.removeItem(saveFilterToLocalStorageKey)
                    }}
                    onCancel={() => setViewingFilter(false)}
                    onApply={(filterObject: PipedriveDealFilter) => {
                        loadList(filterObject)
                        setViewingFilter(false)
                        setCurrentFilter(filterObject)
                        localStorage.setItem(saveFilterToLocalStorageKey, JSON.stringify(filterObject))
                    }}
                />
            </>}
        </>
        <div className='px-10'>
            {pipedriveDeals.map((pipedriveDeal) => {
                return <div key={`deal-${pipedriveDeal.unique_identifier}`} className='border-bottom border-gray-200 py-5'>
                    <div className='fw-bold fs-3 mb-1'>
                        {pipedriveDeal.latest_deal_json.person_name}
                        {/* 
                        {pipedriveDeal.latest_deal_json.title}
                        */}
                        <span className='text-muted fs-4 ms-2'>
                            {`${formatLeadItemDate(pipedriveDeal.updated_at, true)} - ${pipedriveDeal.unique_identifier}`}
                        </span>
                    </div>
                    <div className='fw-bold fs-3 mb-2'>
                        {pipedriveDeal.latest_deal_json.org_name}
                    </div>
                    <div className='fw-bold fs-3 mb-2'>
                        FCA Estate Specialist: {pipedriveDeal.latest_deal_json.owner_name}
                    </div>
                    {pipedriveDeal.limited_hydrated_deal_json?.fields_with_values ? <div className="grid grid-cols-4 gap-4">
                        {pipedriveDeal.limited_hydrated_deal_json.fields_with_values.filter(x => {
                            if (pipedriveDeal.pipedrive_account.api_domain === "https://full-circleaftercare2.pipedrive.com") {
                                // If this changes, search for `limit_hydrated_deal_json` in the backend.
                                return [
                                    "29280234f3abc430ca561212b5dffce4d4bde41a", // Deceased Name
                                    // "efab7d3aa734a422c084ac02031d539acad7f8c9", // Deceased Date of Birth
                                    "ce40a1124dc27dabd508b100548d61d066f5b94f", // Death Date
                                    "7844c1a1618ab1867f009eba8920979a18c193d4", // Age
                                    "e44971ba8242ebbcb00fe1199bb8f6afda9156bf", // Funeral Service Date(s) (DO NOT CALL ON THESE DAYS)
                                    // "330552d1267107caf937f259c9bd68cf030282af", // Date Serviced ---- FCA Aftercare Appointment Date
                                    "6821b4528bd218a5523d76ce59620b2a8770f29a", // Funeral Director / SW  ----  SW == Social Worker
                                    "7948db556604a4bb9c39dacb28ba47352ec71b8b", // Pre-Need?
                                    "900d26a002b5b95b5d8d4c1628373416989d3541", // Preneed Lead Notes
                                    "e5237ec9f56dd90f28ff75029db86f4f157f292f", // Survey Q1 - Exceeded Expectations
                                    "06d6082c41586f982ecebbdd54b535c0107043af", // Survey Q2 - Anything we can do to improve our service?
                                    "d1636999928d7388068b0aa06d4664d653754226", // Survey Q3 - Will you recommend ____?
                                    "646c1d329f29615bc6aec0dfee4b1036edbf4833", // Online Review Link (BackOffice)
                                    "abc0be9b36208d836db290ec68a6bdf9903bf86b", // Online Review Quote (Backoffice)
                                    "2634916b307bfeb1cafd2fa55cda5d7976d1a2b7", // Deceased Gender
                                    "d38bdbdd1cebf3fb1d7523aec9b20bcd008b1c38", // Veteran?
                                    "577284f0f937debaf15352c3ee73e8ad3baa42a0", // Veterans
                                ].includes(x.field.key)
                            }
                            // Return all custom fields for everyone else
                            return true
                        }).map((fieldWithValue) => {
                            return <div key={`deal-field-${fieldWithValue.field.key}`} className='mb-2'>
                                <div className='fw-bold fs-5'>
                                    {fieldWithValue.field.name}
                                </div>
                                <div className='fs-6'>
                                    {fieldWithValue.value}
                                </div>
                            </div>
                        })}
                    </div> : null}
                </div>
            })}
        </div>
    </div >

}
