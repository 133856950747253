/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useMemo, useState } from 'react'

import { KTIcon } from '../../_metronic/helpers'
import useAccounts from '../hooks/useAccounts'
import { addUserToAccountByUniqueIdentifier } from '../core/_requests'
import { APIError } from '../../app/modules/auth'
import useCurrentAccountPage from '../hooks/useCurrentAccountPage'

export default function AddUserModal() {

  const {
    accounts,
    loadingAccounts,
    errorLoadingAccounts,
  } = useAccounts()

  const [saving, setSaving] = useState(false)
  const [errorSaving, setErrorSaving] = useState<string | null>(null)

  const {
    currentAccount: matchingUserAccount,
  } = useCurrentAccountPage()

  const onFormSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!matchingUserAccount) {
      setErrorSaving('No account found')
      return
    }
    const form = event.currentTarget
    const formData = new FormData(form)
    const data = Object.fromEntries(formData.entries())
    setErrorSaving(null)
    setSaving(true)
    try {
      const _was_created = await addUserToAccountByUniqueIdentifier({
        account_unique_identifier: matchingUserAccount.unique_identifier,
        name: data.name,
        email: data.email,
      })
      window.location.reload()
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to add user'
      setErrorSaving(errorMessage)
      setSaving(false)
    }
  }, [matchingUserAccount?.unique_identifier])

  return (
    <div className='modal fade' id='modal_add_user' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <form className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15' onSubmit={onFormSubmit}>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>
                Add a user
              </h1>

              <div className='text-muted fw-bold fs-5'>
                Enter the user details below
              </div>
            </div>


            {false && 'willBeAMultiSelectOneDay' && <>
              <label className='form-label'>Account:</label>
              {loadingAccounts ? <div>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div> : <>
                {errorLoadingAccounts ? <>
                  <div className='w-100'>
                    <div className='alert alert-danger'>
                      {errorLoadingAccounts}
                    </div>
                  </div>
                </> : <>
                  <select
                    name="account_unique_identifier"
                    className='form-select form-select-solid mb-8' required>
                    <option value=''>Select an account...</option>
                    {accounts.map((account) => <option key={`account-${account.unique_identifier}`} value={account.unique_identifier}>{account.name}</option>)}
                  </select>
                </>}
              </>}
            </>}

            <label className='form-label'>Add User to Account:</label>
            <div className='mb-8'>
              {matchingUserAccount ? <>
                <div className='alert alert-primary'>
                  {matchingUserAccount.name}
                </div>
              </> : <>
                <div className='alert alert-danger'>
                  No account found
                </div>
              </>}
            </div>

            <label className='form-label'>User's Full Name:</label>
            <input required name="name" type="text" className="form-control form-control-solid mb-8" placeholder="e.g. John Doe" />

            <label className='form-label'>User's Email Address:</label>
            <input required name="email" type="email" className="form-control form-control-solid mb-8" placeholder="e.g. john@doe.com" />

            <div className='my-7'></div>

            <div className='modal-footer'>
              <div className='flex-center'>
                <button type='reset' className='btn btn-white me-3' data-bs-dismiss='modal' id='add_user_modal_cancel'>
                  Cancel
                </button>
                <button type='submit' className='btn btn-primary'>
                  {!saving && 'Add User'}
                  {saving && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      Adding...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
              {errorSaving ? <div className='w-100 mt-5'>
                <div className='alert alert-danger'>
                  {errorSaving}
                </div>
              </div> : null}
            </div>

          </form>

        </div>
      </div>
    </div>
  )
}
