import { Link, useParams } from "react-router-dom";
import { useAccountDetail } from "../../AccountsRoutes";
import { useCallback, useEffect, useState } from "react";
import { AccountUserMembership, ServiceLocationWithAccessLevel, getListOfServiceLocationsWithAccessLevelForAccountUserMembershipUniqueIdentifier, giveAccessToServiceLocationForAccountUserMembershipUniqueIdentifier, takeAccessFromServiceLocationForAccountUserMembershipUniqueIdentifier } from "../../../core/_requests";
import { APIError, useAuth } from "../../../../app/modules/auth";

function ServiceLocationManageLocations() {
    const {
        account_membership_unique_identifier: accountMembershipUniqueIdentifier
    } = useParams<{ account_membership_unique_identifier: string }>()

    const { currentUser } = useAuth();

    const {
        account
    } = useAccountDetail();

    const [loadedUserMembership, setLoadedUserMembership] = useState<AccountUserMembership | null>(null)

    const [listOfServiceLocationsWithAccess, setListOfServiceLocationsWithAccess] = useState<ServiceLocationWithAccessLevel[]>([]);
    const [loadingAccount, setLoadingAccount] = useState(true);
    const [errorLoadingAccount, setErrorLoadingAccount] = useState<string | null>(null);

    const [filterText, setFilterText] = useState('')

    const showServiceLocations = listOfServiceLocationsWithAccess.filter(serviceLocationWithAccess => {
        return serviceLocationWithAccess.serviceLocation.name.toLowerCase().includes(filterText.toLowerCase())
    })

    const loadListOfServiceLocations = useCallback(async () => {
        if (!currentUser) {
            return
        }
        if (!account.unique_identifier || !accountMembershipUniqueIdentifier) {
            return
        }
        setLoadingAccount(true)
        setErrorLoadingAccount(null)
        try {
            const {
                membership,
                locationsWithAccessLevel: updatedListOfServiceLocations
            } = await getListOfServiceLocationsWithAccessLevelForAccountUserMembershipUniqueIdentifier(account.unique_identifier, accountMembershipUniqueIdentifier)
            setLoadedUserMembership(membership)
            setListOfServiceLocationsWithAccess(updatedListOfServiceLocations)
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to load account'
            setErrorLoadingAccount(errorMessage)
        } finally {
            setLoadingAccount(false)
        }
    }, [account.unique_identifier, accountMembershipUniqueIdentifier, currentUser])

    const giveAccessByRow = useCallback(async (serviceLocationUniqueIdentifier: string) => {
        if (!loadedUserMembership?.unique_identifier) {
            return
        }
        try {
            await giveAccessToServiceLocationForAccountUserMembershipUniqueIdentifier({
                accountMembershipUniqueIdentifier: loadedUserMembership.unique_identifier,
                serviceLocationUniqueIdentifier: serviceLocationUniqueIdentifier
            })
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to update access'
            alert(errorMessage) // One day, we'll have a better way to show this (TODO)
        }
    }, [loadedUserMembership?.unique_identifier])

    const takeAccessByRow = useCallback(async (serviceLocationUniqueIdentifier: string) => {
        if (!loadedUserMembership?.unique_identifier) {
            return
        }
        try {
            await takeAccessFromServiceLocationForAccountUserMembershipUniqueIdentifier({
                accountMembershipUniqueIdentifier: loadedUserMembership.unique_identifier,
                serviceLocationUniqueIdentifier: serviceLocationUniqueIdentifier
            })
        } catch (exception) {
            console.error(exception)
            const errorMessage = exception instanceof APIError ? exception.message : 'Failed to update access'
            alert(errorMessage) // One day, we'll have a better way to show this (TODO)
        }
    }, [loadedUserMembership?.unique_identifier])

    useEffect(() => {
        loadListOfServiceLocations()
    }, [loadListOfServiceLocations])

    if (!currentUser || loadingAccount) {
        return <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    }

    if (errorLoadingAccount || !account) {
        return <div>
            <div className="alert alert-danger" role="alert">
                {errorLoadingAccount || 'Failed to load available service locations'}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadListOfServiceLocations}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <div className="mt-5">
        <div className='mb-5'>
            <Link
                to={`/accounts/${account.unique_identifier}`}
                className='btn btn-secondary btn-sm'>
                Back to Account Management
            </Link>
        </div>
        <h4 className='mb-5'>
            {/* Speeding to get this done, hence the long line below: */}
            {`Manage ${`${loadedUserMembership?.user.first_name || ''} ${loadedUserMembership?.user.last_name || ''}`.trim()}'s (${loadedUserMembership?.user.email}) Location Access for ${account.name}`}
        </h4>
        <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5'>
                <input
                    className="form-control form-control-solid form-control-lg"
                    type="text"
                    placeholder="Filter by location name..."
                    value={filterText}
                    onChange={(event) => setFilterText(event.target.value)}
                />
            </div>
            <div className='card-body py-3'>
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                        <tr className='fw-bold text-muted'>
                            <th scope="col">Service Location</th>
                            <th scope="col" className="w-150px">Has Access?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {showServiceLocations.length === 0 ? <>
                            <tr>
                                <td colSpan={2}>
                                    <div className='text-center'>
                                        No service locations found
                                    </div>
                                </td>
                            </tr>
                        </> : <>
                            {showServiceLocations.map(serviceLocationWithAccess => {
                                return <tr key={serviceLocationWithAccess.serviceLocation.unique_identifier}>
                                    <td>{serviceLocationWithAccess.serviceLocation.name}</td>
                                    <RowCheckCell
                                        initialCheckValue={serviceLocationWithAccess.isSelected}
                                        serviceLocationWithAccess={serviceLocationWithAccess}
                                        giveAccessByRow={giveAccessByRow}
                                        takeAccessByRow={takeAccessByRow}
                                    />
                                </tr>
                            })}
                        </>}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}

function RowCheckCell({ initialCheckValue, serviceLocationWithAccess, giveAccessByRow, takeAccessByRow }) {
    const [checked, setChecked] = useState(initialCheckValue)

    return <td>
        <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            onChange={async (event) => {
                // For now, simply update setCheckedUniqueIdentifiers:
                const isChecked = event.target.checked
                if (isChecked) {
                    setChecked(true)
                    giveAccessByRow(serviceLocationWithAccess.serviceLocation.unique_identifier)
                } else {
                    setChecked(false)
                    takeAccessByRow(serviceLocationWithAccess.serviceLocation.unique_identifier)
                }
            }} />
    </td>
}

export default ServiceLocationManageLocations;