import DashboardGraph from "../../../components/DashboardGraph";
import { useServiceLocationDetail } from "./ServiceLocationDetail";

function ServiceLocationDashboard() {
    const {
        serviceLocation
    } = useServiceLocationDetail();

    if (!serviceLocation) {
        return null;
    }

    return <div>
        <DashboardGraph
            onlyShowServiceLocation={serviceLocation}
            removeExtraHeightBy={50}
        />
    </div>
}

export default ServiceLocationDashboard;